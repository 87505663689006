import * as React from "react";
import styled from "styled-components";
import Button from "./mobileOverlayButton";
import RegionRadioGroup from "./regionRadioGroup";
import MobileOverlayMainContact from "./mobileOverlayMainContact";
import {CategoryListWithQuery} from "../../../components";
import {
  NavigationOverlayStateType,
  navigationOverlayStates,
  ColumnDiv,
  macroBold,
  DflRegionType,
  ContactMainProps,
} from "../../../models";

import logoDfl from "../../../images/contactMainDFL.svg";
import logoBli from "../../../images/contactMainBLI.svg";
import {Link} from "gatsby";

interface WrapperProps {
  visible: boolean;
}
const Wrapper = styled(ColumnDiv)`
  display: ${(props: WrapperProps) => (props.visible ? "flex" : "none")};
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  /* NOTE: must have a big padding bottom for iOS address bar 
  See: MainContentWrapper in style-constants */
  padding: 50px 10px 100px;
  box-sizing: border-box;
`;

const Headline = styled.div`
  ${macroBold};
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  margin: 36px 0 18px;
`;

interface CloseOverlayButtonProps {
  link: string;
  label: string;
  onButtonClick: () => void;
}
const CloseOverlayButton = (props: CloseOverlayButtonProps) => (
  <Link to={props.link} activeClassName={"mobile-active"}>
    <Button label={props.label} onButtonClick={props.onButtonClick} />
  </Link>
);

interface MobileOverlayContentProps {
  overlayState: NavigationOverlayStateType;
  selectedRegion: DflRegionType;
  onRegionClick: (region: DflRegionType) => void;
  onButtonClick: () => void;
  mainDfl: ContactMainProps;
  mainBli: ContactMainProps;
}
const MobileOverlayContent = (props: MobileOverlayContentProps) => {
  const {overlayState, selectedRegion, onRegionClick, onButtonClick, mainDfl, mainBli} = props;

  return (
    <Wrapper visible={overlayState === navigationOverlayStates.mobileActive}>
      <Headline>Bundesliga Products</Headline>
      <CategoryListWithQuery isMobileNav={true} />

      <Headline>What's new</Headline>
      <CloseOverlayButton label="What's new 2024/25" link={"/whats-new"} onButtonClick={onButtonClick} />
      {/* <Link to={"/whats-new"} activeClassName={"mobile-active"}>
        <Button label="What's new 2019/20" />
      </Link> */}

      <Headline>Your region</Headline>
      <RegionRadioGroup selectedRegion={selectedRegion} onRegionClick={onRegionClick} />

      <Headline>Contact</Headline>
      <div style={{marginBottom: 3}}>
        <CloseOverlayButton label="All contacts page" link={"/contact"} onButtonClick={onButtonClick} />
      </div>
      <MobileOverlayMainContact {...mainDfl} imageUrl={logoDfl} />
      <MobileOverlayMainContact {...mainBli} imageUrl={logoBli} />
    </Wrapper>
  );
};

export default MobileOverlayContent;
