import React from "react";
import {StaticQuery, graphql} from "gatsby";
import {connect} from "react-redux";
import ContactList from "./contactList";
import {WPAcfPostsProps, DflRegionType} from "../../models";
import {AppState} from "../../store/";
import {acfFieldsToContactPersons} from "../../util/util";

interface QueryProps {
  wpPost: WPAcfPostsProps;
}

// COMPONENT PROPERTIES
interface PropsFromState {
  selectedRegion: DflRegionType;
}
const mapStateToProps = (state: AppState): PropsFromState => ({
  selectedRegion: state.system.selectedRegion,
});

interface ContactListWithQueryState {
  selectedRegion: DflRegionType;
}
class ContactListWithQuery extends React.Component<PropsFromState, ContactListWithQueryState> {
  render() {
    return (
      <StaticQuery
        query={graphql`
        query ($contacts_id: Int = 1) {
          wpPost(databaseId: {eq: $contacts_id}) {
            contacts {
              contactsBli {
                image {
                  sourceUrl
                }
                name
                market
                position
                email
                phone
                positionTwo
              }
              contactsDfl {
                image {
                  sourceUrl
                }
                name
                market
                position
                phone
                email
                positionTwo
              }
            }
          }
        }
        `}
        render={(data: QueryProps) => {
          const contactsBLI = acfFieldsToContactPersons(data.wpPost.contacts.contactsBli);
          const contacts = contactsBLI.filter(contact => contact.market === this.props.selectedRegion);
          return (
            <ContactList
              contactsDFL={acfFieldsToContactPersons(data.wpPost.contacts.contactsDfl)}
              contactsBLI={contacts}
            />
          );
        }}
      />
    );
  }
}

export default connect(
  mapStateToProps,
)(ContactListWithQuery);
