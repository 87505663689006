import * as React from "react";
import styled from "styled-components";
import {px} from "../models";

const GridWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  pointer-events: none;
`;

interface GridProps {
  width: number;
}
const Grid = styled.div`
  width: ${(props: GridProps) => px(props.width)};
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

interface GridColumnProps {
  width: number;
  borderColor?: string;
  fillColor?: string;
}
const GridColumn = styled.div`
  width: ${(props: GridColumnProps) => px(props.width)};
  box-sizing: border-box;
  background-color: ${(props: GridColumnProps) => (props.fillColor ? props.fillColor : "none")};
  opacity: 0.15;
  border-left: 1px solid ${(props: GridColumnProps) => (props.borderColor ? props.borderColor : "rgba(255, 0, 0, 0.15)")};
  border-right: 1px solid ${(props: GridColumnProps) => (props.borderColor ? props.borderColor : "rgba(255, 0, 0, 0.15)")};
`;

interface GridCompProps {
  width: number;
  columns: number;
  columnWidth: number;
  borderColor?: string;
  fillColor?: string;
  enabled?: boolean;
}
const GridComp = (props: GridCompProps) => {
  const {width, columns, columnWidth, borderColor, fillColor, enabled} = props;
  const columnsArray = [];
  for (let i = 0; i < columns; i += 1) {
    columnsArray.push(
      <GridColumn key={`grid-col-${i}`} fillColor={fillColor} borderColor={borderColor} width={columnWidth} />,
    );
  }
  return (enabled !== undefined && enabled === false) ? null :
      (<GridWrapper>
        <Grid width={width}>{columnsArray}</Grid>
      </GridWrapper>);
};

export default GridComp;
