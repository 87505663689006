import * as React from "react";
import styled, {css} from "styled-components";
import {Caret, RegionDropdown, TextFilter} from "../../components";
import {
  px,
  componentBreakpoints,
  elementSizes,
  timings,
  colors,
  fonts,
  navigationOverlayStates,
  NavigationOverlayStateType,
  DflRegionType,
  SearchableProductProps,
  GridColumnDiv,
} from "../../models";

import {} from "../../util/util";
import {Link} from "gatsby";

interface DesktopLinksStyleProps {
  active?: boolean;
  isInverted?: boolean;
  isTextDark?: boolean;
}

const CaretActiveStyles = `
  transform: translateY(25%) scaleY(-1);
  filter: flipv;
`;

const CaretWrapper = styled.div<{isActive?: boolean}>`
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-left: 6px;
  transition: transform 0.3s, filter 0.3s;
  transform-origin: 0 bottom;

  ${p =>
    p.isActive &&
    css`
      ${CaretActiveStyles};
    `};
`;

const TextUnaccented = styled.span`
  font-weight: ${fonts.weightLight};
`;

/* NOTE: There is a scenario when navigating from the 
  landing page where the inverted nav text needs to be black  */
const TextLink = styled.div`
  ${p =>
    p.isTextDark &&
    css`
      color: ${colors.black};
      a {
        color: ${colors.black};
      }
    `};

  transition: color ${timings.hurried}ms;
  color: ${(p: DesktopLinksStyleProps) => (p.active ? colors.redDFL : p.isInverted ? colors.white : colors.black)};
  text-transform: uppercase;
  user-select: none;

  a {
    color: ${(p: DesktopLinksStyleProps) => (p.active ? colors.redDFL : p.isInverted ? colors.white : colors.black)};
    padding: 0 ${elementSizes.navigationHeaderDropdownMenuXPadding}px;
  }

  .active & {
    color: ${colors.redDFL};
  }
`;

const PointerDiv = styled.div`
  pointer-events: all;
  cursor: pointer;
`;

const LinkList = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  pointer-events: all;
  padding: 0 ${elementSizes.navigationHeaderDropdownMenuXPadding}px;
`;

const LinkListDropdownMenuBtn = styled(LinkList)<{isInverted?: boolean}>`
  cursor: pointer;

  .link-list-dropdown-menu {
    display: none;
  }

  &:hover {
    background-color: ${p => (p.isInverted ? colors.white : colors.black)};

    .link-list-dropdown-menu {
      display: block;
      background-color: ${p => (p.isInverted ? colors.white : colors.black)};
      color: ${p => (p.isInverted ? colors.black : colors.white)};

      div:first-child {
        border-top: 1px solid ${p => (p.isInverted ? colors.grayUltraLight : colors.grayDark)};
        border-bottom: 1px solid ${p => (p.isInverted ? colors.grayUltraLight : colors.grayDark)};
      }
    }

    ${TextLink} {
      color: ${p => (p.isInverted ? colors.black : colors.white)};
    }

    svg polyline {
      stroke: ${p => (p.isInverted ? colors.black : colors.white)};
    }

    ${CaretWrapper} {
      ${CaretActiveStyles};
    }
  }
`;

const Container = styled(GridColumnDiv)`
  display: none;
  grid-gap: 20px;
  height: 100%;
  @media (min-width: ${px(componentBreakpoints.navigation)}) {
    display: grid;
  }
`;

interface DesktopLinksCompProps {
  overlayState: NavigationOverlayStateType;
  selectedRegion: DflRegionType;
  isNavigationInverted: boolean;
  isTextDark?: boolean;
  onProductsMouseOver: () => void;
  onProductsMouseLeave: () => void;
  onUpdateRegionClick?: (region: DflRegionType) => void;
  onPageLinkClick: (label: string) => void;
  searchables: SearchableProductProps[];
}

const DesktopLinksComp = (props: DesktopLinksCompProps) => {
  const {
    isNavigationInverted,
    overlayState,
    onProductsMouseOver,
    onProductsMouseLeave,
    onUpdateRegionClick,
    onPageLinkClick,
    selectedRegion,
    searchables,
    isTextDark,
  } = props;

  const isCaretActive = overlayState === navigationOverlayStates.productsActive;
  const isNavInverted = isNavigationInverted && overlayState === navigationOverlayStates.default;
  const isNavInvertedAndTextBlack = isNavigationInverted && !isTextDark;
  const inactiveCaretColor = isNavInvertedAndTextBlack ? colors.white : colors.black;

  return (
    <Container>
      {/* TODO: refactor and use LinkListDropdownMenuBtn component */}
      <PointerDiv
        style={{
          height: "100%",
        }}
      >
        <LinkList
          onMouseOver={(event: React.MouseEvent) => {
            onProductsMouseOver();
            event.stopPropagation();
          }}
          onMouseLeave={onProductsMouseLeave}
        >
          <TextLink
            isInverted={isNavInverted}
            isTextDark={isTextDark}
            active={overlayState === navigationOverlayStates.productsActive}
          >
            Products
            <CaretWrapper isActive={isCaretActive}>
              {/* TODO: refactor nested  */}
              <Caret color={isCaretActive ? colors.redDFL : inactiveCaretColor} />
            </CaretWrapper>
          </TextLink>
        </LinkList>
      </PointerDiv>

      <PointerDiv>
        <TextLink isInverted={isNavInverted} isTextDark={isTextDark}>
          <Link to={"/whats-new"} activeStyle={{color: colors.redDFL}} onClick={() => onPageLinkClick("What's New")}>
            What's New
          </Link>
        </TextLink>
      </PointerDiv>

      <LinkListDropdownMenuBtn isInverted={isNavInverted}>
        <TextLink isInverted={isNavInverted} isTextDark={isTextDark}>
          Region: <TextUnaccented>{selectedRegion}</TextUnaccented>
          <CaretWrapper>
            <Caret color={isNavInverted ? colors.white : colors.black} />
          </CaretWrapper>
        </TextLink>

        <RegionDropdown
          className="link-list-dropdown-menu"
          updateRegion={onUpdateRegionClick}
          selectedRegion={selectedRegion}
        />
      </LinkListDropdownMenuBtn>

      <PointerDiv>
        <TextLink isInverted={isNavInverted} isTextDark={isTextDark}>
          <Link to={"/contact"} activeClassName={"active"} onClick={() => onPageLinkClick("Contact")}>
            contact
          </Link>
        </TextLink>
      </PointerDiv>

      {/* Search */}
      <TextFilter products={searchables} />
    </Container>
  );
};

export default DesktopLinksComp;
