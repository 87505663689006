import * as React from 'react';
import styled from 'styled-components';
import {colors, stage, hugeBold, px, mediumBold} from "../../models";

const ProductHeader = styled.div`
  width: 100%;
`;

const Caption = styled.div`
  ${mediumBold};
  color: ${colors.redDFL};
  width: ${px(stage.innerWidth)};
  margin: 0 auto 32px;
`;
const Headline = styled.div`
  ${hugeBold};
  color: ${colors.black};
  text-transform: uppercase;
  line-height: 1em;
  width: ${px(stage.outerWidth)};
  margin: 0 auto;
`;


interface ProductHeaderCompProps {
  caption: string;
  title: string;
}
const ProductHeaderComp = (props: ProductHeaderCompProps) => {
  const { caption, title } = props;
  return (
    <ProductHeader>
      <Caption>{caption}</Caption>
      <Headline>{title}</Headline>
    </ProductHeader>
  );
};

export default ProductHeaderComp;
