import React from "react";
import styled from "styled-components";
import {elementSizes, dflRegions, DflRegionType} from "../../../models";

const REGIONS = [dflRegions.emea, dflRegions.asia, dflRegions.americas];

const RegionDropdownContainer = styled.div`
  position: absolute;
  top: ${elementSizes.navigationHeaderHeight}px;
  left: 0;
  width: 100%;
  box-sizing: border-box;
`;

const RegionDropdownItem = styled.div`
  font-size: 15px;
  font-weight: normal;
  text-transform: uppercase;
  text-align: right;
  padding: 15px 31px 15px ${elementSizes.navigationHeaderDropdownMenuXPadding}px;
  cursor: pointer;

  &:first-child {
    margin: 0 ${elementSizes.navigationHeaderDropdownMenuXPadding}px;
    padding-right: 16px;
  }
`;

interface RegionDropdownProps {
  className?: string;
  updateRegion: (region: DflRegionType) => void;
  selectedRegion: DflRegionType;
}
const RegionDropdown = (props: RegionDropdownProps) => {
  const {className, updateRegion, selectedRegion} = props;

  return (
    <RegionDropdownContainer className={className}>
      {REGIONS.filter(region => region !== selectedRegion).map((region, regionIndex) => {
        return (
          <RegionDropdownItem key={`region-${regionIndex}`} onClick={() => updateRegion(region)}>
            {region}
          </RegionDropdownItem>
        );
      })}
    </RegionDropdownContainer>
  );
};

export default RegionDropdown;
