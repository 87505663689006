import React from "react";
import styled from "styled-components";
import {Dispatch} from "redux";
import {connect} from "react-redux";
import {
  RegularCell,
  CategoryAggregationCell,
  CategoryCell,
  FlippedTextContainer,
  FlippedTextInner,
  BroadcastEntryLink,
} from "../styled";
import {
  BroadcastProductType,
  UTCTimeStateType,
  ScheduleAroundSeasonContentProps,
  condensedMicroBold,
  condensedMicroRegular,
  ColumnDiv,
  RowDiv,
  ColorType,
  colors,
} from "../../../../models";
import {AppState} from "../../../../store";
import {updateActiveSynergiesAction, updateHoveredDayAction} from "../../../../store/schedule/actions";

const TableRow = styled.tr``;

interface TitleProps {
  textColor: string;
}
const SeasonalProduct = styled(ColumnDiv)`
  width: 100%;
  margin-top: 10px;
`;
const TitleRow = styled(RowDiv)``;
const Title = styled.div`
  ${condensedMicroBold};
  padding: 0;
  margin: 0;
  color: ${(props: TitleProps) => props.textColor};
`;
interface EnhancementProps {
  color: ColorType;
}
const Enhancement = styled.div`
  ${condensedMicroBold};
  background-color: ${(props: EnhancementProps) => props.color};
  color: ${colors.white};
  text-transform: uppercase;
  padding: 2px 4px 0;
  margin-right: 4px;
  margin-bottom: 1px;
`;

const Description = styled.div`
  ${condensedMicroRegular};
  font-size: 12px;
  padding: 0;
  margin-top: -2px;
  color: ${(props: TitleProps) => props.textColor};
`;

const SeasonCell = styled(RegularCell)`
  padding: 0 30px 0 30px;
`;

interface PropsFromState {
  activeSynergies: BroadcastProductType[];
  hoveredDay: number;
  localUTCOffset: number;
  selectedUTCOffset: UTCTimeStateType;
}
const mapStateToProps = (state: AppState): PropsFromState => ({
  activeSynergies: state.schedule.activeBroadcastSynergies,
  hoveredDay: state.schedule.hoveredDay,
  localUTCOffset: state.system.utcOffset,
  selectedUTCOffset: state.schedule.selectedUTCTimeOffset,
});

interface PropsFromDispatch {
  updateActiveSynergies: (newSynergies: BroadcastProductType[]) => void;
  updateHoveredDay: (newDayIndex: number) => void;
}
const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => {
  return {
    updateActiveSynergies: (newSynergies: BroadcastProductType[]) => {
      return dispatch(updateActiveSynergiesAction(newSynergies));
    },
    updateHoveredDay: (newDayIndex: number) => {
      return dispatch(updateHoveredDayAction(newDayIndex));
    },
  };
};
interface CategoryRowProps extends PropsFromState, PropsFromDispatch {
  isSpan: boolean;
  span?: number;
  spanContent?: JSX.Element;
  content: ScheduleAroundSeasonContentProps;
}
const SeasonCategoryRow = (props: CategoryRowProps) => {
  const {isSpan, span, spanContent, content} = props;
  const {categoryName, backgroundColor, labelColor, textColor, seasons} = content;

  return (
    <TableRow>
      {isSpan && (
        <CategoryAggregationCell rowSpan={span}>
          <FlippedTextContainer>
            <FlippedTextInner>{spanContent}</FlippedTextInner>
          </FlippedTextContainer>
        </CategoryAggregationCell>
      )}
      <CategoryCell color={labelColor} borderColor={backgroundColor}>
        {categoryName}
      </CategoryCell>
      {seasons.map((season, seasonIndex) => {
        return (
          <SeasonCell key={seasonIndex} collapsed={false} backgroundColor={backgroundColor}>
            {season.seasonalProducts.map((product, productIndex) => {
              return (
                <BroadcastEntryLink key={productIndex} to={`/product/${product.productLink}`}>
                  <SeasonalProduct>
                    <TitleRow>
                      {product.enhancement && (
                        <Enhancement color={product.enhancement.color}>{product.enhancement.label}</Enhancement>
                      )}
                      <Title textColor={textColor}>{product.label}</Title>
                    </TitleRow>

                    <Description textColor={textColor}>{product.description}</Description>
                  </SeasonalProduct>
                </BroadcastEntryLink>
              );
            })}
          </SeasonCell>
        );
      })}
    </TableRow>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SeasonCategoryRow);
