import * as React from "react";
import styled from "styled-components";
import {Link} from "gatsby";
import CategoryTile from "./categoryTile";
import {trackSubNavClick, SubNavType} from "../../util/tracking";

import {
  ProductCategory,
  px,
  componentBreakpoints,
  NavigationOverlayStateType,
  navigationOverlayStates,
} from "../../models";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  flex-direction: row;
  display: none;

  /* desktop */
  @media (min-width: ${px(componentBreakpoints.categoryList)}) {
    display: flex;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Cell = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const CategoryLink = styled(props => <Link {...props} />)`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 4px;
`;

interface CloseOverlayLinkProps {
  updateNavigationOverlay: (newState: NavigationOverlayStateType) => void;
  category: ProductCategory;
}
const CloseOverlayLink = (props: CloseOverlayLinkProps) => (
  <CategoryLink
    to={`/product/${props.category.slug}`}
    onClick={() => {
      trackSubNavClick({type: SubNavType.Product, label: props.category.title, isMobile: false});
      props.updateNavigationOverlay(navigationOverlayStates.default);
    }}
  >
    <CategoryTile {...props.category} />
  </CategoryLink>
);

interface DesktopViewProps {
  live: ProductCategory[];
  digital: ProductCategory[];
  other: ProductCategory[];
  updateNavigationOverlay: (newState: NavigationOverlayStateType) => void;
}
const DesktopView = (props: DesktopViewProps) => {
  const {live, digital, other, updateNavigationOverlay} = props;

  return (
    <Wrapper>
      <Column style={{flex: 1}}>
        {/* live */}
        <Row style={{flex: 2}}>
          <CloseOverlayLink category={live[2]} updateNavigationOverlay={updateNavigationOverlay} />
        </Row>
        <Row style={{flex: 1}}>
          <Cell style={{flex: 1}}>
            <CloseOverlayLink category={live[1]} updateNavigationOverlay={updateNavigationOverlay} />
          </Cell>
          <Cell style={{flex: 1}}>
            <CloseOverlayLink category={live[0]} updateNavigationOverlay={updateNavigationOverlay} />
          </Cell>
        </Row>
      </Column>

      <Row style={{flex: 1}}>
        <Column style={{flex: 1}}>
          {/* digital */}
          <Cell style={{flex: 2}}>
            <CloseOverlayLink category={digital[0]} updateNavigationOverlay={updateNavigationOverlay} />
          </Cell>

          {/* other 1 */}
          <Cell style={{flex: 1}}>
            <CloseOverlayLink category={digital[1]} updateNavigationOverlay={updateNavigationOverlay} />
          </Cell>
        </Column>

        <Column style={{flex: 1}}>
          <Cell style={{flex: 1}}>
            <CloseOverlayLink category={other[2]} updateNavigationOverlay={updateNavigationOverlay} />
          </Cell>
          <Cell style={{flex: 1}}>
            <CloseOverlayLink category={other[1]} updateNavigationOverlay={updateNavigationOverlay} />
          </Cell>
          <Cell style={{flex: 1}}>
            <CloseOverlayLink category={other[0]} updateNavigationOverlay={updateNavigationOverlay} />
          </Cell>
        </Column>
      </Row>
    </Wrapper>
  );
};

export default DesktopView;
