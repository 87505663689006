import * as React from "react";
import styled from "styled-components";
import {TextFilter, Burger, Close} from "../../components";
import {RowDiv, px, componentBreakpoints, SearchableProductProps, CenteredDiv, colors} from "../../models";

const LinkList = styled(RowDiv)`
  display: flex;
  flex-direction: row;
  align-items: center;

  /* desktop */
  @media (min-width: ${px(componentBreakpoints.navigation)}) {
    display: none;
  }
`;
const PointerDiv = styled.div`
  pointer-events: all;
`;

const IconWrapper = styled(CenteredDiv)`
  width: 26px;
  height: 21px;
  margin: 0 20px;
  cursor: pointer;
`;

interface MobileLinksCompProps {
  isNavigationInverted: boolean;
  isOverlayActive: boolean;
  searchables: SearchableProductProps[];
  onMenuClick: () => void;
}
const MobileLinksComp = (props: MobileLinksCompProps) => {
  const {isNavigationInverted, isOverlayActive, searchables, onMenuClick} = props;
  return (
    <LinkList>
      {/* Search */}
      <TextFilter products={searchables} />

      <PointerDiv>
        <IconWrapper onClick={onMenuClick}>
          {isOverlayActive && <Close color={colors.black} />}
          {!isOverlayActive && <Burger color={isNavigationInverted ? colors.white : colors.black} strokeWidth={1.5} />}
        </IconWrapper>
      </PointerDiv>
    </LinkList>
  );
};

export default MobileLinksComp;
