import * as React from "react";
import styled from "styled-components";
import {Checkmark} from "../../../components";
import {DflRegionType, ColumnDiv, smallBold, dflRegions, colors, CenteredDiv, RowDiv} from "../../../models";

const RegionRadioGroup = styled(ColumnDiv)`
  width: 100%;
`;

interface RadioItemProps {
  active: boolean;
}
const RadioItem = styled.div`
  ${smallBold};
  color: ${(props: RadioItemProps) => (props.active ? colors.redDFL : colors.black)};
  padding: 24px;
  margin-bottom: 2px;
  background-color: ${colors.white};
  cursor: pointer;
`;
const RadioItemContent = styled(RowDiv)`
  justify-content: space-between;
`;
const IconWrapper = styled(CenteredDiv)`
  width: 16px;
  height: 12px;
`;


interface RadioItemCompProps {
  region: DflRegionType;
  selectedRegion: DflRegionType;
  onRegionClick: (region: DflRegionType) => void;
}
const RadioItemComp = (props: RadioItemCompProps) => {
  const {region, selectedRegion, onRegionClick} = props;
  return (
    <RadioItem active={selectedRegion === region} onClick={() => onRegionClick(region)}>
      <RadioItemContent>
        {region}
        {region === selectedRegion && <IconWrapper><Checkmark color={colors.redDFL} /></IconWrapper>}
      </RadioItemContent>
    </RadioItem>
  );
};


interface RegionRadioGroupCompProps {
  selectedRegion: DflRegionType;
  onRegionClick: (region: DflRegionType) => void;
}
const RegionRadioGroupComp = (props: RegionRadioGroupCompProps) => {
  const {selectedRegion, onRegionClick} = props;
  return (
    <RegionRadioGroup>
      <RadioItemComp region={dflRegions.emea} selectedRegion={selectedRegion} onRegionClick={onRegionClick} />
      <RadioItemComp region={dflRegions.asia} selectedRegion={selectedRegion} onRegionClick={onRegionClick} />
      <RadioItemComp region={dflRegions.americas} selectedRegion={selectedRegion} onRegionClick={onRegionClick} />
    </RegionRadioGroup>
  );
};

export default RegionRadioGroupComp;
