import * as React from "react";
import styled from "styled-components";
import Measure, {ContentRect} from "react-measure";
import {PinnedVisualAndContent} from "../../components";
import {stage, mediumLight, px} from "../../models";
import {columnSpan} from "../../util/util";

// Image
const ProductIntro = styled.div`
  width: 100%;
  max-width: ${px(stage.maxWidth)};
  margin: auto;
  display: flex;
`;

const TextWrap = styled.div`
  ${mediumLight};
  position: relative;
  line-height: 1.8em;
  width: 100%;
`;

const Image = styled.img`
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
`;

// COMPONENT
interface ProductIntroCompProps {
  image: string;
  imageAltText?: string;
  text: string;
  imageSize: "small" | "medium";
}
interface ProductIntroCompState {
  fullWidth: number;
}
class ProductIntroComp extends React.Component<ProductIntroCompProps, ProductIntroCompState> {
  constructor(props: ProductIntroCompProps) {
    super(props);
    this.state = {
      fullWidth: 0,
    };
    this.handleResize = this.handleResize.bind(this);
  }

  render() {
    const {text, image, imageSize, imageAltText} = this.props;
    const textWidth = (imageSize === "small")
      ? columnSpan(6, stage.columnWidth, stage.columnGap)
      : columnSpan(4, stage.columnWidth, stage.columnGap);
    const gapWidth = stage.columnWidth + (stage.columnGap * 1.5);


    return (
      <div>
        <Measure bounds={true} onResize={this.handleResize}>
          {({measureRef: mapRef}) => (
            <ProductIntro ref={mapRef}>
              <PinnedVisualAndContent
                stageWidth={stage.innerWidth}
                contentWidth={textWidth}
                contentElement={
                  <TextWrap>{text}</TextWrap>
                }
                visualElement={<Image src={image} alt={imageAltText} />}
                gapWidth={gapWidth}
                visualPosition="right"
              />
            </ProductIntro>
          )}
        </Measure>
      </div>
    );
  }

  handleResize(contentRect: ContentRect) {
    this.setState({
      fullWidth: (contentRect.entry && contentRect.entry.width) || 0,
    });
  }
}

export default ProductIntroComp;
