import * as React from "react";
import styled from "styled-components";
import {Link} from "gatsby";
import CategoryTile from "./categoryTile";
import {trackSubNavClick, SubNavType} from "../../util/tracking";

import {
  ProductCategory,
  px,
  breakpoints,
  RowDiv,
  ColumnDiv,
  baseBold,
  NavigationOverlayStateType,
  navigationOverlayStates,
} from "../../models";

const MobileNavWrapper = styled(ColumnDiv)`
  position: relative;
  width: 100%;
  height: 100%;

  box-sizing: border-box;

  /* desktop */
  @media (min-width: ${px(breakpoints.desktopHd)}) {
    display: none;
  }
`;

const Row = styled(RowDiv)`
  width: 100%;
  height: 100%;
`;

const CategoryLink = styled(props => <Link {...props} />)`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 4px;
`;

const Headline = styled.div`
  ${baseBold};
  width: 100%;
  font-size: 18px;
  margin-bottom: 50px;
  text-transform: uppercase;
  text-align: center;
`;

const Cell = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

interface CloseOverlayLinkProps {
  updateNavigationOverlay: (newState: NavigationOverlayStateType) => void;
  category: ProductCategory;
  isMobileNav?: boolean;
}
const CloseOverlayLink = (props: CloseOverlayLinkProps) => (
  <CategoryLink
    to={`/product/${props.category.slug}`}
    onClick={() => {
      trackSubNavClick({type: SubNavType.Product, label: props.category.title, isMobile: true});
      props.updateNavigationOverlay(navigationOverlayStates.default);
    }}
  >
    <CategoryTile {...props.category} isMobileNav={props.isMobileNav} />
  </CategoryLink>
);

interface MobileViewProps {
  live: ProductCategory[];
  digital: ProductCategory[];
  other: ProductCategory[];
  isMobileNav?: boolean;
  updateNavigationOverlay: (newState: NavigationOverlayStateType) => void;
}
const MobileView = (props: MobileViewProps) => {
  const {live, digital, other, isMobileNav, updateNavigationOverlay} = props;

  return (
    <div>
      {/*  CategoryList in mobile navigation overlay */}
      {isMobileNav && (
        <MobileNavWrapper>
          {/* first row: Live Match */}
          <Row style={{height: "25vw", minHeight: 100, maxHeight: 200}}>
            <Cell style={{flex: 1}}>
              <CloseOverlayLink
                isMobileNav={true}
                category={live[2]}
                updateNavigationOverlay={updateNavigationOverlay}
              />
            </Cell>
            <Cell style={{flex: 1}}>
              <CloseOverlayLink
                isMobileNav={true}
                category={live[1]}
                updateNavigationOverlay={updateNavigationOverlay}
              />
            </Cell>
          </Row>

          {/* second row: Match Highlights + Match Interviews */}
          <Row style={{height: "25vw", minHeight: 100, maxHeight: 200}}>
            <Cell style={{flex: 1}}>
              <CloseOverlayLink
                isMobileNav={true}
                category={digital[0]}
                updateNavigationOverlay={updateNavigationOverlay}
              />
            </Cell>
            <Cell style={{flex: 1}}>
              <CloseOverlayLink
                isMobileNav={true}
                category={live[0]}
                updateNavigationOverlay={updateNavigationOverlay}
              />
            </Cell>
          </Row>

          <Row style={{height: "25vw", minHeight: 100, maxHeight: 200}}>
            <Cell style={{flex: 1}}>
              <CloseOverlayLink
                isMobileNav={true}
                category={digital[1]}
                updateNavigationOverlay={updateNavigationOverlay}
              />
            </Cell>
            <Cell style={{flex: 1}}>
              <CloseOverlayLink
                isMobileNav={true}
                category={other[0]}
                updateNavigationOverlay={updateNavigationOverlay}
              />
            </Cell>
          </Row>

          <Row style={{height: "25vw", minHeight: 100, maxHeight: 200}}>
            <Cell style={{flex: 1}}>
              <CloseOverlayLink
                isMobileNav={true}
                category={other[1]}
                updateNavigationOverlay={updateNavigationOverlay}
              />
            </Cell>
            <Cell style={{flex: 1}}>
              <CloseOverlayLink
                isMobileNav={true}
                category={other[2]}
                updateNavigationOverlay={updateNavigationOverlay}
              />
            </Cell>
          </Row>
        </MobileNavWrapper>
      )}

      {/*  CategoryList on mobile landing page */}
      {!isMobileNav && (
        <MobileNavWrapper>
          <Headline>Bundesliga Products</Headline>
          <div style={{width: "100%", maxWidth: 800, margin: "0 auto"}}>
            {/* first row: Live Match */}
            <Row style={{height: "40vw", minHeight: 250}}>
              <CategoryLink to={`/product/${live[2].slug}`} style={{flex: 1}}>
                <CategoryTile {...live[2]} />
              </CategoryLink>
            </Row>

            {/* second row: Match Highlights + Match Interviews */}
            <Row style={{height: "24vw", minHeight: 125}}>
              <Cell style={{flex: 1}}>
                <CloseOverlayLink category={live[1]} updateNavigationOverlay={updateNavigationOverlay} />
              </Cell>
              <Cell style={{flex: 1}}>
                <CloseOverlayLink category={live[0]} updateNavigationOverlay={updateNavigationOverlay} />
              </Cell>
            </Row>

            <Row style={{height: "40vw", minHeight: 250}}>
              <ColumnDiv style={{flex: 1, height: "100%"}}>
                <Cell style={{flex: 1}}>
                  <CloseOverlayLink category={digital[0]} updateNavigationOverlay={updateNavigationOverlay} />
                </Cell>
              </ColumnDiv>

              <ColumnDiv style={{flex: 1, height: "100%"}}>
                <RowDiv style={{flex: 1}}>
                  <Cell style={{flex: 1}}>
                    <CloseOverlayLink category={digital[1]} updateNavigationOverlay={updateNavigationOverlay} />
                  </Cell>
                </RowDiv>
                <RowDiv style={{flex: 1}}>
                  <Cell style={{flex: 1}}>
                    <CloseOverlayLink category={other[0]} updateNavigationOverlay={updateNavigationOverlay} />
                  </Cell>
                </RowDiv>
              </ColumnDiv>
            </Row>

            <Row style={{height: "24vw", minHeight: 125}}>
              <Cell style={{flex: 1}}>
                <CloseOverlayLink category={other[1]} updateNavigationOverlay={updateNavigationOverlay} />
              </Cell>
              <Cell style={{flex: 1}}>
                <CloseOverlayLink category={other[2]} updateNavigationOverlay={updateNavigationOverlay} />
              </Cell>
            </Row>
          </div>
        </MobileNavWrapper>
      )}
    </div>
  );
};

export default MobileView;
