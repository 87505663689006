/**
 * Checkmark icon
 *
 */

// IMPORTS
import React from "react";

interface CheckmarkProps {
  color?: string;
  strokeWidth?: number;
}
const Checkmark = ({color = "#fff"}: CheckmarkProps) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 15 12"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill={color}>
      <g transform="translate(-324.000000, -644.000000)">
        <g transform="translate(10.000000, 584.000000)">
          <path d="M319.59,68.310731 L316.124613,64.8453436 C315.735064,64.4557947 315.103825,64.4546795 314.712902,64.8428494 L314.71,64.845731 C314.319261,65.2337184 314.31703,65.8650008 314.705018,66.2557399 C314.705845,66.2565732 314.706674,66.257405 314.707504,66.2582354 L319.59,71.140731 L328.515194,62.1170856 C328.902065,61.7259472 328.900336,61.0957716 328.511326,60.7067612 L328.509565,60.705 C328.122463,60.3178985 327.494848,60.3178985 327.107746,60.705 C327.106387,60.7063596 327.105031,60.7077231 327.103679,60.7090906 L319.59,68.310731 Z" />
        </g>
      </g>
    </g>
  </svg>
);

export default Checkmark;
