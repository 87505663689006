import {WeekDayType} from "./../../models/constants";
import {
  UPDATE_SELECTED_DATE_OPTION,
  UPDATE_SELECTED_WEEK_DAY,
  UPDATE_SELECTED_PREMATCH_START_AND_END_TIME,
  UPDATE_SELECTED_LIVEMATCH_FIRST_HALF_START_AND_END_TIME,
  UPDATE_SELECTED_LIVEMATCH_SECOND_HALF_START_AND_END_TIME,
  UPDATE_SELECTED_POST_MATCH_START_AND_END_TIME,
  UPDATE_SELECTED_DIGITAL_HIGHLIGHTS_CLIPS_TIME,
  UPDATE_SELECTED_SLOW_MOTION_PLAY_OUTS_TIME,
  UPDATE_SELECTED_POST_MATCH_INTERVIEWS_TIME,
  RundownActionTypes,
} from "./types";

export const updateSelectedDateOptionAction = (newDate: any) => {
  return {
    type: UPDATE_SELECTED_DATE_OPTION,
    payload: newDate,
  };
};

export const updateSelectedWeekDayAction = (newSelectedWeekDay: WeekDayType): RundownActionTypes => {
  return {
    type: UPDATE_SELECTED_WEEK_DAY,
    payload: newSelectedWeekDay,
  };
};

export const updateSelectedPreMatchStartAndEndTimeAction = (
  newSelectedPreMatchStartAndEndTime: [number, number, number, number],
): RundownActionTypes => {
  return {
    type: UPDATE_SELECTED_PREMATCH_START_AND_END_TIME,
    payload: newSelectedPreMatchStartAndEndTime,
  };
};

export const updateSelectedLiveMatchFirstHalfStartAndEndTimeAction = (
  newSelectedLiveMatchFirstHalfStartAndEndTime: [number, number, number, number],
): RundownActionTypes => {
  return {
    type: UPDATE_SELECTED_LIVEMATCH_FIRST_HALF_START_AND_END_TIME,
    payload: newSelectedLiveMatchFirstHalfStartAndEndTime,
  };
};

export const updateSelectedLiveMatchSecondHalfStartAndEndTimeAction = (
  newSelectedLiveMatchSecondHalfStartAndEndTime: [number, number, number, number],
): RundownActionTypes => {
  return {
    type: UPDATE_SELECTED_LIVEMATCH_SECOND_HALF_START_AND_END_TIME,
    payload: newSelectedLiveMatchSecondHalfStartAndEndTime,
  };
};

export const updateSelectedPostMatchStartAndEndTimeAction = (
  newSelectedPostMatchStartAndEndTime: [number, number, number, number],
): RundownActionTypes => {
  return {
    type: UPDATE_SELECTED_POST_MATCH_START_AND_END_TIME,
    payload: newSelectedPostMatchStartAndEndTime,
  };
};

export const updateSelectedDigitalHighlightsClipsTimeAction = (
  newSelectedDigitalHighlightsClipsTime: [number, number],
): RundownActionTypes => {
  return {
    type: UPDATE_SELECTED_DIGITAL_HIGHLIGHTS_CLIPS_TIME,
    payload: newSelectedDigitalHighlightsClipsTime,
  };
};

export const updateSelectedSlowMotionPlayOutsTimeAction = (
  newSelectedSlowMotionPlayOutsTime: [number, number],
): RundownActionTypes => {
  return {
    type: UPDATE_SELECTED_SLOW_MOTION_PLAY_OUTS_TIME,
    payload: newSelectedSlowMotionPlayOutsTime,
  };
};

export const updateSelectedPostMatchInterviewsTimeAction = (
  newSelectedPostMatchInterviewsTime: [number, number],
): RundownActionTypes => {
  return {
    type: UPDATE_SELECTED_POST_MATCH_INTERVIEWS_TIME,
    payload: newSelectedPostMatchInterviewsTime,
  };
};
