import React from "react";
import styled from "styled-components";
import {colors, fonts, fontSizesPx} from "../models";

const Select = styled.select<{isBold: boolean}>`
  width: 100%;
  font-family: ${fonts.condensedFont};
  font-size: ${fontSizesPx.micro};
  font-weight: ${p => (p.isBold ? fonts.weightBold : fonts.weightRegular)};
  background: none;
  background-color: none;
  border: none;
  border-bottom: 1px solid ${colors.grayDark2};
  padding-bottom: 3px;
  border-radius: 0;
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  /* temporary */
  outline: none;
`;

type OptionValue = string | number;
interface Option<T extends OptionValue> {
  value: T;
  label: string;
}

interface NativeSelectProps<T extends OptionValue> {
  options: [Option<T>];
  value?: T;
  onChange: (value: T) => void;
  selectedOption: any;
  isBold?: boolean;
}

function NativeSelect<T extends OptionValue>(props: NativeSelectProps<T>) {
  function handleOnChange(e: React.FormEvent<HTMLSelectElement>) {
    const {selectedIndex} = e.currentTarget;
    const selectedOption = props.options[selectedIndex];
    props.onChange(selectedOption.value);
  }
  return (
    <Select value={props.selectedOption} onChange={handleOnChange} isBold={props.isBold}>
      {props.options.map(option => {
        return (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        );
      })}
    </Select>
  );
}

export default NativeSelect;
