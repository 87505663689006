import * as React from "react";
import styled from "styled-components";
import {
  colors,
  smallRegular,
  smallBold,
  ContactPersonProps,
  px,
  breakpoints,
  microBold,
  microRegular,
} from "../../models";
import {decryptMail} from "../../util/util";

const ContactCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100%;
  overflow: auto;

  /* tablet */
  @media (min-width: ${px(breakpoints.tablet)}) {
    width: 520px;
    max-width: 520px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 40px 30px;
    overflow: auto;
  }

  /* Desktop HD */
  @media (min-width: ${px(breakpoints.desktopHd)}) {
  }
`;

interface AvaterProps {
  src: string;
}
const Avatar = styled.div`
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${(props: AvaterProps) => props.src});
  width: 70px;
  height: 70px;
  border-radius: 50%;
  align-self: center;
  margin-bottom: 16px;

  /* tablet */
  @media (min-width: ${px(breakpoints.tablet)}) {
    width: 100px;
    height: 100px;
    margin-bottom: 0;
  }
`;

const ContactDetails = styled.div`
  margin-left: 0;

  /* tablet */
  @media (min-width: ${px(breakpoints.tablet)}) {
    margin-left: 50px;
  }

  /* Desktop HD */
  @media (min-width: ${px(breakpoints.desktopHd)}) {
  }
`;

const Title = styled.div`
  ${microBold};
  text-align: center;
  margin-bottom: 6px;

  /* tablet */
  @media (min-width: ${px(breakpoints.tablet)}) {
    ${smallBold};
    margin-bottom: 10px;
  }
`;

const Text = styled.div`
  ${microRegular};
  text-align: center;
  max-width: 340px;

  /* tablet */
  @media (min-width: ${px(breakpoints.tablet)}) {
    ${smallRegular};
  }
`;

// @ts-ignore //TODO: Fehler mit styled() ?
const Email = styled(Text)`
  color: ${colors.redDFL};
  cursor: pointer;

  /* tablet */
  @media (min-width: ${px(breakpoints.tablet)}) {
    color: ${colors.redDFL};
  }
`;

const ContactCardComp = (props: ContactPersonProps) => {
  const {avatar, name, position, position_two, phone, email} = props;

  return (
    <ContactCard>
      <Avatar src={avatar} />
      <ContactDetails>
        <Title>{name}</Title>
        <Text>{position}</Text>
        {position_two && <Text>{position_two}</Text>}
        <Text>{phone}</Text>
        <Email
          onClick={() => {
            window.location.href = `mailto:${decryptMail(email)}`;
          }}
        >
          Send Email
        </Email>
      </ContactDetails>
    </ContactCard>
  );
};

export default ContactCardComp;
