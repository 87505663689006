import * as React from "react";
import styled from "styled-components";
import {colors, FullpageHeadline, FullpageSubheadline, fonts, fontSizesPx, breakpoint, stage} from "../../models";
import QuickFactsBarChartComp from "./quickFactsBarChart";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ITEMS_MAX_WIDTH = 380;

const QuickFactsFootnoteText = styled.p`
  margin: 0 0 3px 0;
`;

const QuickFactsFootnote = styled.div`
  font-family: ${fonts.condensedFont};
  font-size: ${fontSizesPx.macro};
  text-align: center;
  margin: 40px auto 0;
  max-width: ${stage.outerWidth}px;
  ${breakpoint.xl.up} {
    margin: 40px 0 0;
    text-align: right;
  };
`;

const QuickFactsItemImage = styled.img`
  width: 100%;
  height: auto;
  max-width: ${ITEMS_MAX_WIDTH}px;
  margin: auto;
`;

const QuickFactsItemMain = styled.div`
  margin-bottom: 30px;
  ${breakpoint.xl.up} {
    margin: auto 0 0;
  };
`;

const QuickFactsItemDescription = styled.p`
  font-size: ${fontSizesPx.small};
  line-height: 1.3em;
  color: ${colors.black};
  margin: 0;

  ${breakpoint.xl.up} {
    margin: 0 0 20px 0;
  };
`;

const QuickFactsItemHeadline = styled.h1`
  font-size: ${fontSizesPx.small};

  ${breakpoint.xl.up} {
    font-size: ${fontSizesPx.average};
    margin-bottom: 8px;
  };
`;

const QuickFactsItemArticle = styled.article`
  order: 2;
  margin-bottom: 10px;

  ${QuickFactsItemHeadline},
  ${QuickFactsItemDescription} {
    /* margin: 0; */
    text-align: center;
    ${breakpoint.xl.up} {
      text-align: left;
    };
  }

  ${breakpoint.xl.up} {
    order: 0;
    margin-bottom: 30px;
  };
`;

const QuickFactsItem = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${fonts.headerFont};
  margin: 0 auto;
  max-width: ${ITEMS_MAX_WIDTH}px;

  ${breakpoint.xs.down} {
     overflow-x: hidden;
  };
`;

const QuickFactsItemWrapper = styled.div`
  ${breakpoint.xl.up} {
      display: flex;
  };
`;

const QuickFactsNonSlider = styled.div`
  display: none;
  ${breakpoint.xl.up} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-columns: 1fr;
    grid-column-gap: 80px;
  };
`;

const QuickFactsSlider = styled.div`
  ${breakpoint.xl.up} {
    display: none;
 };
`;

const QuickFactsMain = styled.div`
  margin: 30px auto 0;
  max-width: ${stage.outerWidth}px;
  ${breakpoint.xl.up} {
    margin-top: 50px;
  };
`;

const QuickFacts = styled.div`
  /* NOTE: can remove when making the landing page responsive */
  padding: 0 15px;

  ${breakpoint.m.up} {
      padding: 0 80px;
  };

  ${breakpoint.l.up} {
      padding: 80px 0 0 0;
  };

  /* NOTE: can remove once FullpageHeadline and FullpageSubheadline are responsive */
  ${FullpageHeadline},
  ${FullpageSubheadline} {
    ${breakpoint.m.down} {
      width: auto;
    };
  }
`;
// NOTE: could refactor typecheck
interface QuickFactsCompProps {
  headline: string;
  subheadline: string;
  content: any;
  footnote: any;
}
const QuickFactsComp = (props: QuickFactsCompProps) => {
  const {headline, subheadline, content, footnote} = props;
  const SliderSettings = {
    dots: true,
  };

  const mainContent = content.map((item, i) => {
    return (
      <QuickFactsItemWrapper key={`infographics-${i}`}>
        <QuickFactsItem>
          <QuickFactsItemArticle>
            <QuickFactsItemHeadline>{item.heading}</QuickFactsItemHeadline>
            <QuickFactsItemDescription>{item.description}</QuickFactsItemDescription>
          </QuickFactsItemArticle>
          {/* NOTE: could add typecheck */}
          <QuickFactsItemMain>
            {item.barChartOrImage === "Bar Chart" && (
              <QuickFactsBarChartComp items={item.barChart} barChartMaxValue={item.barChartMaxValue} />
            )}
            {item.barChartOrImage === "Image" && (
              <QuickFactsItemImage src={item.image && item.image.sourceUrl} alt={item.heading} />
            )}
          </QuickFactsItemMain>
        </QuickFactsItem>
      </QuickFactsItemWrapper>
    );
  });

  return (
    <QuickFacts>
      <FullpageHeadline>{headline}</FullpageHeadline>
      <FullpageSubheadline>{subheadline}</FullpageSubheadline>
      <QuickFactsMain>
        <QuickFactsSlider>
          <Slider {...SliderSettings}>{mainContent}</Slider>
        </QuickFactsSlider>
        <QuickFactsNonSlider>{mainContent}</QuickFactsNonSlider>
        <QuickFactsFootnote>
          {footnote &&
            footnote.map((item, i) => {
              return <QuickFactsFootnoteText key={`item-${i}`}>{item.lineOfText}</QuickFactsFootnoteText>;
            })}
        </QuickFactsFootnote>
      </QuickFactsMain>
    </QuickFacts>
  );
};

export default QuickFactsComp;
