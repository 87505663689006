/**
 * A navigation header that sticks to the top of the page
 *
 */

// IMPORTS
import {Link, StaticQuery, graphql} from "gatsby";
import React from "react";
import styled from "styled-components";
import Measure, {ContentRect} from "react-measure";
import {Dispatch} from "redux";
import {connect} from "react-redux";
import DesktopLinks from "./desktopLinks";
import MobileLinks from "./mobileLinks";
import MobileOverlayContent from "./mobileOverlayContent/mobileOverlayContent";
import {AppState} from "../../store/";
import {
  updateRegionAction,
  updateNavigationOverlayAction,
  updateScreenWidthAction,
  updateIsNavigationInvertedAction,
} from "../../store/system/actions";
import {
  px,
  colors,
  stage,
  smallBold,
  DflRegionType,
  NavigationOverlayStateType,
  navigationOverlayStates,
  elementSizes,
  zIndices,
  timings,
  SearchableProductProps,
  wordpressPageIds,
  suggestionSublineMaxLength,
  componentBreakpoints,
  WPFieldGroupContactMainProps,
  ProductNode,
  contentTypes,
} from "../../models";
import {cutOffText, decodeHtml, productToRelatedProductProps} from "../../util/util";
import {
  trackNavClick,
  trackMainLogoClick,
  trackMobileMenuToggle,
  trackMobileLocalizationChange,
} from "../../util/tracking";

import logoWide from "../../images/logo_wide.svg";
import logoSmall from "../../images/logo__bundesliga.svg";
import ProductsDropdown from "./productsDropdown/productsDropdown";

// COMPONENT PROPERTIES
interface PropsFromState {
  selectedRegion: DflRegionType;
  overlayState: NavigationOverlayStateType;
  isHidden: boolean;
  isNavigationInverted: boolean;
  screenWidth: number;
  isTextDark?: boolean;
  isHomepageAboveVideo: boolean;
}
const mapStateToProps = (state: AppState): PropsFromState => ({
  selectedRegion: state.system.selectedRegion,
  overlayState: state.system.overlayState,
  isHidden: state.system.isNavigationHidden,
  isNavigationInverted: state.system.isNavigationInverted,
  screenWidth: state.system.screenWidth,
  isHomepageAboveVideo: state.system.isHomepageAboveVideo,
});

interface PropsFromDispatch {
  updateRegion: (region: DflRegionType) => void;
  updateNavigationOverlay: (newState: NavigationOverlayStateType) => void;
  updateScreenWidth: (newScreenWidth: number) => void;
  updateIsNavigationInverted: (isInverted: boolean) => void;
}
const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => {
  return {
    updateRegion: (region: DflRegionType) => {
      return dispatch(updateRegionAction(region));
    },
    updateNavigationOverlay: (newState: NavigationOverlayStateType) => {
      return dispatch(updateNavigationOverlayAction(newState));
    },
    updateScreenWidth: (newScreenWidth: number) => {
      return dispatch(updateScreenWidthAction(newScreenWidth));
    },
    updateIsNavigationInverted: (isInverted: boolean) => {
      return dispatch(updateIsNavigationInvertedAction(isInverted));
    },
  };
};
interface NavigationHeaderProps extends PropsFromState, PropsFromDispatch {
  showProductsOnMouseOver?: boolean;
  isTextDark?: boolean;
}

// STYLES
interface HeaderProps {
  isHidden: boolean;
}
const Header = styled.header`
  ${smallBold};
  width: 100vw;
  background: transparent;
  position: fixed;
  transition: top ${timings.moderate}ms;
  top: ${(props: HeaderProps) => (props.isHidden ? px(-elementSizes.navigationHeaderHeight) : px(0))};
  z-index: ${zIndices.zNavigation};
`;

interface HeaderContentBackgroundProps {
  isInverted: boolean;
}
const HeaderContentBackground = styled.div`
  position: relative;
  width: 100%;
  height: 70px;
  background-color: ${(props: HeaderContentBackgroundProps) => (props.isInverted ? colors.transparent : colors.white)};
  transition: background-color ${timings.hurried}ms;
  z-index: ${zIndices.zNavigationElements};

  /* desktop */
  @media (min-width: ${px(componentBreakpoints.navigation)}) {
    height: ${elementSizes.navigationHeaderHeight}px;
  }
`;
const HeaderContent = styled.div`
  max-width: ${px(stage.outerWidth)};
  height: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const PointerDiv = styled.div`
  pointer-events: all;
`;

const LogoWrapper = styled.div`
  width: 70px;
  height: 70px;
  padding: 7px;
  box-sizing: border-box;

  /* desktop */
  @media (min-width: ${px(componentBreakpoints.navigation)}) {
    width: unset;
    padding: 18px 18px 18px 0;
  }
`;
const Logo = styled.img`
  width: 100%;
  height: 100%;
`;

// Overlay
interface OverlayProps {
  overlayActive: boolean;
}

const Overlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: ${colors.grayUltraLight};
  z-index: ${zIndices.zNavigationOverlay};
  pointer-events: ${(props: OverlayProps) => (props.overlayActive ? "all" : "none")};
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;

  transition: opacity ${timings.instant}ms;
  opacity: ${(props: OverlayProps) => (props.overlayActive ? 1 : 0)};
`;

interface QueryProps {
  allWpPage: {
    edges: [{
      node: {
        databaseId: number;
        title: string;
        slug: string;
        intro: {
          introText: string;
        };
      };
    }];
  };
  wpPost: {
    contacts: {
      mainBli: WPFieldGroupContactMainProps;
      mainDfl: WPFieldGroupContactMainProps;
    };
  };
  allWpPost: {
    edges: ProductNode[];
  };
}

// COMPONENT
const NavigationHeader = (props: NavigationHeaderProps) => {
  const {
    overlayState,
    updateNavigationOverlay,
    isHidden,
    updateRegion,
    selectedRegion,
    isNavigationInverted,
    screenWidth,
    updateScreenWidth,
    updateIsNavigationInverted,
    isTextDark,
    isHomepageAboveVideo,
  } = props;
  const overlayActive =
    overlayState === navigationOverlayStates.mobileActive || overlayState === navigationOverlayStates.productsActive;

  if (typeof window !== "undefined") {
    if (overlayActive) {
      document.body.classList.add("overflow-y-hidden");
    } else {
      document.body.classList.remove("overflow-y-hidden");
    }
  }
  return (
    <StaticQuery
      query={graphql`
        query {
          allWpPage {
            edges {
              node {
                databaseId
                title
                slug
                intro {
                  introText
                }
              }
            }
          }
          wpPost(databaseId: {eq: 1}) {
            contacts {
              mainBli {
                name
                email
                phone
                website
              }
              mainDfl {
                email
                name
                phone
                website
              }
            }
          }
          allWpPost(filter: {databaseId: {nin: [1272, 1]}}) {
            edges {
              node {
                product_details {
                  productContent {
                    productCategory
                    productContentType
                    productImageOrVideoSettings {
                      decoration
                      imageSize
                      layout
                      videoSize
                      productImageOrVideoHeadline
                      attributes {
                        attribute
                      }
                    }
                    productImageGroup {
                      isImageLocalised
                      localizedImageGroup {
                        productLocalizedImageImageEmea {
                          altText
                          sourceUrl
                        }
                        productLocalizedImageImageAmericas {
                          altText
                          sourceUrl
                        }
                        productLocalizedImageImageAsia {
                          altText
                          sourceUrl
                        }
                      }
                      image {
                        altText
                        sourceUrl
                      }
                    }
                    productVideoGroup {
                      isVideoLocalised
                      videoId
                      localizedVideoGroup {
                        videoEmeaId
                        videoAmericasId
                        videoAsiaId
                      }
                    }
                    productThreeColumnModule {
                      overallTitle
                      threeColumnLocalizedContentColumnOne {
                        headline
                        attributes {
                          attribute
                        }
                      }
                      threeColumnLocalizedContentColumnTwo {
                        headline
                        attributes {
                          attribute
                        }
                      }
                      threeColumnLocalizedContentColumnThree {
                        headline
                        attributes {
                          attribute
                        }
                      }
                      threeColumnLocalizedContentIsWithHeadline
                      threeColumnLocalizedContentHeadlineGroup {
                        headline
                        subheadline
                      }
                      threeColumnLocalizedContentIsWithLocalizedContent
                      threeColumnLocalizedContentLocalizedContent {
                        contentType
                        localizedImage {
                          imageEmea {
                            altText
                            sourceUrl
                          }
                          imageAmericas {
                            altText
                            sourceUrl
                          }
                          imageAsia {
                            altText
                            sourceUrl
                          }
                        }
                        localizedVideo {
                          videoEmeaId
                          videoAmericasId
                          videoAsiaId
                        }
                      }
                    }
                    productButton {
                      label
                      link
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data: QueryProps) => {
        // get all product pages
        const productPages = data.allWpPage.edges.filter(page => {
          return (
            page.node.databaseId === wordpressPageIds.bundesligaMatches ||
            page.node.databaseId === wordpressPageIds.matchInterviews ||
            page.node.databaseId === wordpressPageIds.matchHighlights ||
            page.node.databaseId === wordpressPageIds.digitalContent ||
            page.node.databaseId === wordpressPageIds.promoTrailerContent ||
            page.node.databaseId === wordpressPageIds.seasonalContent ||
            page.node.databaseId === wordpressPageIds.monthlyContent ||
            page.node.databaseId === wordpressPageIds.weeklyContent
          );
        });

        // create searchables for the text filter component
        const searchables: SearchableProductProps[] = [];

        // make page titles and product titles searchable
        productPages.forEach(page => {
          searchables.push({
            name: decodeHtml(page.node.title),
            subline: cutOffText(page.node.intro.introText, suggestionSublineMaxLength),
            link: `/product/${page.node.slug.trim()}`,
            type: "category",
          });
        });

        // make products searchable
        if (data && data.allWpPost && data.allWpPost.edges) {
          data.allWpPost.edges.forEach(edge => {
            const props = productToRelatedProductProps(edge);
            const {
              node: {
                product_details: {
                  productContent: {
                    productContentType: contentType,
                    productImageOrVideoSettings: imageOrVideoSettings,
                    productThreeColumnModule: {
                      threeColumnLocalizedContentColumnOne: columnOne,
                      threeColumnLocalizedContentColumnTwo: columnTwo,
                      threeColumnLocalizedContentColumnThree: columnThree,
                    },
                  },
                },
              },
            } = edge;

            // for the threeColumn module, add a search entry for each column
            if (contentType === contentTypes.threeColumn) {
              [columnOne, columnTwo, columnThree].forEach(column => {
                const attributes = column.attributes;
                const subline = cutOffText(attributes[0].attribute, suggestionSublineMaxLength);

                searchables.push({
                  name: column.headline,
                  subline,
                  link: `/product/${props.link}`,
                  type: "product",
                });
              });

              // for the image or video module, add a singe search entry
            } else if (contentType === contentTypes.image || contentType === contentTypes.video) {
              const attributes = imageOrVideoSettings.attributes;
              const subline = cutOffText(attributes[0].attribute, suggestionSublineMaxLength);

              searchables.push({
                name: props.title,
                subline,
                link: `/product/${props.link}`,
                type: "product",
              });
            }
          });
        }

        const mainDfl = data.wpPost.contacts.mainDfl[0];
        const mainBli = data.wpPost.contacts.mainBli[0];

        const isMobile = screenWidth < componentBreakpoints.navigation;

        return (
          <Measure
            bounds={true}
            onResize={(contentRect: ContentRect) => {
              if (contentRect && contentRect.entry) {
                updateScreenWidth(contentRect.entry.width);
              }
            }}
          >
            {({measureRef: headerRef}) => (
              // <PinnedImageRow >
              <>
                <Header isHidden={isHidden} ref={headerRef}>
                  <Overlay
                    onWheel={(event: React.WheelEvent) => {
                      if (overlayState === navigationOverlayStates.productsActive) {
                        event.preventDefault();
                        event.stopPropagation();
                      }
                    }}
                    overlayActive={overlayActive}
                  >
                    {/* Content for Mobile overlay */}
                    <MobileOverlayContent
                      overlayState={overlayState}
                      onRegionClick={region => {
                        trackMobileLocalizationChange(region);
                        updateRegion(region);
                      }}
                      onButtonClick={() => updateNavigationOverlay(navigationOverlayStates.default)}
                      selectedRegion={selectedRegion}
                      mainDfl={mainDfl}
                      mainBli={mainBli}
                    />

                    <ProductsDropdown
                      show={overlayState === navigationOverlayStates.productsActive}
                      onMouseLeave={() => {
                        if (isHomepageAboveVideo) {
                          updateIsNavigationInverted(true);
                        }
                      }}
                    />
                  </Overlay>

                  <HeaderContentBackground isInverted={isNavigationInverted && !overlayActive}>
                    <HeaderContent
                      onMouseOver={() => {
                        if (!isMobile) {
                          updateNavigationOverlay(navigationOverlayStates.default);
                        }
                      }}
                    >
                      {/* Logo */}
                      <PointerDiv>
                        <Link
                          to="/"
                          onClick={() => {
                            trackMainLogoClick();
                            updateNavigationOverlay(navigationOverlayStates.default);
                          }}
                        >
                          <LogoWrapper>
                            <Logo src={isMobile ? logoSmall : logoWide} />
                          </LogoWrapper>
                        </Link>
                      </PointerDiv>

                      {/* Desktop view of the links and search */}
                      <DesktopLinks
                        isNavigationInverted={isNavigationInverted}
                        isTextDark={isTextDark}
                        selectedRegion={selectedRegion}
                        overlayState={overlayState}
                        onProductsMouseOver={() => {
                          updateIsNavigationInverted(false);
                          updateNavigationOverlay(navigationOverlayStates.productsActive);
                        }}
                        onProductsMouseLeave={() => {
                          if (isHomepageAboveVideo) {
                            updateIsNavigationInverted(true);
                          }
                        }}
                        onUpdateRegionClick={region => {
                          trackNavClick("Region");
                          updateRegion(region);
                          updateNavigationOverlay(navigationOverlayStates.default);
                        }}
                        onPageLinkClick={(label: string) => {
                          trackNavClick(label);
                          updateNavigationOverlay(navigationOverlayStates.default);
                        }}
                        searchables={searchables}
                      />

                      {/* mobile view of the search and the menu icon */}
                      <MobileLinks
                        isNavigationInverted={isNavigationInverted}
                        isOverlayActive={overlayState === navigationOverlayStates.mobileActive}
                        searchables={searchables}
                        onMenuClick={() => {
                          trackMobileMenuToggle(overlayState === navigationOverlayStates.default);
                          updateNavigationOverlay(
                            overlayState === navigationOverlayStates.mobileActive
                              ? navigationOverlayStates.default
                              : navigationOverlayStates.mobileActive,
                          );
                        }}
                      />
                    </HeaderContent>
                  </HeaderContentBackground>
                </Header>
              </>
            )}
          </Measure>
        );
      }}
    />
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NavigationHeader);
