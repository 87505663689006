import * as React from "react";
import styled from "styled-components";
import {connect} from "react-redux";
import {AppState} from "../../store";
import {colors} from "../../models";

interface PropsFromState {
  screenWidth: number;
}
const mapStateToProps = (state: AppState): PropsFromState => {
  return {
    screenWidth: state.system.screenWidth,
  };
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${colors.black};
  z-index: 1;
`;

interface MainStageVideoProps {
  useVerticalVideo: boolean;
}
const MainStageVideo = styled.video`
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;

  /* depending on either using vertical or horizontal video, make the video fit the container */
  ${(props: MainStageVideoProps) => {
    return props.useVerticalVideo
      ? `
      @media screen and (max-aspect-ratio: 9/16) {
        height: 100%;
      }
      @media screen and (min-aspect-ratio: 9/16) {
        width: 100%;
      }
  `
      : `
    @media screen and (max-aspect-ratio: 16/9) { /* taller than 16:9 */
      height: 100%;
    }
    @media screen and (min-aspect-ratio: 16/9) { /* 16:9 or flatter */
      width: 100%;
    }
    `;
  }}
`;

interface MainstageVideoCompProps extends PropsFromState {
  srcHorizontal: string;
  srcVertical: string;
}
const mainstageVideoComp = (props: MainstageVideoCompProps) => {
  const {srcHorizontal, srcVertical, screenWidth} = props;
  const useVerticalVideo = screenWidth < 480;

  return (
    <Wrapper>
      {!useVerticalVideo && (
        <MainStageVideo useVerticalVideo={useVerticalVideo} autoPlay muted loop playsInline>
          <source src={srcHorizontal} type="video/mp4" />
        </MainStageVideo>
      )}
      {useVerticalVideo && (
        <MainStageVideo useVerticalVideo={useVerticalVideo} autoPlay muted loop playsInline>
          <source src={srcVertical} type="video/mp4" />
        </MainStageVideo>
      )}
    </Wrapper>
  );
};

export default connect(mapStateToProps)(mainstageVideoComp);
