import React from "react";
import styled from "styled-components";
import {smallBold, smallRegular, colors, SearchableProductProps, zIndices} from "../../../models";

const SorryMessage = styled.div`
  background-color: ${colors.white};
  padding: 24px 15px;
`;
const SorryMessageValue = styled.span`
  ${smallBold};
`;

const SuggestionTitle = styled.div`
  ${smallBold};
`;
const SuggestionSubline = styled.div`
  ${smallRegular};
`;
const CategoryLink = styled.div`
  background-color: ${colors.grayUltraLight};
  padding: 8px 10px;
  display: block;
  border: 1px solid ${colors.grayMedium};
  margin-top: -1px;

  &:hover {
    background-color: ${colors.grayLight};
  }

  ${SuggestionTitle} {
    color: ${colors.redDFL};
  }
`;
interface SuggestionContainerProps {
  hasInput: boolean;
}
const SuggestionContainer = styled.div`
  display: ${(props: SuggestionContainerProps) => (props.hasInput ? "block" : "none")};
  max-height: 90vh;
  overflow-y: scroll;
  box-shadow: 0 3px 8px ${colors.blackAlpha20};
  z-index: ${zIndices.zNavigation - 5};
  position: relative;
`;

// const Background = styled.div`
//   width: 100vw;
//   height: 100vh;
//   position: fixed;
//   background-color: rgba(0, 0, 0, 0.1);
//   top: 0;
//   left: 0;
//   z-index: ${zIndices.zNavigation - 10};
// `;

interface SuggestionContainerCompProps {
  hasInput: boolean;
  resultsEmpty: boolean;
  searchTerm: string;
  products: SearchableProductProps[];
  containerProps: any;
  children: JSX.Element;
  updateHoveredLink: (link: string) => void;
}
class SuggestionContainerComp extends React.Component<SuggestionContainerCompProps> {
  render() {
    const {resultsEmpty, hasInput, searchTerm, containerProps, children, updateHoveredLink} = this.props;

    return (
      <div>
        {resultsEmpty ? (
          <SuggestionContainer hasInput={hasInput}>
            <SorryMessage>
              <SuggestionSubline>
                <SorryMessageValue>{searchTerm}</SorryMessageValue> could not be matched. Explore our products
              </SuggestionSubline>
            </SorryMessage>

            {this.props.products
              .filter(product => product.type === "category")
              .map((category, categoryIndex) => {
                return (
                  <CategoryLink
                    key={`category-${categoryIndex}`}
                    onMouseEnter={() => updateHoveredLink(category.link)}
                    onMouseLeave={() => updateHoveredLink(null)}
                  >
                    <SuggestionTitle>{category.name}</SuggestionTitle>
                    <SuggestionSubline>{category.subline}</SuggestionSubline>
                  </CategoryLink>
                );
              })}
          </SuggestionContainer>
        ) : (
          <SuggestionContainer {...containerProps} hasInput={hasInput}>
            {children}
          </SuggestionContainer>
        )}
      </div>
    );
  }
}

export default SuggestionContainerComp;
