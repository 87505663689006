import * as React from "react";
import styled from "styled-components";
import {Caret} from "../../../components";
import {smallBold, colors, CenteredDiv, RowDiv} from "../../../models";

const MobileOverlayButton = styled.button`
  ${smallBold};
  width: 100%;
  padding: 24px;
  text-transform: uppercase;
  background-color: ${colors.white};
  outline: none;
  border: none;
  cursor: pointer;
`;
const ButtonContent = styled(RowDiv)`
  justify-content: space-between;
  .mobile-active & {
    color: ${colors.redDFL};
  }
`;
const CaretWrapper = styled(CenteredDiv)`
  width: 22px;
  height: 22px;
  padding: 4px;
  box-sizing: border-box;
  transform: rotate(-90deg);
`;

interface MobileOverlayButtonCompProps {
  label: string;
  onButtonClick: () => void;
}
const MobileOverlayButtonComp = (props: MobileOverlayButtonCompProps) => {
  const {label, onButtonClick} = props;
  return (
    <MobileOverlayButton onClick={onButtonClick}>
      <ButtonContent>
        <div>{label}</div>
        <CaretWrapper>
          <Caret color={colors.black} />
        </CaretWrapper>
      </ButtonContent>
    </MobileOverlayButton>
  );
};

export default MobileOverlayButtonComp;
