import * as React from "react";
import styled from "styled-components";
import {Column, CTA, PinnedVisualAndContent} from "../../components";
import {
  stage,
  px,
  ProductDescriptionProps,
  productWithImageSizes,
  productWithVideoSizes,
  ProductWithImageSizeType,
  productWithDecorations,
  microBold,
  colors,
  decorationMap,
  ColorType,
  DflRegionType,
  breakpoints,
  MobileVisualWrapper,
  contentTypes,
} from "../../models";
import {getLocalizedProductImage, getLocalizedVideo, slugify, columnSpan} from "../../util/util";
import VideoPlayer from "../videoPlayer/videoPlayer";

interface ProductWrapperProps {
  enhanced: boolean;
}
const ProductWrapper = styled.div`
  width: 100%;
  position: relative;
  max-width: ${px(stage.maxWidth)};
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 50px 0;
  background-color: ${(props: ProductWrapperProps) => (props.enhanced ? colors.grayUltraLight : "none")};
`;

// Sized Image and Video
interface SizedImageContainerProps {
  imageSize: ProductWithImageSizeType;
  layout: "imageLeft" | "imageRight";
}

const SizedImageContainer = styled.div`
  width: ${(props: SizedImageContainerProps) =>
    props.imageSize === productWithImageSizes.small
      ? px(stage.columnWidth * 5 + stage.columnGap * 5)
      : px(stage.columnWidth * 7 + stage.columnGap * 7)};
  height: ${(props: SizedImageContainerProps) => (props.imageSize === productWithImageSizes.small ? px(290) : px(405))};
  order: ${(props: SizedImageContainerProps) => (props.layout === "imageLeft" ? -1 : 1)};
  margin-right: ${(props: SizedImageContainerProps) =>
    props.layout === "imageLeft" ? px(stage.columnWidth * 1 + stage.columnGap * 0.5) : 0};
  margin-left: ${(props: SizedImageContainerProps) =>
    props.layout === "imageLeft" ? 0 : px(stage.columnWidth * 1 + stage.columnGap * 0.5)};
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const SizedImage = styled.img`
  width: 100%;
`;

// text content
interface StageWidthContainerProps {
  width: number;
  layout: "imageLeft" | "imageRight";
}
const StageWidthContainer = styled.div`
  width: ${(props: StageWidthContainerProps) => px(props.width)};
  margin: auto;
  display: flex;
  flex-direction: row;
`;

interface DecorationProps {
  color: ColorType;
}
const Decoration = styled.div`
  ${microBold};
  color: ${colors.white};
  background-color: ${(props: DecorationProps) => props.color};
  align-self: flex-start;
  padding: 10px 25px 8px;
  margin: 30px 0 0 22px;

  /* tablet */
  @media (min-width: ${px(breakpoints.tablet)}) {
    padding: 12px 30px 10px;
    margin: 30px 0 0 0;
  }

  /* desktop */
  @media (min-width: ${px(breakpoints.desktopHd)}) {
    padding: 12px 30px 10px;
    margin: 0 0 36px 0;
  }
`;

const InfoBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  /* tablet */
  @media (min-width: ${px(breakpoints.tablet)}) {
    width: ${px(stage.innerWidthTablet)};
    margin: 0 auto;
    flex-direction: column;
  }

  /* desktop */
  @media (min-width: ${px(breakpoints.desktopHd)}) {
    width: ${px(stage.columnWidth * 4 + stage.columnGap * 3)};
    flex-direction: column;
  }
`;
const ButtonWrapper = styled.div`
  margin-top: 0;
  width: 100%;
  padding: 0 22px;
  box-sizing: border-box;

  /* tablet */
  @media (min-width: ${px(breakpoints.tablet)}) {
    padding: 0;
    width: 353px;
    margin: 0 auto 0;
  }

  /* desktop */
  @media (min-width: ${px(breakpoints.desktopHd)}) {
    margin-top: 50px;
  }
`;

const Anchor = styled.div`
  position: absolute;
  top: -80px;
`;

const Image = styled.img`
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
`;

interface ProductDescriptionComp extends ProductDescriptionProps {
  selectedRegion?: DflRegionType;
  screenWidth?: number;
}

const ProductDescriptionComp = (props: ProductDescriptionComp) => {
  const {
    imageUrl,
    imageAltText,
    imageSize,
    title,
    descriptions,
    layout,
    button,
    decoration,
    isLocalized,
    localizedImages,
    localizedVideos,
    selectedRegion,
    videoId,
    videoSize,
    screenWidth,
    contentType,
  } = props;

  const localizedImageUrl = localizedImages && getLocalizedProductImage(localizedImages, selectedRegion);
  const localizedVideoId = localizedVideos && getLocalizedVideo(localizedVideos, selectedRegion);
  const isVideo = contentType === contentTypes.video;
  const isImage = contentType === contentTypes.image;

  const hasLocalizedImageLink = isImage && isLocalized && localizedImageUrl !== null;
  const imageSrc = hasLocalizedImageLink ? getLocalizedProductImage(localizedImages, selectedRegion) : imageUrl;

  const hasLocalizedVideoLink = isVideo && isLocalized && localizedVideoId !== null;
  const videoSrc = hasLocalizedVideoLink ? getLocalizedVideo(localizedVideos, selectedRegion) : videoId;

  const cleanedTitle = title.trim();

  // create the infoBox
  const infoBox = (
    <InfoBox>
      {decoration !== productWithDecorations.none && (
        <Decoration color={decorationMap.get(decoration).color}>{decorationMap.get(decoration).label}</Decoration>
      )}
      <Column title={cleanedTitle} descriptions={descriptions} />

      {button && button.link && (
        <ButtonWrapper>
          <CTA {...button} />
        </ButtonWrapper>
      )}
    </InfoBox>
  );

  // create the visual element
  const visualElement = isImage ? (
    <Image src={imageSrc} alt={imageAltText} />
  ) : isVideo ? (
    <VideoPlayer playerId={videoSrc} />
  ) : null;

  // check type of visual and size
  const isLargeImage = isImage && imageSrc && imageSize === productWithImageSizes.large;
  const isSmallOrMediumImage = isImage && imageSrc && imageSize !== productWithImageSizes.large;
  const isLargeVideo = isVideo && videoSrc && videoSize === productWithVideoSizes.large;
  const isMediumVideo = isVideo && videoSrc && videoSize === productWithVideoSizes.medium;

  return (
    <ProductWrapper enhanced={decoration === productWithDecorations.enhancement}>
      <Anchor id={slugify(cleanedTitle)} />

      {/* desktop size large image or video*/}
      {screenWidth >= 1440 && (isLargeImage || isLargeVideo) && (
        <PinnedVisualAndContent
          stageWidth={stage.outerWidth}
          gapWidth={stage.columnWidth + stage.columnGap}
          visualPosition={layout === "imageLeft" ? "left" : "right"}
          contentWidth={columnSpan(4, stage.columnWidth, stage.columnGap)}
          contentElement={infoBox}
          visualElement={visualElement}
        />
      )}

      {/* desktop size small or medium image*/}
      {screenWidth >= 1440 && isSmallOrMediumImage && (
        <StageWidthContainer
          layout={layout}
          width={imageSize === productWithImageSizes.small ? stage.innerWidth : stage.outerWidth}
        >
          {contentType === contentTypes.image && (
            <SizedImageContainer layout={layout} imageSize={imageSize}>
              <SizedImage src={imageSrc} />
            </SizedImageContainer>
          )}
          {/* infobox */}
          {infoBox}
        </StageWidthContainer>
      )}

      {/* desktop size medium video*/}
      {screenWidth >= 1440 && isMediumVideo && (
        <StageWidthContainer
          layout={layout}
          width={imageSize === productWithImageSizes.small ? stage.innerWidth : stage.outerWidth}
        >
          {/* video */}
          {contentType === contentTypes.video && (
            <SizedImageContainer layout={layout} imageSize={imageSize}>
              <VideoPlayer playerId={videoSrc} />
            </SizedImageContainer>
          )}

          {/* infobox */}
          {infoBox}
        </StageWidthContainer>
      )}

      {/* mobile sizes */}
      {screenWidth < 1440 && (
        <div style={{width: "100%"}}>
          <MobileVisualWrapper visualWidth={screenWidth}>{visualElement}</MobileVisualWrapper>

          {infoBox}
        </div>
      )}
    </ProductWrapper>
  );
};

export default ProductDescriptionComp;
