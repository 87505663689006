import * as React from "react";
import styled from "styled-components";
import Measure, {ContentRect} from "react-measure";
import {stage, px} from "../models";


// Image
const PinnedImageRow = styled.div`
  width: 100%;
  max-width: ${px(stage.maxWidth)};
  margin: auto;
  display: flex;
  flex-direction: row;
`;

interface ContentWrapperProps {
  marginLeft: number;
  width: number;
}
const ContentWrapper = styled.div`
  margin-left: ${(props: ContentWrapperProps) => px(props.marginLeft)};
  width: ${(props: ContentWrapperProps) => px(props.width)};
`;


interface ImageWrapperProps {
  marginLeft: number;
  width: number;
  isLeft: boolean;
}
const VisualContentWrapper = styled.div`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  margin-left: ${(props: ImageWrapperProps) => px(props.marginLeft)};
  order: ${(props: ImageWrapperProps) => props.isLeft ? -1 : 1};
  width: ${(props: ImageWrapperProps) => px(props.width)};
  height: ${(props: ImageWrapperProps) => px((props.width / 16) * 9)};
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;


// COMPONENT
interface PinnedImageRowCompProps {
  stageWidth: number;
  visualPosition: "left" | "right";
  visualElement: JSX.Element;
  contentElement: JSX.Element;
  contentWidth: number;
  gapWidth: number;
}
interface PinnedImageRowCompState {
  elementWidth: number;
}
class PinnedImageRowComp extends React.Component<PinnedImageRowCompProps, PinnedImageRowCompState> {
  constructor(props: PinnedImageRowCompProps) {
    super(props);
    this.state = {
      elementWidth: 0,
    };
    this.handleResize = this.handleResize.bind(this);
  }

  render() {
    const {stageWidth, visualPosition, visualElement, contentElement, contentWidth, gapWidth} = this.props;
    const {elementWidth} = this.state;
   
    const offsetWidth = (elementWidth - stageWidth) / 2;
    const imageWidth = elementWidth - (offsetWidth + contentWidth + gapWidth);
    const isVisualLeft = visualPosition === "left";

    return (
      <Measure bounds={true} onResize={this.handleResize}>
        {({measureRef: mapRef}) => (
          <PinnedImageRow ref={mapRef}>
            <ContentWrapper
              marginLeft={isVisualLeft ? gapWidth : offsetWidth}
              width={contentWidth}
            >{contentElement}
            </ContentWrapper>

            <VisualContentWrapper
              isLeft={isVisualLeft}
              marginLeft={isVisualLeft ? 0 : gapWidth}
              width={imageWidth}
            >
              {visualElement}
            </VisualContentWrapper>
          </PinnedImageRow>
        )}
      </Measure>
    );
  }

  handleResize(contentRect: ContentRect) {
    this.setState({
      elementWidth: (contentRect.entry && contentRect.entry.width) || 0,
    });
  }
}

export default PinnedImageRowComp;
