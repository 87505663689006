/**
 * Image component that shows a placeholder of the same dimensions until
 * has finished loading.
 * 
 */

import React from "react";

interface ImageWithSpinnerProps {
  imageUrl: string;
  width: number;
  height: number;
}
interface ImageWithSpinnerState {
  imageStatus: "loading" | "loaded" | "failed to load";
}
class ImageWithSpinner extends React.Component<ImageWithSpinnerProps, ImageWithSpinnerState> {
  constructor(props: ImageWithSpinnerProps) {
    super(props);
    this.state = { imageStatus: "loading" };

    this.handleImageLoaded = this.handleImageLoaded.bind(this);
    this.handleImageErrored = this.handleImageErrored.bind(this);
  }

  handleImageLoaded() {
    this.setState({ imageStatus: "loaded" });
  }

  handleImageErrored() {
    this.setState({ imageStatus: "failed to load" });
  }

  render() {
    return (
      <div>
        <img
          style={{
            display: this.state.imageStatus === "loaded" ? "block" : "none",
            width: this.props.width,
            height: this.props.height,
          }}
          src={this.props.imageUrl}
          onLoad={this.handleImageLoaded}
          onError={this.handleImageErrored}
        />
        <svg
          style={{
            display: this.state.imageStatus === "loaded" ? "none" : "block",
          }}
          width={this.props.width}
          height={this.props.height}
        />
      </div>
    );
  }
}
export default ImageWithSpinner;

