import React from "react";
import styled from "styled-components";
import {Link} from "gatsby";
import {ColorType, px, scheduleSizes, condensedMacroBold, condensedSmallBold, condensedSmallRegular} from "../../../models";

interface RegularCellProps {
  collapsed: boolean;
  backgroundColor: ColorType;
  opacity?: number;
}
export const RegularCell = styled.td`
  width: ${(props: RegularCellProps) => props.collapsed ? px(scheduleSizes.collapsedColumnWidth) : "initial"};
  transition: width 0.5s, opacity 0.25s, color 0.5s;
  border: 1px solid white;
  box-sizing: border-box;
  vertical-align: top;
  text-align: left;
  background-color: ${(props: RegularCellProps) => props.backgroundColor};
  opacity: ${(props: RegularCellProps) => (props.opacity ? props.opacity : 1)};
  overflow: hidden;
`;

export const CategoryAggregationCell = styled.td`
  width: ${px(scheduleSizes.categoryAggregationLabelColumn)};
  position: relative;
  padding: 0;
`;

interface CategoryCellProps {
  color: ColorType;
  borderColor: ColorType;
}
export const CategoryCell = styled.td`
  ${condensedMacroBold};
  text-transform: uppercase;
  width: ${px(scheduleSizes.categoryLabelColumn)};
  vertical-align: top;
  text-align: left;
  color: ${(props: CategoryCellProps) => props.color};
  border-left: 2px solid ${(props: CategoryCellProps) => props.borderColor};
  padding: 0 ${px(scheduleSizes.categoryLabelPadding)};
  box-sizing: content-box;
`;

export const VerticalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
export const VerticalRegular = styled.div`
  ${condensedSmallRegular};
  text-transform: uppercase;
`;
export const VerticalBold = styled.div`
  ${condensedSmallBold};
  text-transform: uppercase;
  white-space: nowrap;
`;

export const FlippedTextContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: 0% 0%;
  transform: rotate(-90deg);
`;
export const FlippedTextInner = styled.div`
  position: absolute;
  right: 0;
`;

export const ContentTable = styled.table`
  width: 100%;
  height: 100%;
  border-spacing: 0;
  table-layout: fixed;
`;

export const BroadcastEntryLink = styled(props => <Link {...props} />)`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;
