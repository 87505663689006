/**
 * Magnifying Glass icon
 *
 */

// IMPORTS
import React from "react";

interface MagnifierProps {
  color?: string;
}
const Magnifier = ({color = "#fff"}: MagnifierProps) => (
  <svg
    width="23px"
    height="25px"
    viewBox="0 0 23 25"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-9.000000, -8.000000)" stroke={color}>
        <g transform="translate(10.000000, 9.000000)">
          <ellipse strokeWidth="2" cx="8" cy="8.4" rx="8" ry="8.4" />
          <path d="M13.5466667,15.1411765 L19.6,22" id="Line-5" strokeWidth="3" strokeLinecap="round" />
        </g>
      </g>
    </g>
  </svg>
);

export default Magnifier;
