/**
 * Clock icon
 *
 */

// IMPORTS
import React from "react";

interface ClockProps {
  color?: string;
  strokeWidth?: number;
}
const Clock = ({color = '#fff', strokeWidth = 1.5}: ClockProps) => (
  <svg width="100%" height="100%" viewBox="0 0 18 18" version="1.1"  xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g stroke="none" strokeWidth={strokeWidth} fill="none" fillRule="evenodd">
        <circle stroke={color} cx="9" cy="9" r="6" />
        <path d="M9,9.02124023 L9,4.97875977"  stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9,9 L12,9" stroke={color} strokeLinecap="square" />
    </g>
  </svg>
);

export default Clock;
