import * as React from "react";
import styled from "styled-components";
import ContactCardPerson from "./contactCardPerson";
import ContactFooter from "./contactFooter";
import {px, elementSizes, ContactPersonProps, FullpageHeadline} from "../../models";

const ContactList = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: ${px(elementSizes.productPageMarginTop)};
  box-sizing: border-box;
`;

const ContactRow = styled.div`
  margin: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 36px;
`;
const ContactWrapper = styled.div`
  margin-bottom: 50px;
`;

interface ContactListCompProps {
  contactsDFL: ContactPersonProps[];
  contactsBLI: ContactPersonProps[];
}
const ContactListComp = (props: ContactListCompProps) => {
  const {contactsBLI} = props;

  return (
    <ContactList>
      <FullpageHeadline>Your Local Contacts</FullpageHeadline>

      <ContactRow>
        {contactsBLI.map((contact, i) => {
          return (
            <div key={`contact-${i}`}>
              <ContactWrapper>
                <ContactCardPerson {...contact} />
              </ContactWrapper>
            </div>
          );
        })}
      </ContactRow>

      <ContactFooter />
    </ContactList>
  );
};

export default ContactListComp;
