import * as React from "react";
import styled from "styled-components";
import {Link} from "gatsby";
import {Caret} from "../../../components";
import {
  RundownBlockSizeType,
  rundownBlockSizes,
  px,
  RowDiv,
  colors,
  ColorType,
  condensedMicroRegular,
  condensedMicroBold,
  condensedMacroBold,
  RundownBlockProps,
  RundownSpaceProps,
  rundownElementTypes,
  ColumnDiv,
  UTCTimeStateType,
  utcTimeStates,
  WeekDayType,
  DFL_UTC_OFFSET,
} from "../../../models";
import {displayBroadcastTimeByLocalTimezoneOffset, createBroadcastDate} from "../../../util/util";

const RundownSection = styled(RowDiv)`
  width: 100%;
  align-items: flex-end;
`;

// Rundown Block
interface RundownBlockWrapperProps {
  size: RundownBlockSizeType;
}
const RundownBlockWrapper = styled.div`
  width: ${(props: RundownBlockWrapperProps) =>
    props.size === rundownBlockSizes.l ? px(200) : props.size === rundownBlockSizes.m ? px(140) : px(80)};
  position: relative;
`;
const RundownTimesRow = styled(RowDiv)`
  justify-content: space-between;
  margin-bottom: 4px;
`;
const RundownTime = styled.div`
  ${condensedMicroRegular};
  color: ${colors.black};
`;
interface RundownBlockDivProps {
  color: ColorType;
}
const RundownBlockDiv = styled(RowDiv)`
  ${condensedMicroBold};
  background-color: ${(props: RundownBlockDivProps) => props.color};
  height: 45px;
  padding-left: 10px;
  color: ${colors.white};
  text-transform: uppercase;
`;
// Rundown Block Infobox
const BlockInfobox = styled.div`
  position: absolute;
  width: 100%;
  border-left: 1px solid ${colors.redDFL};
`;
const ProductLink = styled(props => <Link {...props} />)`
  ${condensedMicroBold};
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;
const LinkDescription = styled.div`
  ${condensedMicroRegular};
  margin: 6px 6px 0 6px;
`;
const CaretWrapper = styled.div`
  width: 8px;
  min-width: 8px;
  height: 8px;
  min-height: 8px;
  transform: rotate(-90deg);
  margin: 11px 8px -2px 0;
`;
const LinkLabel = styled.div`
  margin-top: 6px;
  ${condensedMacroBold};
  font-size: 12px;

  &:hover {
    color: ${colors.redDFL};
    text-decoration: underline;
  }
`;

// Rundown Space
const SpaceWrapper = styled(ColumnDiv)`
  justify-content: flex-end;

  ${RundownTime} {
    padding: 0 2px;
    text-align: center;
  }
  flex-grow: 1;
`;
const Space = styled.div`
  background-color: ${colors.grayLight};
  height: 15px;
  min-width: 10px;
`;

interface RundownSectionCompProps {
  feed: [RundownBlockProps | RundownSpaceProps];
  selectedUTCOffset?: UTCTimeStateType;
  selectedWeekDay?: WeekDayType;
  selectedPreMatchStartAndEndTime?: [number, number, number, number];
  selectedLiveMatchFirstHalfStartAndEndTime?: [number, number, number, number];
  selectedLiveMatchSecondHalfStartAndEndTime?: [number, number, number, number];
  selectedPostMatchStartAndEndTime?: [number, number, number, number];
  selectedDigitalHighlightsClipsTime?: [number, number];
  selectedSlowMotionPlayOutsTime?: [number, number];
  selectedPostMatchInterviewsTime?: [number, number];
  localUTCOffset?: number;
}
const RundownSectionComp = (props: RundownSectionCompProps) => {
  const {
    feed,
    selectedUTCOffset,
    localUTCOffset,
    selectedWeekDay,
    selectedPreMatchStartAndEndTime,
    selectedLiveMatchFirstHalfStartAndEndTime,
    selectedLiveMatchSecondHalfStartAndEndTime,
    selectedPostMatchStartAndEndTime,
    selectedDigitalHighlightsClipsTime,
    selectedSlowMotionPlayOutsTime,
    selectedPostMatchInterviewsTime,
  } = props;

  const utcOffsetToUse = selectedUTCOffset === utcTimeStates.localTime ? localUTCOffset : DFL_UTC_OFFSET;

  return (
    <RundownSection>
      {feed.map((product, productIndex) => {
        const {
          color,
          description,
          endTime,
          label,
          link,
          minutes,
          size,
          startTime,
          subLabel,
          timePrefix,
          type,
          leftPosition,
        } = product;
        const isBlockElementType = type === rundownElementTypes.block;

        let matchStartTimeHours: number = 0;
        let matchStartTimeMinutes: number = 0;
        let matchEndTimeHours: number = 0;
        let matchEndTimeMinutes: number = 0;

        if (label === "Pre-Match") {
          matchStartTimeHours = selectedPreMatchStartAndEndTime[0];
          matchStartTimeMinutes = selectedPreMatchStartAndEndTime[1];
          matchEndTimeHours = selectedPreMatchStartAndEndTime[2];
          matchEndTimeMinutes = selectedPreMatchStartAndEndTime[3];
        }

        if (label === "Live Match" && subLabel === "1st half") {
          matchStartTimeHours = selectedLiveMatchFirstHalfStartAndEndTime[0];
          matchStartTimeMinutes = selectedLiveMatchFirstHalfStartAndEndTime[1];
          matchEndTimeHours = selectedLiveMatchFirstHalfStartAndEndTime[2];
          matchEndTimeMinutes = selectedLiveMatchFirstHalfStartAndEndTime[3];
        }

        if (label === "Live Match" && subLabel === "2nd half") {
          matchStartTimeHours = selectedLiveMatchSecondHalfStartAndEndTime[0];
          matchStartTimeMinutes = selectedLiveMatchSecondHalfStartAndEndTime[1];
          matchEndTimeHours = selectedLiveMatchSecondHalfStartAndEndTime[2];
          matchEndTimeMinutes = selectedLiveMatchSecondHalfStartAndEndTime[3];
        }

        if (label === "Post-Match") {
          matchStartTimeHours = selectedPostMatchStartAndEndTime[0];
          matchStartTimeMinutes = selectedPostMatchStartAndEndTime[1];
          matchEndTimeHours = selectedPostMatchStartAndEndTime[2];
          matchEndTimeMinutes = selectedPostMatchStartAndEndTime[3];
        }

        if (link && link.label === "Digital Highlights Clips") {
          matchStartTimeHours = selectedDigitalHighlightsClipsTime[0];
          matchStartTimeMinutes = selectedDigitalHighlightsClipsTime[1];
          matchEndTimeHours = 0;
          matchEndTimeMinutes = 0;
        }

        if (link && link.label === "Slow-Motion Play-Outs") {
          matchStartTimeHours = selectedSlowMotionPlayOutsTime[0];
          matchStartTimeMinutes = selectedSlowMotionPlayOutsTime[1];
          matchEndTimeHours = 0;
          matchEndTimeMinutes = 0;
        }

        if (link && link.label === "Post-Match Interviews") {
          matchStartTimeHours = selectedPostMatchInterviewsTime[0];
          matchStartTimeMinutes = selectedPostMatchInterviewsTime[1];
          matchEndTimeHours = 0;
          matchEndTimeMinutes = 0;
        }

        const getProductStartTime = startTime
          ? displayBroadcastTimeByLocalTimezoneOffset(
              utcOffsetToUse,
              createBroadcastDate(selectedWeekDay, matchStartTimeHours, matchStartTimeMinutes),
              selectedWeekDay,
            )
          : null;

        const getProductEndTime = endTime
          ? displayBroadcastTimeByLocalTimezoneOffset(
              utcOffsetToUse,
              createBroadcastDate(selectedWeekDay, matchEndTimeHours, matchEndTimeMinutes),
              selectedWeekDay,
            )
          : null;

        const startTimeWithOffset = isBlockElementType ? getProductStartTime : null;
        const endTimeWithOffset = isBlockElementType ? getProductEndTime : null;

        const element = isBlockElementType ? (
          <RundownBlockWrapper key={`${productIndex}`} size={size}>
            {(startTime || endTime) && (
              <RundownTimesRow>
                {startTime && <RundownTime>{`${timePrefix ? timePrefix : ""}${startTimeWithOffset}`}</RundownTime>}
                {endTime && <RundownTime>{endTimeWithOffset}</RundownTime>}
              </RundownTimesRow>
            )}

            <RundownBlockDiv color={color}>{label}</RundownBlockDiv>

            <BlockInfobox style={{left: leftPosition || "0"}}>
              {description && <LinkDescription>{description}</LinkDescription>}
              {link && (
                <ProductLink to={`/product/${link.url}`}>
                  <CaretWrapper>
                    <Caret color={colors.redDFL} />
                  </CaretWrapper>
                  <LinkLabel>{link.label}</LinkLabel>
                </ProductLink>
              )}
            </BlockInfobox>
          </RundownBlockWrapper>
        ) : (
          <SpaceWrapper key={`${productIndex}`}>
            <RundownTime>{minutes ? (minutes > 0 ? `+${minutes}` : minutes) : ""}</RundownTime>
            <Space />
          </SpaceWrapper>
        );

        return element;
      })}
    </RundownSection>
  );
};

export default RundownSectionComp;
