import * as React from "react";
import styled from "styled-components";
import {colors} from "../../models";

const ProgressTag = styled.progress`
  position: relative;
  background-color: transparent;

  &[value] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 40px;
    border: 0;
    ::-webkit-progress-bar {
      background-color: transparent;
      border-radius: 0;
    }
    ::-webkit-progress-value {
      border-radius: 0;
    }
  }
`;

const ProgressTagContainer = styled.div<{filledColor: string}>`
  position: relative;
  display: flex;
  align-items: center;
  height: 160px;
  transform: rotate(-90deg);
  &:before {
    position: absolute;
    z-index: 0;
    left: 50%;
    content: "";
    display: inline-block;
    width: 1px;
    height: 80%;
    background-color: ${colors.grayLight};
    transform: translateX(-50%) rotate(-90deg);
  }
  ${ProgressTag} {
    &[value]::-webkit-progress-value {
      border-right: 4px solid ${colors.white};
      background-color: ${p => (p.filledColor ? p.filledColor : colors.grayLight)};
    }

    /* NOTE: must put here and not add after a a comma after -webkit */
    &[value]::-moz-progress-bar {
      border-right: 4px solid ${colors.white};
      background-color: ${p => (p.filledColor ? p.filledColor : colors.grayLight)};
    }

    ::-ms-fill {
      border-right: 4px solid ${colors.white};
      transform: translateX(-20px);
      background-color: ${p => (p.filledColor ? p.filledColor : colors.grayLight)};
    }
  }
`;
interface ProgressTagCompProps {
  value: number;
  max: number;
  filledColor: string;
}

const ProgressTagComp = (props: ProgressTagCompProps) => {
  const {value, max, filledColor} = props;
  return (
    <ProgressTagContainer filledColor={filledColor}>
      <ProgressTag value={value} max={max} />
    </ProgressTagContainer>
  );
};

export default ProgressTagComp;
