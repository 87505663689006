import * as React from "react";
import {Link} from "gatsby";
import styled from "styled-components";
import Slider from "react-slick";
import {Clock} from "../../../../components";
import {
  ScheduleCategoryContentProps,
  px,
  breakpoints,
  weekDayMap,
  ColorType,
  ColumnDiv,
  colors,
  condensedSmallBold,
  CenteredDiv,
  RowDiv,
  ScheduleAroundSeasonContentProps,
  ScheduleViewType,
  scheduleViews,
  seasonMap,
  condensedMicroBold,
} from "../../../../models";
import {leftPad} from "../../../../util/util";

const ScheduleMobile = styled.div`
  display: block;
  margin: 0 auto 80px;
  width: 100%;
  max-width: 600px;

  /* desktop */
  @media (min-width: ${px(breakpoints.desktopHd)}) {
    display: none;
  }
`;

// Toggle Buttons
interface ToggleButtonProps {
  active: boolean;
}
const ToggleButton = styled.button`
  ${condensedMicroBold};
  color: ${(props: ToggleButtonProps) => (props.active ? colors.grayMediumDark : colors.black)};
  border: ${(props: ToggleButtonProps) => (props.active ? `1px solid ${colors.grayMediumDark}` : "none")};
  border-radius: 3px;
  padding: 2px 6px 8px;
  text-transform: uppercase;
  background-color: ${colors.white};
  cursor: pointer;
`;
const ToggleButtonWrapper = styled(RowDiv)`
  justify-content: center;
  margin-bottom: 15px;

  ${ToggleButton} {
    &:nth-child(1) {
      margin-right: 10px;
    }
  }
`;

// Schedule Items
interface CategoryRowProps {
  color: ColorType;
}
const CategoriesWrapper = styled(ColumnDiv)`
  height: 550px;
  margin-bottom: 50px;


  @media (min-width: ${px(380)}) {
    height: 450px;
  }
`;
interface CategoryRowProps {
  hasMargin?: boolean;
}
const CategoryRow = styled.div`
  flex: 1;
  background-color: ${(props: CategoryRowProps) => props.color};
  padding: 3px 20px 8px;
  margin: ${(props: CategoryRowProps) => (props.hasMargin ? "1px 0" : 0)};
`;
interface CategoryLabelProps {
  color?: ColorType;
}
const CategoryLabel = styled.div`
  ${condensedSmallBold};
  font-size: 9px;
  text-transform: uppercase;
  color: ${(props: CategoryLabelProps) => (props.color ? props.color : colors.white)};
  opacity: 0.6;
`;
const BroadcastEntryLink = styled(props => <Link {...props} />)``;

const DayLabel = styled(CenteredDiv)`
  ${condensedSmallBold};
  font-size: 13px;
  background-color: ${colors.black};
  color: white;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 2px;
  padding: 4px;
`;
const BroadcastEntry = styled(RowDiv)`
  justify-content: space-between;
`;
interface BroadcastLabelProps {
  textColor?: ColorType;
}
const BroadcastLabel = styled.div`
  ${condensedSmallBold};
  font-size: 15px;
  padding: 0;
  margin: 0;
  color: ${(props: BroadcastLabelProps) => (props.textColor ? props.textColor : colors.white)};
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2px;

  &:hover {
    text-decoration: underline;
  }
`;
const SubLabel = styled(BroadcastLabel)`
  font-size: 13px;
  margin-left: 8px;

  &:hover {
    text-decoration: none;
  }
`;
const TimeLabel = styled(SubLabel)`
  margin-left: 0;

  &:hover {
    text-decoration: none;
  }
`;
const ClockWrapper = styled.div`
  width: 15px;
  height: 15px;
  margin-right: 4px;
`;
const RowRight = styled(RowDiv)`
  width: 140px;
  justify-content: space-between;

  @media (min-width: ${px(380)}) {
    width: 160px;
  }
`;

// Carousel Dots
const CustomDot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${colors.grayMedium};
`;
const CustomDotsList = styled.ul`
  padding: 0;
  margin: 0;

  & li.slick-active {
    ${CustomDot} {
      background-color: ${colors.white};
      border: 1px solid ${colors.grayMedium};
    }
  }
`;

interface ScheduleMobileCompProps {
  matchdaysCategories: ScheduleCategoryContentProps[];
  seasonCategories: ScheduleAroundSeasonContentProps[];
  selectedView: ScheduleViewType;
  updateSelectedView: (newView: ScheduleViewType) => void;
}
const ScheduleMobileComp = (props: ScheduleMobileCompProps) => {
  const {matchdaysCategories, seasonCategories, selectedView, updateSelectedView} = props;

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 2,
    appendDots: dots => (
      <div
        style={{
          height: 50,
          width: "100%",
        }}
      >
        <CustomDotsList style={{margin: "0px"}}> {dots} </CustomDotsList>
      </div>
    ),
    customPaging: (_i: number) => <CustomDot />,
  };

  return (
    <ScheduleMobile>
      <ToggleButtonWrapper>
        <ToggleButton
          active={selectedView === scheduleViews.aroundMatchdays}
          onClick={() => updateSelectedView(scheduleViews.aroundMatchdays)}
        >
          Around Matchdays
        </ToggleButton>
        <ToggleButton
          active={selectedView === scheduleViews.aroundSeason}
          onClick={() => updateSelectedView(scheduleViews.aroundSeason)}
        >
          Around the season
        </ToggleButton>
      </ToggleButtonWrapper>

      {/* Around Matchdays */}
      {selectedView === scheduleViews.aroundMatchdays && (
        <Slider {...settings}>
          {matchdaysCategories[0].days.map((currentDay, dayIndex) => (
            <div key={dayIndex}>
              <DayLabel>{weekDayMap.get(currentDay.weekDay).fullName}</DayLabel>

              <CategoriesWrapper>
                {matchdaysCategories.map((category, categoryIndex) => {
                  const dayEntry = category.days.find(day => day.weekDay === currentDay.weekDay);

                  return (
                    <CategoryRow key={categoryIndex} color={category.backgroundColor}>
                      <CategoryLabel color={category.categoryName === "Digital Content" ? colors.redDFL : null}>
                        {category.categoryName}
                      </CategoryLabel>

                      {dayEntry.broadcastGroups.map((group, groupIndex) => (
                        <BroadcastEntry key={groupIndex}>
                          <BroadcastEntryLink to={`/product/${group.productLink}`}>
                            <RowDiv>
                              <BroadcastLabel textColor={category.textColor}>{group.label}</BroadcastLabel>
                              <SubLabel>{group.subLabel}</SubLabel>
                            </RowDiv>
                          </BroadcastEntryLink>

                          <RowRight>
                            {group.broadcasts.map((broadcast, broadcastIndex) => {
                              return (
                                broadcast.time && (
                                  <SubLabel key={broadcastIndex}>
                                    <RowDiv>
                                      <ClockWrapper>
                                        <Clock strokeWidth={1} color={category.textColor} />
                                      </ClockWrapper>
                                      <TimeLabel textColor={category.textColor}>{`${broadcast.time.hours}:${leftPad(
                                        broadcast.time.minutes,
                                      )}`}</TimeLabel>
                                    </RowDiv>
                                  </SubLabel>
                                )
                              );
                            })}
                          </RowRight>
                        </BroadcastEntry>
                      ))}
                    </CategoryRow>
                  );
                })}
              </CategoriesWrapper>
            </div>
          ))}
        </Slider>
      )}

      {/* Around Season */}
      {selectedView === scheduleViews.aroundSeason && (
        <Slider {...settings}>
          {seasonCategories[0].seasons.map((currentSeason, dayIndex) => (
            <div key={dayIndex}>
              <DayLabel>{seasonMap.get(currentSeason.season).name}</DayLabel>

              <CategoriesWrapper>
                {seasonCategories.map((seasonCategory, seasonCategoryIndex) => {
                  const seasonEntry = seasonCategory.seasons.find(season => season.season === currentSeason.season);

                  return (
                    <CategoryRow hasMargin={true} key={seasonCategoryIndex} color={seasonCategory.backgroundColor}>
                      <CategoryLabel color={seasonCategory.textColor}>{seasonCategory.categoryName}</CategoryLabel>

                      {seasonEntry.seasonalProducts.map((seasonalProduct, groupIndex) => (
                        <BroadcastEntry key={groupIndex}>
                          <RowDiv>
                            <BroadcastLabel textColor={seasonCategory.textColor}>
                              {seasonalProduct.label}
                            </BroadcastLabel>
                          </RowDiv>
                        </BroadcastEntry>
                      ))}
                    </CategoryRow>
                  );
                })}
              </CategoriesWrapper>
            </div>
          ))}
        </Slider>
      )}
    </ScheduleMobile>
  );
};

export default ScheduleMobileComp;
