import React from "react";
import styled from "styled-components";
import {RegularCell, CategoryAggregationCell, CategoryCell} from "../styled";
import {px, scheduleSizes, WeekDayType, colors, scheduleWeek, weekDayMap, condensedSmallBold} from "../../../../models";

const DayLabelRow = styled.tr`
  height: ${px(scheduleSizes.dayLabelRow)};
`;

interface LabelCellProps {
  centered: boolean;
}
const LabelCell = styled(RegularCell)`
${condensedSmallBold};
  font-size: 13px;
  color: white;
  text-transform: uppercase;
  text-align: ${(props: LabelCellProps) => (props.centered ? "center" : "left")};
  padding-left: ${(props: LabelCellProps) => (props.centered ? 0 : px(15))};
  vertical-align: middle;
`;

interface DayLabelRowCompProps {
  activeMatchDays: [WeekDayType, WeekDayType];
}
const DayLabelRowComp = (props: DayLabelRowCompProps) => {
  const {activeMatchDays} = props;

  return (
    <DayLabelRow>
      <CategoryAggregationCell />
      <CategoryCell color={colors.transparent} borderColor={colors.transparent} />

      {scheduleWeek.map((day, dayIndex) => {
        const isDayCollapsed = !activeMatchDays.includes(dayIndex);
        return (
          <LabelCell
            centered={isDayCollapsed}
            key={dayIndex}
            collapsed={isDayCollapsed}
            backgroundColor={colors.black} 
          >
            {isDayCollapsed ? weekDayMap.get(day).shortName : weekDayMap.get(day).fullName}
          </LabelCell>
        );
      })}
    </DayLabelRow>
  );
};

export default DayLabelRowComp;
