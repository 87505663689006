import * as React from "react";
import styled from "styled-components";
import {Dispatch} from "redux";
import {connect} from "react-redux";
import AroundMatchdays from "./aroundMatchdays/aroundMatchdays";
import AroundSeason from "./aroundSeason/aroundSeason";
import ScheduleMobile from "./scheduleMobile/scheduleMobile";
import {GenericSelect, Caret, Download} from "../../../components";
import {mediumBold, CenteredDiv, RowDiv, condensedMicroRegular} from "../../../models/style-constants";
import {
  px,
  scheduleSizes,
  elementSizes,
  WeekDayType,
  ScheduleContentProps,
  UTCTimeStateType,
  utcTimeStates,
  utcTimeStateMap,
  condensedMicroBold,
  colors,
  timings,
  ScheduleViewType,
  scheduleViews,
  breakpoints,
} from "../../../models";
import {AppState} from "../../../store";
import {
  updateActiveMatchdaysAction,
  updateUTCTimeOffsetAction,
  updateSelectedViewAction,
} from "../../../store/schedule/actions";

// COMPONENT PROPERTIES
interface PropsFromState {
  activeMatchDays: [WeekDayType, WeekDayType];
  selectedTimeOffset: UTCTimeStateType;
  localUTCOffsetText: string;
  selectedView: ScheduleViewType;
}
const mapStateToProps = (state: AppState): PropsFromState => ({
  activeMatchDays: state.schedule.activeMatchdays,
  selectedTimeOffset: state.schedule.selectedUTCTimeOffset,
  localUTCOffsetText: state.system.localUTCOffsetText,
  selectedView: state.schedule.selectedView,
});

interface PropsFromDispatch {
  updateActiveMatchDays: (region: [WeekDayType, WeekDayType]) => void;
  updateSelectedTimeOffset: (newOffsetState: UTCTimeStateType) => void;
  updateSelectedView: (newView: ScheduleViewType) => void;
}
const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => {
  return {
    updateActiveMatchDays: (newMatchDays: [WeekDayType, WeekDayType]) => {
      return dispatch(updateActiveMatchdaysAction(newMatchDays));
    },
    updateSelectedTimeOffset: (newOffsetState: UTCTimeStateType) => {
      return dispatch(updateUTCTimeOffsetAction(newOffsetState));
    },
    updateSelectedView: (newView: ScheduleViewType) => {
      return dispatch(updateSelectedViewAction(newView));
    },
  };
};

export interface ScheduleCompProps extends PropsFromState, PropsFromDispatch, ScheduleContentProps {
  title: string;
  pdfUrl: string;
}

// STYLES
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-top: ${px(elementSizes.navigationHeaderHeight)};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;
const HeaderRow = styled.div`
  ${mediumBold};
  font-size: 18px;
  letter-spacing: 0.24em;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  flex-grow: 0;
  margin-bottom: 20px;

  /* desktop */
  @media (min-width: ${px(breakpoints.desktopHd)}) {
    font-size: 25px;
    margin-bottom: 0;
  }
`;
const ScheduleWrapper = styled.div`
  width: 100%;
  display: none;
  flex-grow: 1;
  flex-direction: row;
  padding-bottom: ${px(elementSizes.navigationHeaderHeight)};

  /* desktop */
  @media (min-width: ${px(breakpoints.desktopHd)}) {
    display: flex;
  }
`;

// SCHEDULE

// BUTTONS (left + right)
interface ButtonColumnProps {
  direction: "left" | "right";
}
const ButtonColumn = styled.div`
  width: ${px(scheduleSizes.buttonColumn)};
  padding-top: ${px(scheduleSizes.navigationRow + scheduleSizes.dayLabelRow)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin: 0 ${(props: ButtonColumnProps) => (props.direction === "right" ? px(50) : 0)} 0
    ${(props: ButtonColumnProps) => (props.direction === "left" ? px(50) : 0)};
`;

interface CaretWrapperProps {
  direction: "left" | "right";
}
const CaretWrapper = styled.div`
  width: 20px;
  height: 20px;
  transition: width ${timings.hurried}ms, height ${timings.instant}ms;
  transform: translate(${(props: CaretWrapperProps) => (props.direction === "left" ? px(-2) : px(2))})
    rotate(${(props: CaretWrapperProps) => (props.direction === "left" ? 90 : -90)}deg);
`;

const Button = styled.div`
  width: ${px(scheduleSizes.buttonSize)};
  height: ${px(scheduleSizes.buttonSize)};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.redDFL30};
  transition: border-color ${timings.moderate}ms;
  cursor: pointer;

  &:hover {
    border-color: ${colors.redDFL};

    ${CaretWrapper} {
      width: 24px;
      height: 24px;
    }
  }
`;

const ScheduleTableWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const Schedule = styled.div`
  flex-grow: 1;
  height: 100%;
`;
const NavigationRow = styled.div`
  padding-left: ${px(
    scheduleSizes.categoryAggregationLabelColumn +
      scheduleSizes.categoryLabelColumn +
      scheduleSizes.categoryLabelPadding * 2 +
      2,
  )};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 6px;
`;
const NavigationTimeSelect = styled.div`
  width: 180px;
  height: 100%;
`;
const NavigationViewToggle = styled.div`
  display: flex;
  flex-direction: row;
`;

// Download
const DownloadRow = styled(RowDiv)`
  justify-content: flex-end;
  margin-top: 4px;
`;

const DownloadText = styled.div`
  ${condensedMicroRegular};
  margin-right: 6px;
`;

const DownloadLink = styled.a`
  display: flex;
  flex-direction: row;
  cursor: pointer;

  &:hover {
    ${DownloadText} {
      color: ${colors.redDFL};
      text-decoration: underline;
    }
  }
`;

const DownloadIconWrapper = styled(CenteredDiv)`
  width: 16px;
  height: 16px;
`;

interface NavigationViewToggleElementProps {
  active: boolean;
}
const NavigationViewToggleElement = styled.div`
  ${condensedMicroBold};
  font-size: 13px;
  color: ${(props: NavigationViewToggleElementProps) => (props.active ? colors.grayMediumDark : colors.black)};
  border-bottom: ${(props: NavigationViewToggleElementProps) =>
    props.active ? `2px solid ${colors.grayMediumDark}` : "none"};
  margin-left: 20px;
  text-transform: uppercase;
  padding-bottom: 2px;
  cursor: pointer;
`;

interface OptionValueLabel {
  value: UTCTimeStateType;
  label: string;
}
const ScheduleComp = (props: ScheduleCompProps) => {
  const {
    activeMatchDays,
    aroundMatchdaysCategories,
    aroundSeasonCategories,
    updateSelectedTimeOffset,
    selectedTimeOffset,
    localUTCOffsetText,
    selectedView,
    updateSelectedView,
    pdfUrl,
    title,
  } = props;

  const options: OptionValueLabel[] = [
    {value: utcTimeStates.germanTime, label: utcTimeStateMap.get(utcTimeStates.germanTime)},
    {
      value: utcTimeStates.localTime,
      label: `${utcTimeStateMap.get(utcTimeStates.localTime)} (UTC ${localUTCOffsetText})`,
    },
  ];
  const activeOption: OptionValueLabel = options.find(o => o.value === selectedTimeOffset);

  return (
    <Wrapper>
      <HeaderRow>{title}</HeaderRow>

      {/* Schedule Desktop */}
      <ScheduleWrapper>
        <ButtonColumn direction="left">
          {selectedView === scheduleViews.aroundMatchdays && (
            <Button
              onClick={() => {
                const newMatchDays: [number, number] =
                  activeMatchDays[0] <= 0 ? [5, 6] : [activeMatchDays[0] - 1, activeMatchDays[1] - 1];
                props.updateActiveMatchDays(newMatchDays);
              }}
            >
              <CaretWrapper direction="left">
                <Caret color={colors.redDFL} strokeWidth={0.75} />
              </CaretWrapper>
            </Button>
          )}
        </ButtonColumn>

        <ScheduleTableWrapper>
          <Schedule>
            <NavigationRow>
              <NavigationTimeSelect>
                <GenericSelect
                  options={options}
                  onChange={(value: UTCTimeStateType) => updateSelectedTimeOffset(value)}
                  value={activeOption}
                  title="Time"
                />
              </NavigationTimeSelect>

              <NavigationViewToggle>
                <NavigationViewToggleElement
                  active={selectedView === scheduleViews.aroundMatchdays}
                  onClick={() => updateSelectedView(scheduleViews.aroundMatchdays)}
                >
                  Around Matchdays
                </NavigationViewToggleElement>
                <NavigationViewToggleElement
                  active={selectedView === scheduleViews.aroundSeason}
                  onClick={() => updateSelectedView(scheduleViews.aroundSeason)}
                >
                  Around the season
                </NavigationViewToggleElement>
              </NavigationViewToggle>
            </NavigationRow>

            {selectedView === scheduleViews.aroundMatchdays && (
              <AroundMatchdays categories={aroundMatchdaysCategories} activeMatchDays={activeMatchDays} />
            )}
            {selectedView === scheduleViews.aroundSeason && <AroundSeason categories={aroundSeasonCategories} />}

            {/*
            <DownloadRow>
              <DownloadLink href={pdfUrl} download="Schedule.pdf" target="_blank">
                <DownloadText>Download PDF</DownloadText>
                <DownloadIconWrapper>
                  <Download color={colors.redDFL} />
                </DownloadIconWrapper>
              </DownloadLink>
            </DownloadRow>
            */}
          </Schedule>
        </ScheduleTableWrapper>

        <ButtonColumn direction="right">
          {selectedView === scheduleViews.aroundMatchdays && (
            <Button
              onClick={() => {
                const newMatchDays: [number, number] =
                  activeMatchDays[1] >= 6 ? [0, 1] : [activeMatchDays[0] + 1, activeMatchDays[1] + 1];
                props.updateActiveMatchDays(newMatchDays);
              }}
            >
              <CaretWrapper direction="right">
                <Caret color={colors.redDFL} strokeWidth={0.75} />
              </CaretWrapper>
            </Button>
          )}
        </ButtonColumn>
      </ScheduleWrapper>

      {/* Schedule Mobile */}
      <ScheduleMobile
        selectedView={selectedView}
        updateSelectedView={updateSelectedView}
        matchdaysCategories={aroundMatchdaysCategories}
        seasonCategories={aroundSeasonCategories}
      />
    </Wrapper>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ScheduleComp);
