import * as React from 'react';
import styled from 'styled-components';
import {Link} from "gatsby";
import {Caret} from "../../components";
import {colors, mediumBold} from "../../models";
import {unslugify} from "../../util/util";

const CaretWrapper = styled.div`
  position: absolute;
  top: 4px;
`;
const Cell = styled.div`
  position: relative;
  width: 100%;
`;
const Text = styled.div`
  ${mediumBold};
  text-transform: uppercase;
  width: 100%;
  text-align: center;
`;

const ButtonWrapper = styled(props => <Link {...props} />)`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${colors.grayUltraLight};
`;
const PrevNextButtons = styled.div`
  width: 100%;
  height: 320px;
  display: flex;
  flex-direction: row;
  /* align-items: center; */

  & ${ButtonWrapper}:nth-child(1) {
    margin-right: 4px;

    & ${CaretWrapper} {
      transform: rotate(90deg) scale(4);
      left: 80px;
    }
  }
  & ${ButtonWrapper}:nth-child(2) {
    & ${CaretWrapper} {
      transform: rotate(-90deg) scale(4);
      right: 80px;
    }
  }
`;

interface PrevNextButtonsCompProps {
  previous: string;
  next: string;
}
const PrevNextButtonsComp = (props: PrevNextButtonsCompProps) => {
  const { previous, next } = props;
  return (
    <PrevNextButtons>
      <ButtonWrapper to={`/product/${previous}`}>
        <Cell>
          <CaretWrapper>
            <Caret color="red" />
          </CaretWrapper>
          <Text>{unslugify(previous)}</Text>
        </Cell>
      </ButtonWrapper>
      <ButtonWrapper to={`/product/${next}`}>
        <Cell>
          <CaretWrapper>
            <Caret color="red" />
          </CaretWrapper>
          <Text>{unslugify(next)}</Text>
        </Cell>
      </ButtonWrapper>
    </PrevNextButtons>
  );
};

export default PrevNextButtonsComp;
