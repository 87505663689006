import * as React from "react";
import styled from "styled-components";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import DesktopView from "./DesktopView";
import MobileView from "./MobileView";
//  import NavDropdownView from "./NavDropdownView";  //  TODO: vllt doch genutzt
import {ProductCategory, px, breakpoints, NavigationOverlayStateType} from "../../models";
import {updateNavigationOverlayAction} from "../../store/system/actions";

// COMPONENT PROPERTIES
// interface PropsFromState {}
// const mapStateToProps = (state: AppState): PropsFromState => {
//   return {};
// };

interface PropsFromDispatch {
  updateNavigationOverlay: (newState: NavigationOverlayStateType) => void;
}
const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => {
  return {
    updateNavigationOverlay: (newState: NavigationOverlayStateType) => {
      return dispatch(updateNavigationOverlayAction(newState));
    },
  };
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  /* desktop */
  @media (min-width: ${px(breakpoints.desktopHd)}) {
  }
`;

interface CategoryListCompProps extends PropsFromDispatch {
  live: ProductCategory[];
  digital: ProductCategory[];
  other: ProductCategory[];
  isMobileNav?: boolean;
}
const CategoryListComp = (props: CategoryListCompProps) => {
  return (
    <Wrapper>
      <DesktopView {...props} />
      <MobileView {...props} />
    </Wrapper>
  );
};

export default connect(
  null,
  mapDispatchToProps,
)(CategoryListComp);
// export default CategoryListComp;
