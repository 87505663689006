import * as React from "react";
import styled from "styled-components";
import {CTA, Column} from "../../components";
import {
  px,
  stage,
  DescriptionColumnProps,
  ButtonProps,
  WPHeadlineWithSubheadlineProps,
  mediumBold,
  baseRegular,
  DflRegionType,
  LocalizedVideoProps,
  LocalizedImageProps,
  breakpoints,
} from "../../models";
import {slugify, columnSpan, getLocalizedVideo, getLocalizedImage} from "../../util/util";
import VideoPlayer from "../videoPlayer/videoPlayer";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const DescriptionColumns = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  /* tablet */
  @media (min-width: ${px(breakpoints.tablet)}) {
    width: ${px(stage.innerWidthTablet)};
  }

  /* desktop */
  @media (min-width: ${px(breakpoints.desktopHd)}) {
    width: ${px(stage.outerWidth)};
    flex-direction: row;
  }
`;

const CTARow = styled.div`
  width: 100%;
  margin: 50px auto 0;
  display: flex;
  flex-direction: row;
  justify-content: center;

  /* tablet */
  @media (min-width: ${px(breakpoints.tablet)}) {
    width: ${px(stage.innerWidthTablet)};
  }

  /* desktop */
  @media (min-width: ${px(breakpoints.desktopHd)}) {
    width: ${px(stage.outerWidth)};
  }
`;
const CTAWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 0 22px;
  margin-top: -36px;

  /* desktop */
  @media (min-width: ${px(breakpoints.desktopHd)}) {
    width: ${px(stage.columnWidth * 4 + stage.columnGap * 3)};
    margin-top: 0;
  }
`;

const HeadlineWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Headline = styled.div`
  ${mediumBold};
  margin-bottom: 16px;
  text-transform: uppercase;
  letter-spacing: 0.2em;
`;

const Subheadline = styled.div`
  ${baseRegular};
  font-size: 20px;
  margin-bottom: 78px;
`;

const Anchor = styled.div`
  position: absolute;
  top: -100px;
`;

const LocalizedContentWrapper = styled.div`
  max-width: ${px(stage.outerWidth)};
  margin: auto;
`;

const ContentWrapper = styled.div`
  max-width: ${px(columnSpan(10, stage.columnWidth, stage.columnGap))};
  margin-bottom: 60px;
`;
const LocalizedImage = styled.img`
  width: 100%;
`;

interface DescriptionColumnsCompProps {
  columns: DescriptionColumnProps[];
  cta?: ButtonProps;
  headline?: WPHeadlineWithSubheadlineProps;
  isLocalized: boolean;
  isImage?: boolean;
  isVideo?: boolean;
  localizedVideos?: LocalizedVideoProps;
  localizedImage?: LocalizedImageProps;
  selectedRegion: DflRegionType;
}
const DescriptionColumnsComp = (props: DescriptionColumnsCompProps) => {
  const {
    columns,
    cta,
    headline,
    isLocalized,
    isImage,
    isVideo,
    localizedImage,
    localizedVideos,
    selectedRegion,
  } = props;
  const videoId = isLocalized && isVideo && getLocalizedVideo(localizedVideos, selectedRegion);
  const imageUrl = isLocalized && isImage && getLocalizedImage(localizedImage, selectedRegion);
  const useButton = cta && (cta.label && cta.label.length > 0) && (cta.link && cta.link.length > 0);

  return (
    <Wrapper>
      {headline && (
        <HeadlineWrapper>
          <Headline>{headline.headline}</Headline>
          <Subheadline>{headline.subheadline}</Subheadline>
        </HeadlineWrapper>
      )}
      {isLocalized && (
        <LocalizedContentWrapper>
          <ContentWrapper>
            {isImage && <LocalizedImage src={imageUrl} />}
            {isVideo && <VideoPlayer playerId={videoId} />}
          </ContentWrapper>
        </LocalizedContentWrapper>
      )}
      <DescriptionColumns>
        {columns.map((column, i) => {
          const cleanedTitle = column.title.trim();
          return (
            <div key={`col-${i}`} style={{position: "relative"}}>
              <Anchor id={slugify(cleanedTitle)} />
              <Column title={column.title} descriptions={column.descriptions} />
            </div>
          );
        })}
      </DescriptionColumns>

      {useButton && (
        <CTARow>
          <CTAWrapper>
            <CTA {...cta} />
          </CTAWrapper>
        </CTARow>
      )}
    </Wrapper>
  );
};

export default DescriptionColumnsComp;
