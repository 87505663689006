import * as React from 'react';
import MatchdayCategoryRow from "./matchdayCategoryRow";
import DayLabelRow from "./dayLabelRow";
import styled from 'styled-components';
import {condensedSmallRegular, condensedSmallBold, ScheduleCategoryContentProps} from "../../../../models";

const ContentTable = styled.table`
  width: 100%;
  height: 100%;
  border-spacing: 0;
  table-layout: fixed;
`;
const VerticalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
const VerticalRegular = styled.div`
  ${condensedSmallRegular};
  text-transform: uppercase;
`;
const VerticalBold = styled.div`
  ${condensedSmallBold};
  text-transform: uppercase;
  white-space: nowrap;
`;

interface AroundMatchdaysProps {
  categories: ScheduleCategoryContentProps[];
  activeMatchDays: [number, number];
}
const aroundMatchdaysComp = (props: AroundMatchdaysProps) => {
  const {categories, activeMatchDays} = props;
  return (
    <ContentTable>
      <tbody>
        {/* day labels */}
        <DayLabelRow activeMatchDays={activeMatchDays} />

        {/* bundesliga 2 matches row */}
        <MatchdayCategoryRow
          isSpan={true}
          span={4}
          spanContent={
            <VerticalWrapper>
              <VerticalRegular>Bundesliga</VerticalRegular>
              <VerticalBold>Matchday</VerticalBold>
            </VerticalWrapper>
          }
          content={categories[0]}
          activeMatchDays={activeMatchDays}
        />

        {/* bundesliga matches row */}
        <MatchdayCategoryRow
          isSpan={false}
          content={categories[1]}
          activeMatchDays={activeMatchDays}
        />

        {/* match highlights row */}
        <MatchdayCategoryRow isSpan={false} content={categories[2]} activeMatchDays={activeMatchDays} />

        {/* match interviews row */}
        <MatchdayCategoryRow isSpan={false} content={categories[3]} activeMatchDays={activeMatchDays} />

        {/* digital content row */}
        <MatchdayCategoryRow
          isSpan={true}
          span={4}
          spanContent={
            <VerticalWrapper>
              <VerticalRegular>Bundesliga</VerticalRegular>
              <VerticalBold>Storytelling & Promotion</VerticalBold>
            </VerticalWrapper>
          }
          content={categories[4]}
          activeMatchDays={activeMatchDays}
        />

        {/* weekly content row */}
        <MatchdayCategoryRow isSpan={false} content={categories[5]} activeMatchDays={activeMatchDays} />

        {/* promo & trailer row */}
        <MatchdayCategoryRow isSpan={false} content={categories[6]} activeMatchDays={activeMatchDays} />
      </tbody>
    </ContentTable>
  );
};

export default aroundMatchdaysComp;
