import {createGlobalStyle} from "styled-components";
import {fonts} from "../models";

import "../../static/fonts.css";

const GlobalStyle = createGlobalStyle`
body {
  font-family: ${fonts.headerFont};
  padding: 0;
  margin: 0;

  /* OpenType features: kerning, ligatures & contextual alternates */
  font-kerning: normal; /* CSS3 */
  -webkit-font-feature-settings: "kern" 1, "liga" 1, "calt" 1;
  -moz-font-feature-settings: "kern=1", "liga=1", "calt=1";	/* Firefox 4.0 to 14.0 */
  -moz-font-feature-settings: "kern" 1, "liga" 1, "calt" 1;	/* Firefox 15.0 onwards */
  font-feature-settings: "kern" 1, "liga" 1, "calt" 1;

  /* Make rendering look more consistent across platforms */
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale;
}
a {
  text-decoration: none;
}
ul {
  margin: 0 auto;
  list-style-type: none;
}

body.overflow-y-hidden {
  overflow-y: hidden;
}
`;

export default GlobalStyle;
