import * as React from 'react';
import styled from 'styled-components';
import {ContactMainProps, RowDiv, microBold, microRegular, colors} from "../../../models";
import {trimWebsiteUrl} from "../../../util/util";

const MobileOverlayMainContact = styled(RowDiv)`
  background-color: ${colors.white};
  margin-top: 1px;
  padding: 12px 24px;
  align-items: flex-start;
`;
const LogoWrapper = styled.div`
  width: 50px;
  margin-right: 12px;
  margin-top: 4px;
`;
const Logo = styled.img`
  width: 100%;
`;
const Title = styled.div`
  ${microBold};
  margin-bottom: 8px;
`;
const Text = styled.div`
  ${microRegular};
  margin-bottom: 2px;
`;
const TextLink = styled.a`
  ${microRegular};
  color: ${colors.redDFL};
  display: block;
  margin-bottom: 2px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

interface MobileOverlayMainContactCompProps extends ContactMainProps {
  imageUrl: string;
}
const MobileOverlayMainContactComp = (props: MobileOverlayMainContactCompProps) => {
  const { imageUrl, name, email, phone, website } = props;
  return (
    <MobileOverlayMainContact>
      <LogoWrapper>
        <Logo src={imageUrl} />
      </LogoWrapper>
      <div>
        <Title>{name}</Title>
        <Text>{phone}</Text>
        <TextLink>{email}</TextLink>
        <TextLink>{trimWebsiteUrl(website)}</TextLink>
      </div>
    </MobileOverlayMainContact>
  );
};

export default MobileOverlayMainContactComp;
