import {
  UPDATE_ACTIVE_MATCHDAYS,
  UPDATE_ACTIVE_SYNERGIES,
  UPDATE_HOVERED_DAY,
  UPDATE_UTC_TIME_OFFSET,
  UPDATE_SELECTED_VIEW,
  ScheduleActionTypes,
} from "./types";
import {BroadcastProductType, UTCTimeStateType, ScheduleViewType} from "../../models";

export const updateActiveMatchdaysAction = (newMatchdays: [number, number]): ScheduleActionTypes => {
  return {
    type: UPDATE_ACTIVE_MATCHDAYS,
    payload: newMatchdays,
  };
};

export const updateActiveSynergiesAction = (newSynergies: BroadcastProductType[]): ScheduleActionTypes => {
  return {
    type: UPDATE_ACTIVE_SYNERGIES,
    payload: newSynergies,
  };
};

export const updateHoveredDayAction = (newDayIndex: number): ScheduleActionTypes => {
  return {
    type: UPDATE_HOVERED_DAY,
    payload: newDayIndex,
  };
};

export const updateUTCTimeOffsetAction = (newTimeOffset: UTCTimeStateType): ScheduleActionTypes => {
  return {
    type: UPDATE_UTC_TIME_OFFSET,
    payload: newTimeOffset,
  };
};

export const updateSelectedViewAction = (newView: ScheduleViewType): ScheduleActionTypes => {
  return {
    type: UPDATE_SELECTED_VIEW,
    payload: newView,
  };
};
