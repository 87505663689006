import * as React from "react";
import styled from "styled-components";
import {Link} from "gatsby";
import {Dispatch} from "redux";
import {connect} from "react-redux";

import RundownSection from "./rundownSection";
import {Caret, CustomSelect, NativeSelect} from "../../../components";
import {
  ColumnDiv,
  UTCTimeStateType,
  utcTimeStates,
  utcTimeStateMap,
  px,
  stage,
  RundownBlockProps,
  RundownSpaceProps,
  RowDiv,
  condensedMicroBold,
  componentBreakpoints,
  colors,
  CenteredDiv,
  FullpageHeadline,
  FullpageSubheadline,
  breakpoints,
  fonts,
  fontSizesPx,
  RundownSelectMenuValues,
  weekDays,
  WeekDayType,
  rundownTimeMap,
} from "../../../models";
import {AppState} from "../../../store";
import {updateUTCTimeOffsetAction} from "../../../store/schedule/actions";
import {
  updateSelectedDateOptionAction,
  updateSelectedWeekDayAction,
  updateSelectedPreMatchStartAndEndTimeAction,
  updateSelectedLiveMatchFirstHalfStartAndEndTimeAction,
  updateSelectedLiveMatchSecondHalfStartAndEndTimeAction,
  updateSelectedPostMatchStartAndEndTimeAction,
  updateSelectedDigitalHighlightsClipsTimeAction,
  updateSelectedSlowMotionPlayOutsTimeAction,
  updateSelectedPostMatchInterviewsTimeAction,
} from "../../../store/rundown/actions";
import {rundownDatesSelectOptionsDesktop, rundownDatesSelectOptionsNative} from "../../../util/content";

// COMPONENT PROPERTIES
interface PropsFromState {
  selectedTimeOffset: UTCTimeStateType;
  selectedDateOption: any;
  localUTCOffset: number;
  localUTCOffsetText: string;
  selectedWeekDay: WeekDayType;
  selectedPreMatchStartAndEndTime: [number, number, number, number];
  selectedLiveMatchFirstHalfStartAndEndTime: [number, number, number, number];
  selectedLiveMatchSecondHalfStartAndEndTime: [number, number, number, number];
  selectedPostMatchStartAndEndTime: [number, number, number, number];
  selectedDigitalHighlightsClipsTime: [number, number];
  selectedSlowMotionPlayOutsTime: [number, number];
  selectedPostMatchInterviewsTime: [number, number];
  screenWidth: number;
}
const mapStateToProps = (state: AppState): PropsFromState => ({
  selectedTimeOffset: state.schedule.selectedUTCTimeOffset,
  localUTCOffset: state.system.utcOffset,
  localUTCOffsetText: state.system.localUTCOffsetText,
  selectedDateOption: state.rundown.selectedDateOption,
  selectedWeekDay: state.rundown.selectedWeekDay,
  selectedPreMatchStartAndEndTime: state.rundown.selectedPreMatchStartAndEndTime,
  selectedLiveMatchFirstHalfStartAndEndTime: state.rundown.selectedLiveMatchFirstHalfStartAndEndTime,
  selectedLiveMatchSecondHalfStartAndEndTime: state.rundown.selectedLiveMatchSecondHalfStartAndEndTime,
  selectedPostMatchStartAndEndTime: state.rundown.selectedPostMatchStartAndEndTime,
  selectedDigitalHighlightsClipsTime: state.rundown.selectedDigitalHighlightsClipsTime,
  selectedSlowMotionPlayOutsTime: state.rundown.selectedSlowMotionPlayOutsTime,
  selectedPostMatchInterviewsTime: state.rundown.selectedPostMatchInterviewsTime,
  screenWidth: state.system.screenWidth,
});

interface PropsFromDispatch {
  updateSelectedTimeOffset: (newOffsetState: UTCTimeStateType) => void;
  updateSelectedDateOption: (newDateState: string) => void;
  updateSelectedWeekDay: (newWeekDayState: any) => void;
  updateSelectedPreMatchStartAndEndTime: (
    newSelectedPreMatchStartAndEndTimeState: [number, number, number, number],
  ) => void;
  updateSelectedLiveMatchFirstHalfStartAndEndTime: (
    newSelectedLiveMatchFirstHalfStartAndEndTimesState: [number, number, number, number],
  ) => void;
  updateSelectedLiveMatchSecondHalfStartAndEndTime: (
    newSelectedLiveMatchSecondHalfStartAndEndTimesState: [number, number, number, number],
  ) => void;
  updateSelectedPostMatchStartAndEndTime: (
    newSelectedPostMatchStartAndEndTimeState: [number, number, number, number],
  ) => void;
  updateSelectedDigitalHighlightsClipsTime: (newSelectedDigitalHighlightsClipsTimeState: [number, number]) => void;
  updateSelectedSlowMotionPlayOutsTime: (newSelectedDSlowMotionPlayOutsTimeState: [number, number]) => void;
  updateSelectedPostMatchInterviewsTime: (newSelectedPostMatchInterviewsTimeState: [number, number]) => void;
}
const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => {
  return {
    updateSelectedTimeOffset: (newOffsetState: UTCTimeStateType) => {
      return dispatch(updateUTCTimeOffsetAction(newOffsetState));
    },
    updateSelectedDateOption: (newDateState: string) => {
      return dispatch(updateSelectedDateOptionAction(newDateState));
    },
    updateSelectedWeekDay: (newWeekDayState: any) => {
      return dispatch(updateSelectedWeekDayAction(newWeekDayState));
    },
    updateSelectedPreMatchStartAndEndTime: (
      newSelectedPreMatchStartAndEndTimeState: [number, number, number, number],
    ) => {
      return dispatch(updateSelectedPreMatchStartAndEndTimeAction(newSelectedPreMatchStartAndEndTimeState));
    },
    updateSelectedLiveMatchFirstHalfStartAndEndTime: (
      newSelectedLiveMatchFirstHalfStartAndEndTimeState: [number, number, number, number],
    ) => {
      return dispatch(
        updateSelectedLiveMatchFirstHalfStartAndEndTimeAction(newSelectedLiveMatchFirstHalfStartAndEndTimeState),
      );
    },
    updateSelectedLiveMatchSecondHalfStartAndEndTime: (
      newSelectedLiveMatchSecondHalfStartAndEndTimeState: [number, number, number, number],
    ) => {
      return dispatch(
        updateSelectedLiveMatchSecondHalfStartAndEndTimeAction(newSelectedLiveMatchSecondHalfStartAndEndTimeState),
      );
    },
    updateSelectedPostMatchStartAndEndTime: (
      newSelectedPostMatchStartAndEndTimeState: [number, number, number, number],
    ) => {
      return dispatch(updateSelectedPostMatchStartAndEndTimeAction(newSelectedPostMatchStartAndEndTimeState));
    },
    updateSelectedDigitalHighlightsClipsTime: (newSelectedDigitalHighlightsClipsTimeState: [number, number]) => {
      return dispatch(updateSelectedDigitalHighlightsClipsTimeAction(newSelectedDigitalHighlightsClipsTimeState));
    },
    updateSelectedSlowMotionPlayOutsTime: (newSelectedSlowMotionPlayOutsTimeState: [number, number]) => {
      return dispatch(updateSelectedSlowMotionPlayOutsTimeAction(newSelectedSlowMotionPlayOutsTimeState));
    },
    updateSelectedPostMatchInterviewsTime: (newSelectedPostMatchInterviewsTimeState: [number, number]) => {
      return dispatch(updateSelectedPostMatchInterviewsTimeAction(newSelectedPostMatchInterviewsTimeState));
    },
  };
};

const RundownWrapper = styled(ColumnDiv)`
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  padding-top: 100px;
`;
const RundownStage = styled.div`
  margin: 0 0 0 20px;

  /* Desktop HD */
  @media (min-width: ${px(breakpoints.desktopHd)}) {
    margin: 0 auto;
  }
`;

const OverflowScroll = styled.div`
  width: 100%;
  overflow-x: scroll;
  position: relative;

  /* Desktop HD */
  @media (min-width: ${px(breakpoints.desktopHd)}) {
    overflow-x: visible;
  }
`;
const RundownGraphicWrapper = styled(ColumnDiv)`
  width: ${px(stage.outerWidth)};
  padding-right: 20px;

  /* Desktop HD */
  @media (min-width: ${px(breakpoints.desktopHd)}) {
    padding-right: 0;
  }

  /* Desktop HD */
  @media (min-width: ${px(breakpoints.desktopHd)}) {
    padding-right: 0;
  }
`;

const SelectDescription = styled.p`
  font-size: ${fontSizesPx.xsSmall};
`;

const SelectMenu = styled(CustomSelect)`
  display: inline-block;
  margin-right: 20px;

  div {
    padding: 0;
  }
  &.select-menu-date {
    font-weight: bold;
  }
`;

const NativeSelectCaret = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 8px;
`;

const NativeSelectContainer = styled.div`
  position: relative;
  display: inline-block;
  min-width: 120px;
  margin-right: 20px;
`;

const SelectWrapper = styled.div`
  margin-bottom: 72px;
`;

// Rundown Graphic
const RundownElements = styled(RowDiv)`
  width: 100%;
  align-items: flex-end;
`;
const WorldFeedElements = styled.div`
  width: 930px;
  flex-shrink: 0;
`;
const AfterWorldFeedElements = styled(RowDiv)`
  width: 930px;
  align-items: flex-end;
`;

const WorldFeedLinkRow = styled(RowDiv)`
  margin-top: 100px;
  width: 100%;
`;
const AdditionalLinkRow = styled(RowDiv)`
  margin-top: 10px;
  width: 100%;
`;
const WorldFeedLinkArea = styled(RowDiv)`
  width: 930px;
`;
const GoalArenaLinkArea = styled(RowDiv)`
  width: 930px;
`;
const CaretWrapper = styled(CenteredDiv)`
  width: 8px;
  height: 8px;
  transform: rotate(-90deg);
  margin: 12px 8px 0px -2px;
`;
const WorldFeedLink = styled(props => <Link {...props} />)`
  ${condensedMicroBold};
  color: ${colors.black};
  margin-right: 4px;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
const WorldFeedLinkLineWrapper = styled(RowDiv)`
  flex-grow: 1;
  border-right: 1px solid ${colors.redDFL};
  box-sizing: border-box;
  height: 9px;
`;
const WorldFeedLinkLine = styled.div`
  width: 100%;
  background-color: ${colors.redDFL};
  height: 1px;
`;

const HeadlineWrapper = styled.div`
  margin-bottom: 80px;
  width: 100%;
`;

const Footnote = styled.p<{isMobile?: boolean}>`
  font-family: ${fonts.condensedFont};
  font-size: ${fontSizesPx.micro};
  color: ${p => (p.isMobile ? colors.black : colors.redDFL)};
`;

interface OptionValueLabel {
  value: UTCTimeStateType;
  label: string;
}

interface RundownCompProps extends PropsFromState, PropsFromDispatch {
  headline: string;
  intro: string;
  worldFeed: [RundownBlockProps | RundownSpaceProps];
  afterWorldFeed: [RundownBlockProps | RundownSpaceProps];
  selectedWeekDay: WeekDayType;
}

class RundownComp extends React.Component<RundownCompProps> {
  constructor(props: RundownCompProps) {
    super(props);
    this.onSelectTimeChange = this.onSelectTimeChange.bind(this);
  }
  onSelectTimeChange(weekday: WeekDayType, label: RundownSelectMenuValues) {
    const {
      updateSelectedWeekDay,
      updateSelectedPreMatchStartAndEndTime,
      updateSelectedLiveMatchFirstHalfStartAndEndTime,
      updateSelectedLiveMatchSecondHalfStartAndEndTime,
      updateSelectedPostMatchStartAndEndTime,
      updateSelectedDigitalHighlightsClipsTime,
      updateSelectedSlowMotionPlayOutsTime,
      updateSelectedPostMatchInterviewsTime,
    } = this.props;
    updateSelectedWeekDay(weekday);
    updateSelectedPreMatchStartAndEndTime([
      rundownTimeMap.get(label).preMatchStart.hours,
      rundownTimeMap.get(label).preMatchStart.minutes,
      rundownTimeMap.get(label).preMatchEnd.hours,
      rundownTimeMap.get(label).preMatchEnd.minutes,
    ]);
    updateSelectedLiveMatchFirstHalfStartAndEndTime([
      rundownTimeMap.get(label).liveMatchFirstHalfStart.hours,
      rundownTimeMap.get(label).liveMatchFirstHalfStart.minutes,
      rundownTimeMap.get(label).liveMatchFirstHalfEnd.hours,
      rundownTimeMap.get(label).liveMatchFirstHalfEnd.minutes,
    ]);
    updateSelectedLiveMatchSecondHalfStartAndEndTime([
      rundownTimeMap.get(label).liveMatchSecondHalfStart.hours,
      rundownTimeMap.get(label).liveMatchSecondHalfStart.minutes,
      rundownTimeMap.get(label).liveMatchSecondHalfEnd.hours,
      rundownTimeMap.get(label).liveMatchSecondHalfEnd.minutes,
    ]);
    updateSelectedPostMatchStartAndEndTime([
      rundownTimeMap.get(label).postMatchStart.hours,
      rundownTimeMap.get(label).postMatchStart.minutes,
      rundownTimeMap.get(label).postMatchEnd.hours,
      rundownTimeMap.get(label).postMatchEnd.minutes,
    ]);
    updateSelectedDigitalHighlightsClipsTime([
      rundownTimeMap.get(label).digitalHightlightClips.hours,
      rundownTimeMap.get(label).digitalHightlightClips.minutes,
    ]);
    updateSelectedSlowMotionPlayOutsTime([
      rundownTimeMap.get(label).slowMotionPlayOuts.hours,
      rundownTimeMap.get(label).slowMotionPlayOuts.minutes,
    ]);
    updateSelectedPostMatchInterviewsTime([
      rundownTimeMap.get(label).postMatchInterviews.hours,
      rundownTimeMap.get(label).postMatchInterviews.minutes,
    ]);
  }
  componentDidUpdate(prevProps) {
    const {selectedDateOption} = this.props;

    if (selectedDateOption !== prevProps.selectedDateOption) {
      const {
        fridayNight,
        saturdayAfternoon,
        saturdayNight,
        sundayLunch,
        sundayAfternoon,
        sundayNight,
        mondayNight,
        tuesdayNight,
        tuesdayLateNight,
        wednesdayNight,
        wednesdayLateNight,
      } = RundownSelectMenuValues;
      switch (selectedDateOption) {
        case fridayNight:
          this.onSelectTimeChange(weekDays.friday, fridayNight);
          break;
        case saturdayAfternoon:
          this.onSelectTimeChange(weekDays.saturday, saturdayAfternoon);
          break;
        case saturdayNight:
          this.onSelectTimeChange(weekDays.saturday, saturdayNight);
          break;
        case sundayLunch:
          this.onSelectTimeChange(weekDays.sunday, sundayLunch);
          break;
        case sundayAfternoon:
          this.onSelectTimeChange(weekDays.sunday, sundayAfternoon);
          break;
        case sundayNight:
          this.onSelectTimeChange(weekDays.sunday, sundayNight);
          break;
        case mondayNight:
          this.onSelectTimeChange(weekDays.monday, mondayNight);
          break;
        case tuesdayNight:
          this.onSelectTimeChange(weekDays.tuesday, tuesdayNight);
          break;
        case tuesdayLateNight:
          this.onSelectTimeChange(weekDays.tuesday, tuesdayLateNight);
          break;
        case wednesdayNight:
          this.onSelectTimeChange(weekDays.wednesday, wednesdayNight);
          break;
        case wednesdayLateNight:
          this.onSelectTimeChange(weekDays.wednesday, wednesdayLateNight);
          break;
        default:
      }
    }
  }
  render() {
    const {
      headline,
      intro,
      worldFeed,
      afterWorldFeed,
      localUTCOffset,
      localUTCOffsetText,
      selectedTimeOffset,
      updateSelectedTimeOffset,
      updateSelectedDateOption,
      selectedDateOption,
      selectedWeekDay,
      selectedPreMatchStartAndEndTime,
      selectedLiveMatchFirstHalfStartAndEndTime,
      selectedLiveMatchSecondHalfStartAndEndTime,
      selectedPostMatchStartAndEndTime,
      selectedDigitalHighlightsClipsTime,
      selectedPostMatchInterviewsTime,
      selectedSlowMotionPlayOutsTime,
      screenWidth,
    } = this.props;

    const localTime = utcTimeStateMap.get(utcTimeStates.localTime);

    const options: OptionValueLabel[] = [
      {
        value: utcTimeStates.germanTime,
        label: utcTimeStateMap.get(utcTimeStates.germanTime),
      },
      {
        value: utcTimeStates.localTime,
        label: `${localTime} (UTC ${localUTCOffsetText})`,
      },
    ];

    const activeOption: OptionValueLabel = options.find(o => o.value === selectedTimeOffset);
    const isMobile = screenWidth < componentBreakpoints.navigation;

    return (
      <RundownWrapper>
        <HeadlineWrapper>
          <FullpageHeadline>{headline}</FullpageHeadline>
          <FullpageSubheadline>{intro}</FullpageSubheadline>
        </HeadlineWrapper>

        <RundownStage>
          <SelectWrapper>
            <SelectDescription>Choose a Kick-Off Match Time</SelectDescription>

            {isMobile ? (
              <NativeSelectContainer>
                <NativeSelect
                  options={options}
                  value={options}
                  selectedOption={activeOption.value}
                  onChange={(value: UTCTimeStateType) => {
                    updateSelectedTimeOffset(value);
                  }}
                />
                <NativeSelectCaret>
                  <Caret color={colors.black} />
                </NativeSelectCaret>
              </NativeSelectContainer>
            ) : (
              <SelectMenu
                className={"select-menu-time"}
                options={options.filter(option => option !== activeOption)}
                selectedOption={activeOption}
                onHandleChange={(value: UTCTimeStateType) => {
                  updateSelectedTimeOffset(value.value);
                }}
                defaultValue={activeOption}
              />
            )}

            {isMobile ? (
              <NativeSelectContainer>
                <NativeSelect
                  options={rundownDatesSelectOptionsNative}
                  value={rundownDatesSelectOptionsNative}
                  selectedOption={selectedDateOption.value}
                  onChange={(date: any) => {
                    updateSelectedDateOption(date);
                  }}
                  isBold
                />
                <NativeSelectCaret>
                  <Caret color={colors.black} />
                </NativeSelectCaret>
              </NativeSelectContainer>
            ) : (
              <SelectMenu
                className={"select-menu-date"}
                options={rundownDatesSelectOptionsDesktop.filter(option => option !== selectedDateOption)}
                selectedOption={selectedDateOption.value}
                onHandleChange={date => {
                  updateSelectedDateOption(date.value);
                }}
                defaultValue={rundownDatesSelectOptionsDesktop[1]}
              />
            )}
          </SelectWrapper>

          <OverflowScroll>
            <RundownGraphicWrapper>
              <RundownElements>
                <WorldFeedElements>
                  <RundownSection
                    feed={worldFeed}
                    selectedUTCOffset={selectedTimeOffset}
                    selectedWeekDay={selectedWeekDay}
                    selectedPreMatchStartAndEndTime={selectedPreMatchStartAndEndTime}
                    selectedLiveMatchFirstHalfStartAndEndTime={selectedLiveMatchFirstHalfStartAndEndTime}
                    selectedLiveMatchSecondHalfStartAndEndTime={selectedLiveMatchSecondHalfStartAndEndTime}
                    selectedPostMatchStartAndEndTime={selectedPostMatchStartAndEndTime}
                    selectedPostMatchInterviewsTime={selectedPostMatchInterviewsTime}
                    localUTCOffset={localUTCOffset}
                  />
                </WorldFeedElements>

                <AfterWorldFeedElements>
                  <RundownSection
                    feed={afterWorldFeed}
                    selectedWeekDay={selectedWeekDay}
                    selectedUTCOffset={selectedTimeOffset}
                    selectedDigitalHighlightsClipsTime={selectedDigitalHighlightsClipsTime}
                    selectedPostMatchInterviewsTime={selectedPostMatchInterviewsTime}
                    selectedSlowMotionPlayOutsTime={selectedSlowMotionPlayOutsTime}
                    localUTCOffset={localUTCOffset}
                  />
                </AfterWorldFeedElements>
              </RundownElements>

              <WorldFeedLinkRow>
                <WorldFeedLinkArea>
                  <CaretWrapper style={{margin: "3px 3px 0 0"}}>
                    <Caret color={colors.redDFL} />
                  </CaretWrapper>
                  <WorldFeedLink to={`/product/bundesliga-matches`}>World Feed</WorldFeedLink>
                  <WorldFeedLinkLineWrapper>
                    <WorldFeedLinkLine />
                  </WorldFeedLinkLineWrapper>
                </WorldFeedLinkArea>
              </WorldFeedLinkRow>

              <AdditionalLinkRow>
                <WorldFeedLinkArea>
                  <CaretWrapper style={{margin: "3px 50px 0 0"}}/>
                  <WorldFeedLink to={`/whats-new`}>Satellite transmission</WorldFeedLink>
                  <WorldFeedLinkLineWrapper>
                    <WorldFeedLinkLine />
                  </WorldFeedLinkLineWrapper>
                </WorldFeedLinkArea>
              </AdditionalLinkRow>

              <AdditionalLinkRow>
                <WorldFeedLinkArea>
                  <CaretWrapper style={{margin: "3px 3px 0 0"}}/>
                  <WorldFeedLink to={`/whats-new`}>Livestream</WorldFeedLink>
                  <WorldFeedLinkLineWrapper>
                    <WorldFeedLinkLine />
                  </WorldFeedLinkLineWrapper>
                </WorldFeedLinkArea>
              </AdditionalLinkRow>

              <AdditionalLinkRow>
                <WorldFeedLinkArea>
                  <CaretWrapper style={{margin: "3px 3px 0 0"}}>
                    <Caret color={colors.redDFL} />
                  </CaretWrapper>
                  <WorldFeedLink to={`/whats-new`}>Interactive Feed</WorldFeedLink>
                  <WorldFeedLinkLineWrapper>
                    <WorldFeedLinkLine />
                  </WorldFeedLinkLineWrapper>
                </WorldFeedLinkArea>
              </AdditionalLinkRow>

              <AdditionalLinkRow>
                <GoalArenaLinkArea>
                  <CaretWrapper style={{margin: "3px 50px 0 0"}}>
                    <Caret color={colors.redDFL} />
                  </CaretWrapper>
                  <WorldFeedLink to={`/product/bundesliga-matches/#goal-arena---the-bundesliga-konferenz`}>Goal Arena - the Bundesliga Konferenz</WorldFeedLink>
                  <WorldFeedLinkLineWrapper>
                    <WorldFeedLinkLine />
                  </WorldFeedLinkLineWrapper>
                </GoalArenaLinkArea>
              </AdditionalLinkRow>
              <Footnote isMobile={isMobile}>
                <b>* </b>Standard Kick-Off Times
              </Footnote>
            </RundownGraphicWrapper>
          </OverflowScroll>
        </RundownStage>
      </RundownWrapper>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RundownComp);
