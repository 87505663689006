/**
 * Caret icon
 *
 */

// IMPORTS
import React from "react";

interface CaretProps {
  color?: string;
  strokeWidth?: number;
}
const Caret = ({color = '#fff', strokeWidth = 1.5}: CaretProps) => (
  <svg width="100%" height="100%" viewBox="0 0 10 10" version="1.1"  xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g stroke="none" strokeWidth="1" fill="none" strokeLinecap="square" transform="translate(1 1)">
      <polyline stroke={color} strokeWidth={strokeWidth} points="8 2 4 6 0 2" />
    </g>
  </svg>
);


export default Caret;
