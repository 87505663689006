import React from "react";
import ReactJWPlayer from "react-jw-player";
import {Waypoint} from "react-waypoint";
import {Dispatch} from "redux";
import {connect} from "react-redux";
import {AppState} from "../../store/";
import {setCurrentJwPlayerIdAction} from "../../store/system/actions";
import {trackVideoPlayerAction} from "../../util/tracking";

const PLAYER_SCRIPT = "https://cdn.jwplayer.com/libraries/FOfCxX5C.js";

interface PropsFromState {
  currentJwPlayerId: string;
}

interface PropsFromDispatch {
  setCurrentJwPlayerId: (newJwPlayerId: string) => void;
}
interface VideoPlayerProps extends PropsFromState, PropsFromDispatch {
  playerId: string;
}

interface VideoPlayerStates {
  isPlaying: boolean;
  playerId: string;
  loaded: boolean;
}

const mapStateToProps = (state: AppState): PropsFromState => ({
  currentJwPlayerId: state.system.currentJwPlayerId,
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => {
  return {
    setCurrentJwPlayerId: (newJwPlayerId: string) => {
      return dispatch(setCurrentJwPlayerIdAction(newJwPlayerId));
    },
  };
};

class VideoPlayer extends React.Component<VideoPlayerProps, VideoPlayerStates> {
  constructor(props: VideoPlayerProps) {
    super(props);
    this.onPlay = this.onPlay.bind(this);
    this.onPause = this.onPause.bind(this);
    this.onResume = this.onResume.bind(this);
    this.handleWaypointLeave = this.handleWaypointLeave.bind(this);
    // NOTE: using state for playerId for localized video change
    this.state = {isPlaying: false, playerId: this.props.playerId, loaded: false};
  }
  componentDidMount() {
    /* NOTE: temporary solution until this is merged.
    https://github.com/micnews/react-jw-player/issues/137 */
    setTimeout(() => {
      this.setState({
        loaded: true,
      });
    }, 500);
  }
  componentWillUnmount() {
    const {setCurrentJwPlayerId} = this.props;
    setCurrentJwPlayerId(null);
  }
  onPlay() {
    trackVideoPlayerAction({action: "play", videoId: this.state.playerId});
    const {setCurrentJwPlayerId} = this.props;
    setCurrentJwPlayerId(this.state.playerId);
    this.setState({isPlaying: true});
  }
  onPause() {
    trackVideoPlayerAction({action: "pause", videoId: this.state.playerId});
    this.setState({isPlaying: false});
  }
  onResume() {
    // the tracking document didn't list this event as one that should
    // be tracked so no event is fired
    const {setCurrentJwPlayerId} = this.props;
    setCurrentJwPlayerId(this.state.playerId);
    this.setState({isPlaying: true});
  }
  onEnterFullScreen = () => {
    trackVideoPlayerAction({action: "fullscreen.true", videoId: this.state.playerId});
  };
  onExitFullScreen = () => {
    trackVideoPlayerAction({action: "fullscreen.false", videoId: this.state.playerId});
  };
  onMute = () => {
    trackVideoPlayerAction({action: "mute.true", videoId: this.state.playerId});
  };
  onUnmute = () => {
    trackVideoPlayerAction({action: "mute.false", videoId: this.state.playerId});
  };
  onOneHundredPercent = () => {
    trackVideoPlayerAction({action: "complete", videoId: this.state.playerId});
  };

  handleWaypointLeave() {
    if (this.state.isPlaying) {
      window.jwplayer(this.state.playerId).pause();
    }
  }
  render() {
    const {playerId} = this.props;
    return (
      <>
        {this.state.loaded && (
          <Waypoint onLeave={this.handleWaypointLeave}>
            <div style={{width: "100%"}}>
              <ReactJWPlayer
                onPlay={this.onPlay}
                onPause={this.onPause}
                onResume={this.onResume}
                onEnterFullScreen={this.onEnterFullScreen}
                onExitFullScreen={this.onExitFullScreen}
                onMute={this.onMute}
                onUnmute={this.onUnmute}
                onOneHundredPercent={this.onOneHundredPercent}
                playerId={this.state.playerId}
                playerScript={PLAYER_SCRIPT}
                // NOTE: must use props instead of state for localized to work
                playlist={`https://cdn.jwplayer.com/v2/playlists/${playerId}?format=mrss`}
              />
            </div>
          </Waypoint>
        )}
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VideoPlayer);
