/**
 * Close (x) icon
 *
 */

// IMPORTS
import React from "react";

interface CloseProps {
  color?: string;
  strokeWidth?: number;
}
const Close = ({color = "#fff", strokeWidth = 1.5}: CloseProps) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 23 23"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g stroke={color} strokeWidth={strokeWidth} fill="none" strokeLinecap="round">
      <path d="M0.372786458,0.365923713 L20.9333333,20.9264706" />
      <path d="M0.372786458,0.365923713 L20.9333333,20.9264706" transform="translate(21, 0) scale(-1, 1)" />
    </g>
  </svg>
);

export default Close;
