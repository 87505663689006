import * as React from "react";
import {Link} from "gatsby";
import styled, {css} from "styled-components";
import VideoPlayer from "../videoPlayer/videoPlayer";
import {breakpoint, colors, fonts, fontSizesPx, stage} from "../../models";
import {Caret} from "../../components";
import {getLastSlug} from "../../util/util";

const CARET_SIZE = 8;

const CaretWrapper = styled.div`
  width: ${CARET_SIZE}px;
  height: ${CARET_SIZE}px;
  transform: rotate(-90deg) translateY(-${CARET_SIZE}px);
`;

const StyledLink = styled(props => <Link {...props} />)`
  display: inline-flex;
  align-items: center;
  font-size: ${fontSizesPx.small};
  font-weight: ${fonts.weightBold};
  line-height: ${CARET_SIZE}px;
  color: ${colors.black};
  margin: 0 0 20px ${CARET_SIZE - 2}px;
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`;

const Description = styled.p`
  font-size: ${fontSizesPx.small};
`;

const Heading = styled.h1`
  font-size: ${fontSizesPx.medium};

  ${breakpoint.xl.up} {
     font-size: ${fontSizesPx.mediumBig};
     margin-top: 0;
  };
`;

const Article = styled.article`
  padding: 0 20px;

  ${breakpoint.m.up} {
    padding: 0;
  };
`;

const VideoWrapper = styled.div`
  width: 100%;
`;

const Image = styled.img`
  width: 100%;
`;

// NOTE: imageSize and videoSize on desktop is set to medium by default
const Container = styled.div<{layout: string; imageSize?: string; videoSize?: string}>`
  display: grid;
  margin: 90px auto;
  font-family: ${fonts.headerFont};

  ${breakpoint.m.up} {
    max-width: 605px;
  };

  ${breakpoint.xl.up} {
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 10px;
    max-width: ${stage.outerWidth}px;
    margin: 120px auto;

    ${Image},
    ${VideoWrapper} {
        // NOTE: medium size
        grid-column: 1 / span 7;

        ${p =>
          p.layout === "right" &&
          css`
            grid-column: 6 / span 12;
            order: 2;
          `};
    }

    ${Image} {
       ${p =>
         p.imageSize === "small" &&
         css`
           max-width: 515px;
           grid-column: 2 / span 7;
         `};

      // NOTE: small size applicable to Image only
      ${p =>
        p.imageSize === "small" &&
        p.layout === "right" &&
        css`
          grid-column: 7 / span 9;
        `};

        ${p =>
          p.imageSize === "large" &&
          css`
            width: 840px;
            transform: translateX(${p => (p.layout === "right" ? "120px" : "-120px")});
          `};
    }

    ${Article} {
      // NOTE: medium size
      grid-column: 10 / span 12;

      ${p =>
        p.imageSize === "small" &&
        css`
          grid-column: 9 / span 10;
          max-width: 390px;
        `};


      ${p =>
        p.layout === "right" &&
        css`
          grid-column: 1 / span 4;
        `};

      // NOTE: small size applicable to Image only
      ${p =>
        p.imageSize === "small" &&
        p.layout === "right" &&
        css`
          grid-column: 2 / span 4;
        `};
    } // end Article

    ${VideoWrapper} {
      ${p =>
        p.videoSize === "large" &&
        css`
          width: 840px;
          transform: translateX(${p => (p.layout === "right" ? "120px" : "-120px")});
        `};
    }

  // end breakpoint.xl here
  };
`;

interface ContentBlockProps {
  heading: string;
  description: string;
  type: "video" | "image";
  links: any;
  imageUrl?: string;
  imageSize?: string;
  videoId?: string;
  videoSize?: string;
  layout: string;
}

const getLastSlugWithAnchor = (url: string): string => {
  const match = url.match(/\/[a-z-]+\/(#?.*)$/);
  return match && match.length > 0 ? match[1] : null;
};

const ContentBlockComp = (props: ContentBlockProps) => {
  const {heading, description, type, links, imageUrl, videoId, layout, imageSize, videoSize} = props;
  const hasLinks = links instanceof Array;
  return (
    <Container layout={layout} imageSize={imageSize} videoSize={videoSize}>
      {type === "image" && <Image src={imageUrl} alt={heading} />}
      {type === "video" && (
        <VideoWrapper>
          <VideoPlayer playerId={videoId} />
        </VideoWrapper>
      )}
      <Article>
        <Heading>{heading}</Heading>
        <Description>{description}</Description>
        {hasLinks && (
          <Links>
            {links.map((item, i) => {
              return (
                item.link &&
                item.label && (
                  <StyledLink key={`item-${i}`} to={`/product/${getLastSlugWithAnchor(item.link.url)}`}>
                    <CaretWrapper>
                      <Caret strokeWidth={2} color={colors.redDFL} />
                    </CaretWrapper>
                    {item.label}
                  </StyledLink>
                )
              );
            })}
          </Links>
        )}
      </Article>
    </Container>
  );
};

export default ContentBlockComp;

