import React from "react";
import Select from "react-select";
import styled from "styled-components";
import {colors, fontSizesPx, fonts} from "../models";

const OPTION_MIN_WIDTH = 70;
const Container = styled.div`
  min-width: ${OPTION_MIN_WIDTH * 2}px;
`;

const customStyles = {
  control: () => ({
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    border: "none",
    borderBottom: `1px solid ${colors.grayDark2}`,
    borderRadius: "none",
    cursor: "pointer",
    minHeight: "25px",
    "&:hover": {
      boxShadow: "none",
    },
  }),
  singleValue: () => ({
    fontFamily: fonts.condensedFont,
    fontSize: fontSizesPx.micro,
    textOverflow: "initial",
    whiteSpace: "nowrap",
    padding: "0",
    border: "none",
    "& > div": {
      display: "flex",
    },
    p: {
      margin: "0",
      padding: "0",
      "&:first-child": {
        marginRight: "5px",
      },
    },
    span: {
      color: colors.redDFL,
    },
  }),
  indicatorSeparator: () => {
    // leave empty as per doc to hide the separator
  },
  dropdownIndicator: () => ({
    color: colors.black,
    "& svg": {width: "14px"},
  }),
  option: (styles, {isDisabled, isFocused, isSelected}) => {
    return {
      ...styles,
      padding: "5px 10px!important",
      fontFamily: fonts.condensedFont,
      fontSize: fontSizesPx.micro,
      color: colors.black,
      borderRadius: "none",
      borderBottom: `1px solid ${colors.grayDark2}`,
      backgroundColor: isSelected || isFocused ? colors.grayUltraLight : "none",
      cursor: isDisabled ? "not-allowed" : "pointer",
      "&:last-child": {
        borderBottom: "none",
      },
      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled && (isSelected ? colors.grayLight : "none"),
      },
      "& > div": {
        display: "flex",
      },
      p: {
        margin: "0",
        padding: "0",
        "&:first-child": {
          minWidth: `${OPTION_MIN_WIDTH}px`,
        },
      },
      span: {
        color: colors.redDFL,
      },
    };
  },
  menu: styles => ({
    ...styles,
    fontSize: fontSizesPx.micro,
    borderRadius: "none",
    boxShadow: "none",
    border: `1px solid ${colors.grayDark2}`,
  }),
  menuList: styles => ({
    ...styles,
    maxHeight: "205px",
  }),
  placeholder: () => ({
    fontSize: fontSizesPx.micro,
  }),
};
interface SelectProps {
  className?: string;
  defaultValue: any;
  onHandleChange: () => void;
  options: any;
}

const CustomSelect = (props: SelectProps) => {
  const {className, options, onHandleChange, defaultValue} = props;

  return (
    <Container className={className}>
      <Select
        onChange={onHandleChange}
        options={options}
        styles={customStyles}
        isSearchable={false}
        defaultValue={defaultValue}
      />
    </Container>
  );
};

export default CustomSelect;
