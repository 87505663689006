import * as React from 'react';
import SeasonCategoryRow from "./seasonCategoryRow";
import SeasonLabelRow from "./seasonLabelRow";
import {VerticalBold, VerticalRegular, VerticalWrapper, ContentTable} from "../styled";
import {ScheduleAroundSeasonContentProps} from "../../../../models";

interface AroundMatchdaysProps {
  categories: ScheduleAroundSeasonContentProps[];
}
const aroundSeasonComp = (props: AroundMatchdaysProps) => {
  const {categories} = props;
  return (
    <ContentTable>
      <tbody>
        {/* season labels */}
        <SeasonLabelRow />

        {/* bundesliga matches row */}
        <SeasonCategoryRow
          isSpan={true}
          content={categories[0]}
        />

        {/* digital content row */}
        <SeasonCategoryRow
          isSpan={true}
          span={4}
          spanContent={
            <VerticalWrapper>
              <VerticalRegular>Bundesliga</VerticalRegular>
              <VerticalBold>Storytelling & Promotion</VerticalBold>
            </VerticalWrapper>
          }
          content={categories[1]}
        />

        {/* monthly content row */}
        <SeasonCategoryRow
          isSpan={false}
          content={categories[2]}
        />

        {/* seasonal content row */}
        <SeasonCategoryRow
          isSpan={false}
          content={categories[3]}
        />
      </tbody>
    </ContentTable>
  );
};

export default aroundSeasonComp;
