import * as React from "react";
import styled from "styled-components";
import {InfographicsBarChartProps, colors, fontSizesPx, breakpoint} from "../../models";
import ProgressTagComp from "../progressTag/progressTag";
import {addCommasToNumber} from "../../util/util";

const Number = styled.p`
  font-size: 17px;
  font-weight: bold;
  margin: 0;
`;

const Label = styled.p`
  font-size: ${fontSizesPx.micro};
  color: ${colors.grayDark};
  margin: 0;

  @supports (-ms-ime-align: auto) {
    transform: translateY(60px);
  }

  ${breakpoint.xs.down} {
    word-break: break-all;
    hyphens: auto;
  };
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 50px);
  grid-column-gap: 8px;
  justify-content: space-around;
  ${breakpoint.s.up} {
      grid-gap: 30px;
  };
`;

const QuickFactsBarChartComp = (props: InfographicsBarChartProps) => {
  const {items, barChartMaxValue} = props;
  return (
    <Container>
      {items.map((item, i) => {
        return (
          <Item key={`item-${i}`}>
            <Number>{addCommasToNumber(item.value, barChartMaxValue)}</Number>
            <ProgressTagComp filledColor={item.barColor} value={item.value} max={barChartMaxValue} />
            <Label>{item.label}</Label>
          </Item>
        );
      })}
    </Container>
  );
};

export default QuickFactsBarChartComp;
