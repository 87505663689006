import React from "react";
import styled from "styled-components";
import {Dispatch} from "redux";
import {connect} from "react-redux";
import {
  RegularCell,
  CategoryAggregationCell,
  CategoryCell,
  BroadcastEntryLink,
  FlippedTextContainer,
  FlippedTextInner,
} from "../styled";
import {Clock} from "../../..";
import {
  WeekDayType,
  condensedSmallBold,
  broadcastSynergyGroups,
  BroadcastProductType,
  ScheduleCategoryContentProps,
  UTCTimeStateType,
  utcTimeStates,
  DFL_UTC_OFFSET,
  timings,
} from "../../../../models";
import {AppState} from "../../../../store";
import {updateActiveSynergiesAction, updateHoveredDayAction} from "../../../../store/schedule/actions";
import {createBroadcastDate, displayBroadcastTimeByLocalTimezoneOffset} from "../../../../util/util";
import {trackInternalLinkClick} from "../../../../util/tracking";

const TableRow = styled.tr``;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  /* font-size: 13px; */
`;
const CellInner = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 8px;
`;
const BroadcastEntry = styled(Row)`
  width: 100%;
  margin-bottom: 2px;
`;
const LabelRow = styled(Row)`
  justify-content: space-between;
  align-items: flex-start;
`;
const RowRight = styled(Row)`
  width: 220px;
  justify-content: space-between;
`;
interface LabelProps {
  active: boolean;
  textColor: string;
}
const Label = styled.div`
  ${condensedSmallBold};
  font-size: 13px;
  padding: 0;
  margin: 0;
  color: ${(props: LabelProps) => props.textColor};
  opacity: ${(props: LabelProps) => (props.active ? 1 : 0.3)};
  transition: opacity ${timings.hurried}ms;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }
`;
const SubLabel = styled(Label)`
  margin-left: 10px;
`;
const TimeLabel = styled(Label)`
  width: 180px;
  justify-content: flex-start;

  &:hover {
    text-decoration: none;
  }
`;

interface ClockWrapperProps {
  active: boolean;
}
const ClockWrapper = styled.div`
  width: 15px;
  height: 15px;
  margin-right: 4px;
  opacity: ${(props: ClockWrapperProps) => (props.active ? 1 : 0.3)};
  transition: opacity ${timings.hurried}ms;
`;

const SynergyDotWrapper = styled.div`
  width: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

interface SynergyDotProps {
  active: boolean;
  color: string;
}
const SynergyDot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: ${(props: SynergyDotProps) => props.color};
  opacity: ${(props: SynergyDotProps) => (props.active ? 1 : 0)};
  transition: opacity ${timings.hurried}ms;
`;

interface PropsFromState {
  activeSynergies: BroadcastProductType[];
  hoveredDay: number;
  localUTCOffset: number;
  selectedUTCOffset: UTCTimeStateType;
}
const mapStateToProps = (state: AppState): PropsFromState => ({
  activeSynergies: state.schedule.activeBroadcastSynergies,
  hoveredDay: state.schedule.hoveredDay,
  localUTCOffset: state.system.utcOffset,
  selectedUTCOffset: state.schedule.selectedUTCTimeOffset,
});

interface PropsFromDispatch {
  updateActiveSynergies: (newSynergies: BroadcastProductType[]) => void;
  updateHoveredDay: (newDayIndex: number) => void;
}
const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => {
  return {
    updateActiveSynergies: (newSynergies: BroadcastProductType[]) => {
      return dispatch(updateActiveSynergiesAction(newSynergies));
    },
    updateHoveredDay: (newDayIndex: number) => {
      return dispatch(updateHoveredDayAction(newDayIndex));
    },
  };
};
interface MatchdayCategoryRowProps extends PropsFromState, PropsFromDispatch {
  isSpan: boolean;
  span?: number;
  spanContent?: JSX.Element;
  content: ScheduleCategoryContentProps;
  activeMatchDays: [WeekDayType, WeekDayType];
}
const MatchdayCategoryRow = (props: MatchdayCategoryRowProps) => {
  const {
    isSpan,
    span,
    spanContent,
    content,
    activeMatchDays,
    activeSynergies,
    updateActiveSynergies,
    updateHoveredDay,
    hoveredDay,
    localUTCOffset,
    selectedUTCOffset,
  } = props;
  const {categoryName, backgroundColor, labelColor, textColor, days} = content;

  return (
    <TableRow>
      {isSpan && (
        <CategoryAggregationCell rowSpan={span}>
          <FlippedTextContainer>
            <FlippedTextInner>{spanContent}</FlippedTextInner>
          </FlippedTextContainer>
        </CategoryAggregationCell>
      )}
      <CategoryCell color={labelColor} borderColor={backgroundColor}>
        {categoryName}
      </CategoryCell>
      {days.map((day, dayIndex) => {
        const isCollapsed = !activeMatchDays.includes(dayIndex);
        const isDayHovered = hoveredDay === dayIndex;

        return (
          <RegularCell
            key={`${categoryName}-${dayIndex}`}
            collapsed={isCollapsed}
            backgroundColor={backgroundColor}
            opacity={isCollapsed ? 0.2 : 1}
          >
            <CellInner>
              {!isCollapsed &&
                day.broadcastGroups.map((broadCastGroup, entryIndex) => {
                  // check if any one broadcast of group is in the synergyGroup
                  let isBroadcastGroupInActiveSynergyGroup = false;
                  for (const broadcast of broadCastGroup.broadcasts) {
                    if (activeSynergies.includes(broadcast.id)) {
                      isBroadcastGroupInActiveSynergyGroup = true;
                    }
                  }

                  // day.weekDay

                  const linkTo = `/product/${broadCastGroup.productLink}`;

                  return (
                    <BroadcastEntry key={`${dayIndex}-${entryIndex}`}>
                      <SynergyDotWrapper>
                        <SynergyDot active={isBroadcastGroupInActiveSynergyGroup} color={textColor} />
                      </SynergyDotWrapper>

                      <BroadcastEntryLink
                        to={linkTo}
                        onClick={() => trackInternalLinkClick({name: broadCastGroup.label, link: linkTo})}
                      >
                        <LabelRow>
                          {/* <Label active={isDayHovered ? isInActiveSynergyGroup : true}>{entry.label}</Label> */}
                          <Row
                            /*onMouseEnter={() => {
                              const synergyGroup = broadcastSynergyGroups.filter(group => {
                                return group.includes(broadCastGroup.broadcasts[0].id);
                              });
                              if (synergyGroup.length > 0) {
                                updateActiveSynergies(synergyGroup[0]);
                                updateHoveredDay(dayIndex);
                              }
                            }}
                            onMouseLeave={() => {
                              updateActiveSynergies([]);
                              updateHoveredDay(null);
                            }}*/
                          >
                            <Label
                              active={isDayHovered ? isBroadcastGroupInActiveSynergyGroup : true}
                              textColor={textColor}
                            >
                              {broadCastGroup.label}
                            </Label>
                            <SubLabel
                              active={isDayHovered ? isBroadcastGroupInActiveSynergyGroup : true}
                              textColor={textColor}
                            >
                              {broadCastGroup.subLabel}
                            </SubLabel>
                          </Row>

                          <RowRight>
                            {broadCastGroup.broadcasts.map((broadcast, broadcastIndex) => {
                              // for each broadcast in broadcast group
                              const utcOffsetToUse =
                                selectedUTCOffset === utcTimeStates.localTime ? localUTCOffset : DFL_UTC_OFFSET;

                              const isBroadcastInActiveSynergyGroup = activeSynergies.includes(broadcast.id);
                              const broadCastDate = broadcast.time ?
                                  broadcast.time.anyTime ? 'Any time' : displayBroadcastTimeByLocalTimezoneOffset(
                                    utcOffsetToUse,
                                    createBroadcastDate(
                                      broadcast.time.day,
                                      broadcast.time.hours,
                                      broadcast.time.minutes,
                                    ),
                                    day.weekDay,
                                  )
                                : null;

                              return (
                                broadcast.time && (
                                  <TimeLabel
                                    key={broadcastIndex}
                                    active={isDayHovered ? isBroadcastInActiveSynergyGroup : true}
                                    textColor={textColor}
                                    /*onMouseEnter={() => {
                                      const synergyGroup = broadcastSynergyGroups.filter(group => {
                                        return group.includes(broadcast.id);
                                      });
                                      if (synergyGroup.length > 0) {
                                        updateActiveSynergies(synergyGroup[0]);
                                        updateHoveredDay(dayIndex);
                                      }
                                    }}
                                    onMouseLeave={() => {
                                      updateActiveSynergies([]);
                                      updateHoveredDay(null);
                                    }}*/
                                  >
                                    <Row>
                                      <ClockWrapper active={isDayHovered ? isBroadcastInActiveSynergyGroup : true}>
                                        <Clock strokeWidth={1} color={textColor} />
                                      </ClockWrapper>
                                      {broadCastDate}
                                    </Row>
                                  </TimeLabel>
                                )
                              );
                            })}
                          </RowRight>
                        </LabelRow>
                      </BroadcastEntryLink>
                    </BroadcastEntry>
                  );
                })}
            </CellInner>
          </RegularCell>
        );
      })}
    </TableRow>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MatchdayCategoryRow);
