import * as React from "react";
import styled from "styled-components";
import {Caret} from "../../components";
import {
  colors,
  zIndices,
  mediumBold,
  ProductCategory,
  px,
  baseBold,
  componentBreakpoints,
  CenteredDiv,
  macroRegular,
  smallRegular,
} from "../../models";

const Title = styled.div`
  ${baseBold};
  font-size: 18px;
  color: ${colors.white};
  text-transform: uppercase;
  line-height: 1.1em;
  margin-bottom: 7px;
  position: relative;
  z-index: ${zIndices.zTileText};

  /* desktop */
  @media (min-width: ${px(componentBreakpoints.categoryList)}) {
    ${mediumBold};
    color: ${colors.white};
    margin-bottom: 10px;
    line-height: 1.1em;
  }
`;
const Text = styled.div`
  ${macroRegular};
  font-size: 11px;
  line-height: 1.3em;
  color: ${colors.white};
  position: relative;
  z-index: ${zIndices.zTileText};

  /* desktop */
  @media (min-width: ${px(componentBreakpoints.categoryList)}) {
    ${smallRegular};
    color: ${colors.white};
    line-height: 1.3em;
  }
`;

const CaretIcon = styled(CenteredDiv)`
  background-color: ${colors.whiteAlpha60};
  width: 22px;
  height: 22px;
  position: absolute;
  right: 5px;
  bottom: 5px;

  /* desktop */
  @media (min-width: ${px(componentBreakpoints.categoryList)}) {
    width: 30px;
    height: 30px;
  }
`;
const CaretWrapper = styled.div`
  width: 10px;
  height: 10px;
  transform: rotate(-90deg);
  margin-left: -12px;

  /* desktop */
  @media (min-width: ${px(componentBreakpoints.categoryList)}) {
    width: 16px;
    height: 16px;
    margin-left: 0;
  }
`;

interface TileProps {
  color: string;
  image?: string;
}
const Tile = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px 20px 15px 10px;
  box-sizing: border-box;
  background-color: ${(props: TileProps) => props.color};
  background-image: ${(props: TileProps) => (props.image ? `url(${props.image})` : "none")};
  background-size: cover;
  position: relative;

  &:hover {
    ${Title} {
      text-decoration: underline;
    }
  }

  /* desktop */
  @media (min-width: ${px(componentBreakpoints.categoryList)}) {
    padding: 30px 20px;
  }
`;
const TileOverlay = styled.div`
  width: 100%;
  height: 50%;
  background: rgb(0, 132, 255);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
  position: absolute;
  left: 0;
  top: 0;
  z-index: ${zIndices.zTileOverlay};
`;

interface CategoryTileProps extends ProductCategory {
  isMobileNav?: boolean;
}
const CategoryTile = (props: CategoryTileProps) => {
  const {title, description, color, image, isMobileNav} = props;
  return (
    <Tile color={color} image={image}>
      {image && <TileOverlay />}
      <Title>{title}</Title>
      {!isMobileNav && <Text>{description}</Text>}
      <CaretIcon>
        <CaretWrapper>
          <Caret strokeWidth={1} />
        </CaretWrapper>
      </CaretIcon>
    </Tile>
  );
};

export default CategoryTile;
