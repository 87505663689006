import React, {MouseEvent} from "react";
import styled from "styled-components";
import {CategoryListWithQuery} from "../../../components";
import {stage, elementSizes} from "../../../models";

interface ProductsDropdownStylesProps {
  show?: boolean;
}

const CONTAINER_PADDING_TOP = elementSizes.navigationHeaderHeight + 20;

const InnerContainer = styled.div`
  width: ${stage.outerWidth}px;
  margin: 0 auto;
`;

const Container = styled.div<ProductsDropdownStylesProps>`
  display: ${p => (p.show ? "flex" : "none")};
  flex-direction: row;
  height: 100%;

  padding: ${CONTAINER_PADDING_TOP}px 0 80px 0;
  box-sizing: border-box;
`;

interface ProductsDropdownProps {
  className?: string;
  show?: boolean;
  onMouseLeave: (event: MouseEvent) => void;
}

const ProductsDropdown = (props: ProductsDropdownProps) => {
  const {className, show, onMouseLeave} = props;
  return (
    <Container className={className} show={show} onMouseLeave={onMouseLeave}>
      <InnerContainer>
        <CategoryListWithQuery />
      </InnerContainer>
    </Container>
  );
};

export default ProductsDropdown;
