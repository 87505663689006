import * as React from "react";
import styled from "styled-components";
import {Caret} from "../components";
import {colors, smallBold, ButtonProps, CenteredDiv} from "../models";

const CTA = styled.a`
  ${smallBold};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 50px;
  text-decoration: none;
  background-color: ${colors.white};
  color: ${colors.redDFL};
  border: 2px solid ${colors.redDFL};
  box-sizing: border-box;
  text-transform: uppercase;

  &:hover {
    background-color: ${colors.redDFL};
    color: ${colors.white};
  }
`;

const CaretWrapper = styled(CenteredDiv)`
  position: absolute;
  width: 16px;
  height: 16px;
  top: 16px;
  right: 10px;
  transform: rotate(-90deg);
`;

interface CtaState {
  hovered: boolean;
}
class CTAComp extends React.Component<ButtonProps, CtaState> {
  constructor(props: ButtonProps) {
    super(props);
    this.state = {
      hovered: false,
    };
  }

  render() {
    const {link, label} = this.props;

    return (
      <CTA
        href={link}
        onMouseEnter={() => this.setState({hovered: true})}
        onMouseLeave={() => this.setState({hovered: false})}
      >
        {label}
        <CaretWrapper>
          <Caret color={this.state.hovered ? colors.white : colors.redDFL} strokeWidth={1} />
        </CaretWrapper>
      </CTA>
    );
  }
}

export default CTAComp;
