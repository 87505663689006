import * as React from "react";
import styled from "styled-components";
import {Dispatch} from "redux";
import {connect} from "react-redux";
import {AppState} from "../../store/";
import {
  setUserDidConsentAction,
} from "../../store/system/actions";
import {colors, zIndices, px, stage, RowDiv, smallRegular, CenteredDiv, COOKIE_KEY, staticPageMap, staticPages, breakpoints} from "../../models";
import {columnSpan} from "../../util/util";

// COMPONENT PROPERTIES
interface PropsFromState {
  userDidConsent: boolean;
}
const mapStateToProps = (state: AppState): PropsFromState => ({
  userDidConsent: state.system.userDidConsent,
});

interface PropsFromDispatch {
  setUserDidConsent: (didConsent: boolean) => void;
}
const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => {
  return {
    setUserDidConsent: (didConsent: boolean) => {
      return dispatch(setUserDidConsentAction(didConsent));
    },
  };
};

interface ConsentBannerContainerProps {
  hidden: boolean;
}
const ConsentBannerContainer = styled(CenteredDiv)`
  position: fixed;
  width: 100%;
  height: unset;
  bottom: 0;
  z-index: ${zIndices.zConsentBanner};
  background-color: ${colors.blackAlpha90};
  color: ${colors.white};
  pointer-events: initial;
  display: ${(props: ConsentBannerContainerProps) => props.hidden ? "none" : "flex"};
  padding: 20px;
  box-sizing: border-box;

  /* desktop */
  @media (min-width: ${px(breakpoints.desktopHd)}) {
    width: 100vw;
    height: 100px;
    padding: 0;
  }
`;

const ConsentBannerContainerInner = styled(RowDiv)`
  width: 100%;
  height: unset;
  margin: 0;
  flex-direction: column;

  /* desktop */
  @media (min-width: ${px(breakpoints.desktopHd)}) {
    width: ${px(stage.outerWidth)};
    height: 100%;
    margin: 0 auto;
    flex-direction: row;
  }
`;
const ConsentBannerMessage = styled.div`
  ${smallRegular};
  color: ${colors.white};
  margin-right: 0;
  margin-bottom: 32px;
  margin-left: 0;
  width: 100%;
  max-width: ${px(columnSpan(8, stage.columnWidth, stage.columnGap))};

  a {
    ${smallRegular};
    color: ${colors.white};
    text-decoration: underline;
  }

  /* desktop */
  @media (min-width: ${px(breakpoints.desktopHd)}) {
    width: ${px(columnSpan(9, stage.columnWidth, stage.columnGap))};
    margin-right: ${px(stage.columnGap)};
    margin-bottom: 0;
    margin-left: ${px(columnSpan(1, stage.columnWidth, stage.columnGap) + stage.columnGap)};
  }
`;

const ConsentBannerButton = styled.button`
  width: 100%;
  height: 50px;
  border: 1px solid ${colors.white};
  text-transform: uppercase;
  background-color: transparent;
  color: ${colors.white};
  cursor: pointer;
  max-width: ${px(columnSpan(4, stage.columnWidth, stage.columnGap))};

  /* desktop */
  @media (min-width: ${px(breakpoints.desktopHd)}) {
    width: ${px(columnSpan(2, stage.columnWidth, stage.columnGap))};
  }
`;

interface ConsentBannerProps extends PropsFromState, PropsFromDispatch {}
class ConsentBanner extends React.Component<ConsentBannerProps> {
  constructor(props: ConsentBannerProps) {
    super(props);
    this.hideForever = this.hideForever.bind(this);
  }

  componentDidMount() {
    // assume default of user already having consent to cookie policy

    const {setUserDidConsent} = this.props;

    // set to false if cookie can't be found
    const isConsentCookieSet = localStorage.getItem(COOKIE_KEY) === "true";
    if (!isConsentCookieSet) {
      setUserDidConsent(false);
    }
  }

  render() {
    const {userDidConsent} = this.props;
    return (
      <ConsentBannerContainer hidden={userDidConsent}>
        <ConsentBannerContainerInner>
          <ConsentBannerMessage>
            {`We use cookies so that this website can be operated safely and optimized as well as its content. 
            For further information, please refer to our `}
            <a href={`/${staticPageMap.get(staticPages.privacyPolicy).slug}`} onClick={this.hideForever}>
              {"privacy statement"}
            </a>.
          </ConsentBannerMessage>

          <ConsentBannerButton onClick={this.hideForever}>
            OK
          </ConsentBannerButton>
        </ConsentBannerContainerInner>
      </ConsentBannerContainer>
    );
  }

  hideForever() {
    localStorage.setItem(COOKIE_KEY, "true");
    this.props.setUserDidConsent(true);
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConsentBanner);
