import * as React from "react";
import styled, {css} from "styled-components";
import {Link} from "gatsby";
import {
  breakpoint,
  colors,
  fonts,
  fontSizesPx,
  FullpageHeadline,
  RelatedProductsProps,
  stage,
  linksListItemsDesktopColumnsLayout,
} from "../../models";
import {Caret} from "../../components";

const CARET_WIDTH_MOBILE = 12;
const CARET_WIDTH_DESKTOP = 16;

const CaretWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  width: ${CARET_WIDTH_MOBILE + 1}px;
  height: ${CARET_WIDTH_MOBILE}px;
  transform: rotate(-90deg) translateX(50%);

  ${breakpoint.l.up} {
    width: ${CARET_WIDTH_DESKTOP + 1}px;
    height: ${CARET_WIDTH_DESKTOP}px;
  };
`;

const SubTitle = styled.b`
  font-size: ${fontSizesPx.macro};
  color: ${colors.black};
  text-transform: uppercase;
  padding-right: ${CARET_WIDTH_DESKTOP}px;

  ${breakpoint.m.up} {
    margin-bottom: 3px;
  };
`;

const ListItems = styled.ul<{desktopColumnsLayout: linksListItemsDesktopColumnsLayout}>`
  display: grid;
  margin: 40px 0 0 0;
  padding: 0;

  ${breakpoint.m.up} {
    ${(p: {desktopColumnsLayout: linksListItemsDesktopColumnsLayout}) =>
      p.desktopColumnsLayout === linksListItemsDesktopColumnsLayout.multi
        ? css`
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 40px;

            ${Item} {
              &:nth-child(2) {
                border-top: 1px solid ${colors.grayDarkLight2};
              }
            }
          `
        : `
      max-width: 580px;
      margin: 0 auto;
      `};
  };
`;

const Item = styled.li`
  font-family: ${fonts.headerFont};
  font-weight: ${fonts.weightBold};
  margin: 0;
  padding: 15px 0;
  border-bottom: 1px solid ${colors.grayDarkLight2};

  &:first-child {
    border-top: 1px solid ${colors.grayDarkLight2};
  }

  a {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  p {
    display: inline-flex;
    align-items: center;
    font-size: ${fontSizesPx.small};
    color: ${colors.redDFL};
    padding: 0 ${CARET_WIDTH_DESKTOP}px 0 0;
    margin: 0;

    ${breakpoint.m.up} {
      font-size: ${fontSizesPx.average};
    };
  }
`;

const Container = styled.div`
  max-width: 580px;
  margin: 0 auto;

  ${breakpoint.m.up} {
    max-width: ${stage.outerWidth}px;
  };

  /* NOTE: can remove once FullpageHeadline is responsive */
  ${FullpageHeadline} {
    ${breakpoint.m.down} {
      font-size: ${fontSizesPx.average};
      width: auto;
    };
  }
`;

interface LinksListItemsCompProps {
  content: RelatedProductsProps[];
}

const LinksListItemsComp = (props: LinksListItemsCompProps) => {
  const {content} = props;
  const desktopColumnsLayout =
    content.length > 3 ? linksListItemsDesktopColumnsLayout.multi : linksListItemsDesktopColumnsLayout.single;

  return (
    <Container>
      <FullpageHeadline>Related Products</FullpageHeadline>
      <ListItems desktopColumnsLayout={desktopColumnsLayout}>
        {content.map((item, i) => {
          const url = item.link ? `/product/${item.link}` : "404";
          const subTitle = item.overline;

          return (
            <Item key={`related-products-${i}`}>
              <Link to={url}>
                <SubTitle>{subTitle}</SubTitle>
                <p>{item.title}</p>
                <CaretWrapper>
                  <Caret strokeWidth={1} color={colors.redDFL} />
                </CaretWrapper>
              </Link>
            </Item>
          );
        })}
      </ListItems>
    </Container>
  );
};

export default LinksListItemsComp;
