import React from "react";
import styled from "styled-components";
import Autosuggest from "react-autosuggest";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import {navigate} from "gatsby";
import {Dispatch} from "redux";
import {connect} from "react-redux";
import SuggestionContainer from "./suggestionContainer";
import {Magnifier} from "../../../components";
import {
  smallBold,
  smallRegular,
  colors,
  ColorType,
  SearchableProductProps,
  px,
  CenteredDiv,
  NavigationOverlayStateType,
  navigationOverlayStates,
  breakpoint,
} from "../../../models";
import {escapeRegexCharacters} from "../../../util/util";
import {trackSearchButtonClick, trackSuggestionClick} from "../../../util/tracking";
import {AppState} from "../../../store";
import {updateIsTextFilterActiveAction} from "../../../store/system/actions";

// COMPONENT PROPERTIES
interface PropsFromState {
  isNavigationInverted: boolean;
  overlayState: NavigationOverlayStateType;
}
const mapStateToProps = (state: AppState): PropsFromState => ({
  isNavigationInverted: state.system.isNavigationInverted,
  overlayState: state.system.overlayState,
});

interface PropsFromDispatch {
  updateIsTextFilterActive: (isActive: boolean) => void;
}
const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => {
  return {
    updateIsTextFilterActive: (isActive: boolean) => {
      return dispatch(updateIsTextFilterActiveAction(isActive));
    },
  };
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

// collapsable container
interface IconContainerCollapsableProps {
  collapsed: boolean;
}
const IconContainerCollapsable = styled(Row)`
  width: ${(props: IconContainerCollapsableProps) => (props.collapsed ? px(32) : px(300))};
  transition: width 0.2s;

  ${breakpoint.s.down} {
    width: ${(props: IconContainerCollapsableProps) => (props.collapsed ? px(32) : px(220))};
  };

  ${breakpoint.xs.down} {
   width: ${(props: IconContainerCollapsableProps) => (props.collapsed ? px(32) : px(160))};
  };
`;
const IconWrapper = styled(CenteredDiv)`
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  cursor: pointer;
`;
const CollapsableContent = styled.div`
  flex-shrink: 1;
  overflow: hidden;
  opacity: ${(props: IconContainerCollapsableProps) => (props.collapsed ? 0 : 1)};
  transition: opacity 0.2s ease-in 0.1s;
  position: relative;
`;

// the autosuggest elements
interface AutosuggestWrapperProps {
  collapsed: boolean;
}
const AutosuggestWrapper = styled.div`
  position: absolute;
  top: 4px;
  right: 0;
  width: ${(props: AutosuggestWrapperProps) => (props.collapsed ? 0 : px(300 - 32))};
  opacity: ${(props: AutosuggestWrapperProps) => (props.collapsed ? 0 : 1)};
  transition: opacity 0.2s, width 0.2s;

  ${breakpoint.s.down} {
      width: ${(props: AutosuggestWrapperProps) => (props.collapsed ? 0 : px(220 - 32))};
  };

  ${breakpoint.xs.down} {
    width: ${(props: AutosuggestWrapperProps) => (props.collapsed ? 0 : px(160 - 32))};
  };
`;

const StyledInput = styled.input`
  ${smallRegular};
  outline: none;
  border: none;
  outline-width: 0;
  caret-color: ${colors.redDFL};
  border-bottom: 1px solid ${colors.grayLight};
  width: 100%;
`;

const SuggestionTitle = styled.div`
  ${smallBold};
`;
interface SuggestionTitleSpanProps {
  color: ColorType;
}
const SuggestionTitleSpan = styled.span`
  color: ${(props: SuggestionTitleSpanProps) => props.color};
`;
const SuggestionSubline = styled.div`
  ${smallRegular};
`;
const SuggestionLink = styled.div`
  background-color: ${colors.white};
  padding: 8px 10px;
  display: block;
  border: 1px solid ${colors.grayMedium};
  margin-top: -1px;

  &:hover {
    background-color: ${colors.grayUltraLight};
  }
`;

// COMPONENT
interface TextFilterProps extends PropsFromState, PropsFromDispatch {
  products: SearchableProductProps[];
}
interface TextFilterState {
  value: string;
  suggestions: SearchableProductProps[];
  isCollapsed: boolean;
  hoveredLink: string;
}
class TextFilter extends React.Component<TextFilterProps, TextFilterState> {
  constructor(props: TextFilterProps) {
    super(props);

    this.state = {
      value: "",
      suggestions: [],
      isCollapsed: true,
      hoveredLink: null,
    };

    this.toggleCollapsed = this.toggleCollapsed.bind(this);
    this.renderInputComponent = this.renderInputComponent.bind(this);
    this.renderSuggestionsContainer = this.renderSuggestionsContainer.bind(this);
    this.renderSuggestion = this.renderSuggestion.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  render() {
    const {isNavigationInverted, overlayState} = this.props;
    const {value, suggestions} = this.state;
    const isOverlayActive = overlayState !== navigationOverlayStates.default;

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: "Search Products",
      value,
      onChange: this.onChange,
      onBlur: this.onBlur,
      onKeyDown: this.onKeyDown,
    };

    return (
      <div style={{pointerEvents: "all", position: "relative"}}>
        <IconContainerCollapsable collapsed={this.state.isCollapsed}>
          <IconWrapper onClick={this.toggleCollapsed}>
            <Magnifier color={isNavigationInverted && !isOverlayActive ? colors.white : colors.black} />
          </IconWrapper>
          <CollapsableContent collapsed={this.state.isCollapsed} />
        </IconContainerCollapsable>

        <AutosuggestWrapper collapsed={this.state.isCollapsed}>
          <Autosuggest
            theme={{
              suggestionsList: {
                padding: 0,
                backgroundColor: colors.white,
              },
            }}
            suggestions={suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            getSuggestionValue={this.getSuggestionValue}
            renderSuggestionsContainer={this.renderSuggestionsContainer}
            renderSuggestion={this.renderSuggestion}
            renderInputComponent={this.renderInputComponent}
            inputProps={inputProps}
          />
        </AutosuggestWrapper>
      </div>
    );
  }

  onChange = (_event: React.FormEvent, {newValue}) => {
    this.setState({
      value: newValue,
    });
  };

  onKeyDown = (event: React.KeyboardEvent<any>) => {
    if (event.key === "Enter") {
      const suggestion = this.getSuggestions(this.state.value);

      if (suggestion.length > 0 && suggestion[0].link) {
        const link = suggestion[0].link;

        this.setState(
          {
            isCollapsed: true,
            value: "",
            hoveredLink: null,
          },
          () => link && navigate(link),
        );
      }
    }
  };

  // Autosuggest will call this function every time you need to update suggestions.
  onSuggestionsFetchRequested = ({value}) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  /**
   * gets called, when the text input loses focus
   *
   */
  onBlur(_event: React.FormEvent) {
    const link = this.state.hoveredLink;
    trackSuggestionClick({searchTerm: this.state.value, page: link});
    this.setState(
      {
        isCollapsed: true,
        value: "",
        hoveredLink: null,
      },
      () => {
        if (!link) {
          return;
        }
        navigate(link);
      },
    );

    this.props.updateIsTextFilterActive(false);
  }

  /**
   * toggles the collapsed state of the textfield (when clicking the magnifier or when input blurs)
   *
   */
  toggleCollapsed() {
    if (this.state.isCollapsed) {
      trackSearchButtonClick();
      this.props.updateIsTextFilterActive(true);
    }
    this.setState({
      isCollapsed: !this.state.isCollapsed,
    });
  }

  /**
   * Gets a list of suggestions that match the input
   *
   */
  getSuggestions(input: string) {
    const escapedValue = escapeRegexCharacters(input.trim());
    if (escapedValue === "") {
      return [];
    }
    const regex = new RegExp("\\b" + escapedValue, "i");
    return this.props.products.filter(product => regex.test(this.getSuggestionValue(product)));
  }

  /**
   * How to get the searchable string value from a suggestion object
   *
   */
  getSuggestionValue(suggestion: SearchableProductProps): string {
    return suggestion.name;
  }

  /**
   * How to render the suggestions container
   *
   */
  renderSuggestionsContainer({containerProps, children}) {
    const hasInput = this.state.value.length > 0;
    const resultsEmpty = hasInput && this.state.suggestions.length === 0;

    return (
      <SuggestionContainer
        resultsEmpty={resultsEmpty}
        hasInput={hasInput}
        containerProps={containerProps}
        searchTerm={this.state.value}
        products={this.props.products}
        children={children}
        updateHoveredLink={(link: string) => {
          this.setState({
            hoveredLink: link,
          });
        }}
      />
    );
  }

  /**
   * How to render each suggestion
   *
   */
  renderSuggestion(suggestion: SearchableProductProps, {query}) {
    const matches = match(suggestion.name, query);
    const parts = parse(suggestion.name, matches);

    return (
      <SuggestionLink
        onClick={() => {
          this.setState(
            {
              isCollapsed: true,
              value: "",
              hoveredLink: null,
            },
            () => {
              if (!suggestion.link) {
                return;
              }
              trackSuggestionClick({searchTerm: query, page: suggestion.name});
              navigate(suggestion.link);
            },
          );
        }}
      >
        <SuggestionTitle>
          {parts.map((part, i) => (
            <SuggestionTitleSpan key={i} color={part.highlight ? colors.redDFL : colors.black}>
              {part.text}
            </SuggestionTitleSpan>
          ))}
        </SuggestionTitle>
        <SuggestionSubline>{suggestion.subline}</SuggestionSubline>
      </SuggestionLink>
    );
  }

  /**
   * How to render the input field
   *
   */
  renderInputComponent(inputProps: any) {
    return <StyledInput {...inputProps} />;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TextFilter);
