import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import {CategoryList} from "../../components";
import {wpPageNodeToCategory} from "../../util/util";
import {WPPageNodeProps, categoryClasses, wordpressPageIds} from "../../models";

interface QueryProps {
  allWpPage?: {
    edges: WPPageNodeProps[];
  };
}
interface CategoryListWithQueryProps {
  isMobileNav?: boolean;
}

const CategoryListWithQueryComp = (props: CategoryListWithQueryProps) => {
  const query: QueryProps = useStaticQuery(
    graphql`
      query {
        allWpPage {
          edges {
            node {
              databaseId
              slug
              category {
                categoryclass
                name
                description
                image {
                  sourceUrl
                }
              }
            }
          }
        }
      }
    `,
  );

  const productPages = query.allWpPage.edges.filter(page => {
    return (
      page.node.databaseId === wordpressPageIds.bundesligaMatches ||
      page.node.databaseId === wordpressPageIds.matchInterviews ||
      page.node.databaseId === wordpressPageIds.matchHighlights ||
      page.node.databaseId === wordpressPageIds.digitalContent ||
      page.node.databaseId === wordpressPageIds.promoTrailerContent ||
      page.node.databaseId === wordpressPageIds.seasonalContent ||
      page.node.databaseId === wordpressPageIds.monthlyContent ||
      page.node.databaseId === wordpressPageIds.weeklyContent
    );
  });
  const matchdayCategories = productPages.filter(edge => edge.node.category.categoryclass === categoryClasses.matchday);
  const promotionCategories = productPages.filter(edge => edge.node.category.categoryclass === categoryClasses.promotion);
  const storytellingCategories = productPages.filter(
    edge => edge.node.category.categoryclass === categoryClasses.storytelling,
  );

  return (
    <CategoryList
      live={matchdayCategories.map(category => wpPageNodeToCategory(category))}
      digital={promotionCategories.map(category => wpPageNodeToCategory(category))}
      other={storytellingCategories.map(category => wpPageNodeToCategory(category))}
      isMobileNav={props.isMobileNav}
    />
  );
};

export default CategoryListWithQueryComp;
