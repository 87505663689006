import React from "react";
import styled from "styled-components";
import {CategoryAggregationCell, CategoryCell, RegularCell} from "../styled";
import {px, scheduleSizes, colors, seasonMap, condensedSmallBold, seasons} from "../../../../models";

const SeasonLabelRow = styled.tr`
  height: ${px(scheduleSizes.dayLabelRow)};
`;

const LabelCell = styled(RegularCell)`
  ${condensedSmallBold};
  font-size: 13px;
  color: white;
  text-transform: uppercase;
  padding-left: ${px(15)};
  vertical-align: middle;
`;

const SeasonLabelRowComp = () => {
  return (
    <SeasonLabelRow>
      <CategoryAggregationCell />
      <CategoryCell color={colors.transparent} borderColor={colors.transparent} />

      {[seasons.preSeason, seasons.season, seasons.postSeason].map((season, seasonIndex) => {
        return (
          <LabelCell
            collapsed={false}
            backgroundColor={colors.black}
            key={seasonIndex}
          >
            {seasonMap.get(season).name}
          </LabelCell>
        );
      })}
    </SeasonLabelRow>
  );
};

export default SeasonLabelRowComp;
