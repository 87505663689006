import * as React from 'react';
import styled from 'styled-components';
import {colors, smallRegular, smallBold, ContactMainProps, px, breakpoints, microBold, microRegular} from "../../models";
import {trimWebsiteUrl} from "../../util/util";

const ContactCard = styled.div`
  max-width: 500px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 10px;
`;

interface LogoProps {
  src: string;
}
const Logo = styled.div`
  background-size: contain;
  background-image: url(${(props: LogoProps) => props.src});
  background-repeat: no-repeat;
  background-position: center;
  width: 50px;
  height: 50px;
  align-self: center;


  /* tablet */
  @media (min-width: ${px(breakpoints.tablet)}) {
    width: 80px;
    height: 80px;
  }
`;

const ContactDetails = styled.div`
  margin-left: 50px;
`;

const Name = styled.div`
  ${microBold};

  /* tablet */
  @media (min-width: ${px(breakpoints.tablet)}) {
    ${smallBold};
  }
`;


const Telephone = styled.div`
  ${microRegular};

  /* tablet */
  @media (min-width: ${px(breakpoints.tablet)}) {
    ${smallRegular};
  }
`;

const TextLink = styled.a`
  ${microRegular};
  color: ${colors.redDFL};
  display: block;

  &:hover {
    text-decoration: underline;
  }

  /* tablet */
  @media (min-width: ${px(breakpoints.tablet)}) {
    ${smallRegular};
    color: ${colors.redDFL};
  }
`;


interface ContactCardMainProps extends ContactMainProps {
  logo: string;
}
const ContactCardMain = (props: ContactCardMainProps) => {
  const { name, phone, email, website, logo } = props;
  return (
    <ContactCard>
      <Logo src={logo} />

      <ContactDetails>
        <Name>{name}</Name>
        <Telephone>{phone}</Telephone>
        <TextLink href={`mailto:${email}`}>{email}</TextLink>
        <TextLink href={website}>{trimWebsiteUrl(website)}</TextLink>
      </ContactDetails>
    </ContactCard>
  );
};

export default ContactCardMain;
