import * as React from 'react';
import styled from 'styled-components';
import {px, stage, breakpoints, mobilePaddings} from "../../models";
import {createMarkup} from "../../util/util";

import {mediumBold, mediumLight} from "../../models/style-constants";


const MessageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 ${px(mobilePaddings.mobile)};
  box-sizing: border-box;

  margin-top: 60px;
  margin-bottom: 20px;

  * {
    ${mediumLight};
    font-size: 25px;
    line-height: 1.5em;
    font-style: normal !important;
    text-decoration: none !important;
  }
  strong {
    ${mediumBold};
    font-size: 25px;
    line-height: 1.5em;
  }

  /* tablet */
  @media (min-width: ${px(breakpoints.tablet)}) {
    padding: 0 ${px(mobilePaddings.tablet)};
    margin-top: 0;
    margin-bottom: 0;

    * {
      font-size: 40px;
      line-height: 1.8em;
      font-style: normal !important;
      text-decoration: none !important;
    }
    strong {
      font-size: 40px;
      line-height: 1.8em;
    }
  }
`;

const Message = styled.div`
  width: ${px(stage.innerWidth)};
`;

interface USPMessageCompProps {
  message: string;
}
const USPMessageComp = (props: USPMessageCompProps) => {
  const { message } = props;
  return (
    <MessageWrapper>
      <Message dangerouslySetInnerHTML={createMarkup(message)} />
    </MessageWrapper>
  );
};

export default USPMessageComp;
