import {
  UPDATE_SELECTED_REGION,
  SET_TIMEZONE_DETECTED,
  UPDATE_UTC_OFFSET,
  UPDATE_LOCAL_UTC_OFFSET_TEXT,
  UPDATE_NAVIGATION_OVERLAY,
  UPDATE_IS_NAVIGATION_HIDDEN,
  UPDATE_IS_NAVIGATION_INVERTED,
  UPDATE_IS_TEXTFILTER_ACTIVE,
  UPDATE_IS_HOMEPAGE_ABOVE_VIDEO,
  SET_CURRENT_JW_PLAYER_ID,
  SET_USER_DID_CONSENT,
  UPDATE_SCREENWIDTH,
  SystemActionTypes,
} from "./types";
import {DflRegionType, NavigationOverlayStateType} from "../../models";

export const updateRegionAction = (newRegion: DflRegionType): SystemActionTypes => {
  return {
    type: UPDATE_SELECTED_REGION,
    payload: newRegion,
  };
};

export const setTimezoneDetectedAction = (): SystemActionTypes => {
  return {
    type: SET_TIMEZONE_DETECTED,
    payload: true,
  };
};

export const updateUTCOffsetAction = (newOffset: number): SystemActionTypes => {
  return {
    type: UPDATE_UTC_OFFSET,
    payload: newOffset,
  };
};

export const updateLocalUTCOffsetTextAction = (newLocalUTCOffsetText: string): SystemActionTypes => {
  return {
    type: UPDATE_LOCAL_UTC_OFFSET_TEXT,
    payload: newLocalUTCOffsetText,
  };
};

export const updateNavigationOverlayAction = (newState: NavigationOverlayStateType): SystemActionTypes => {
  return {
    type: UPDATE_NAVIGATION_OVERLAY,
    payload: newState,
  };
};

export const updateIsHomepageAboveVideoAction = (isAboveVideo: boolean): SystemActionTypes => {
  return {
    type: UPDATE_IS_HOMEPAGE_ABOVE_VIDEO,
    payload: isAboveVideo,
  };
};

export const updateIsNavigationHiddenAction = (isHidden: boolean): SystemActionTypes => {
  return {
    type: UPDATE_IS_NAVIGATION_HIDDEN,
    payload: isHidden,
  };
};

export const updateIsNavigationInvertedAction = (isInverted: boolean): SystemActionTypes => {
  return {
    type: UPDATE_IS_NAVIGATION_INVERTED,
    payload: isInverted,
  };
};

export const updateIsTextFilterActiveAction = (isActive: boolean): SystemActionTypes => {
  return {
    type: UPDATE_IS_TEXTFILTER_ACTIVE,
    payload: isActive,
  };
};

export const setCurrentJwPlayerIdAction = (newJwPlayerId: string): SystemActionTypes => {
  return {
    type: SET_CURRENT_JW_PLAYER_ID,
    payload: newJwPlayerId,
  };
};

export const setUserDidConsentAction = (didConsent: boolean): SystemActionTypes => {
  return {
    type: SET_USER_DID_CONSENT,
    payload: didConsent,
  };
};

export const updateScreenWidthAction = (newScreenWidth: number): SystemActionTypes => {
  return {
    type: UPDATE_SCREENWIDTH,
    payload: newScreenWidth,
  };
};
