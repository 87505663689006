import * as React from "react";
import styled from "styled-components";
import {colors, stage, smallRegular, mediumBold, px, DescriptionColumnProps, breakpoints} from "../../models";
import {linesToTextArray} from "../../util/util";

const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 22px;
  box-sizing: border-box;
  margin-bottom: 36px;

  /* tablet */
  @media (min-width: ${px(breakpoints.tablet)}) {
    padding: 0;
  }

  /* desktop */
  @media (min-width: ${px(breakpoints.desktopHd)}) {
    width: ${px(stage.columnWidth * 4 + stage.columnGap * 3)};
    padding: 0;
    margin-bottom: 0;
  }
`;

const Title = styled.div`
  ${mediumBold};
  margin: 36px 0 32px;

  /* desktop */
  @media (min-width: ${px(breakpoints.desktopHd)}) {
    ${mediumBold};
    font-size: 30px;
    margin: -10px 0 25px;
  }
`;

const DescriptionList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const Description = styled.li`
  ${smallRegular};
  line-height: 1.3em;
  color: ${colors.grayDark};
  padding: 10px 4px 15px;
  border-bottom: 1px solid ${colors.grayLight};

  /* desktop */
  @media (min-width: ${px(breakpoints.desktopHd)}) {
    padding: 10px 12px 15px;
  }
`;

const DescriptionColumnComp = (props: DescriptionColumnProps) => {
  const {title, descriptions} = props;
  return (
    <Column>
      <Title>{title}</Title>
      <DescriptionList>
        {descriptions.map((description, i) => (
          <Description key={`desc-${i}`}>
            {linesToTextArray(description).map((line, lineIndex) => (
              <span key={`${i}-${lineIndex}`}>
                {line}
                <br />
              </span>
            ))}
          </Description>
        ))}
      </DescriptionList>
    </Column>
  );
};

export default DescriptionColumnComp;
