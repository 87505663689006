/**
 * A custom dropdown component with typesafe options
 *
 */

import * as React from "react";
import onClickOutside from "react-onclickoutside";
import styled from "styled-components";
import {Caret} from "../components";
import {condensedMicroRegular, px, colors, zIndices, elementSizes, condensedMicroBold, RowDiv} from "../models";

const DropdownWrapper = styled(RowDiv)`
  position: relative;
  width: 100%;
  z-index: ${zIndices.zDropdown};
  box-sizing: border-box;
`;
const Select = styled(RowDiv)`
  ${condensedMicroRegular};
  width: 100%;
  height: ${px(elementSizes.dropdownHeight)};
  background-color: ${colors.white};
  line-height: 1.25em;
  cursor: pointer;
  box-sizing: border-box;
  border-bottom: 1px solid ${colors.grayMedium};
  justify-content: space-between;
`;
const Title = styled.div`
  ${condensedMicroBold};
  margin-right: 10px;
`;
const CaretWrapper = styled.div`
  width: 8px;
  height: 8px;
  margin: 2px 0 0 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Choice = styled(RowDiv)`
  ${condensedMicroRegular};
  width: 100%;
  padding: ${px(6)} ${px(8)};
  background-color: ${colors.white};
  line-height: 1.25em;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid ${colors.grayMedium};
  margin-top: -1px;
  justify-content: space-between;

  &:hover {
    background-color: ${colors.grayUltraLight};
  }
`;
interface ChoicesListProps {
  isCollapsed: boolean;
}
const ChoicesList = styled.div`
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  top: ${px(elementSizes.dropdownHeight + 7)};
  left: 0;
  opacity: ${(props: ChoicesListProps) => (props.isCollapsed ? 0 : 1)};
  pointer-events: ${(props: ChoicesListProps) => (props.isCollapsed ? "none" : "all")};
  max-height: 300px;
  overflow-y: auto;

  ${Choice} {
    :nth-child(1) {
      margin-top: 0;
    }
  }
`;

type OptionValue = string | number;
interface Option<T extends OptionValue> {
  value: T;
  label: string;
}
interface GenericSelectProps<T extends OptionValue> {
  options: [Option<T>];
  value: Option<T>;
  onChange: (value: T) => void;
  title: string;
}
interface GenericSelectState {
  isCollapsed: boolean;
}
class GenericSelect<T extends OptionValue> extends React.Component<GenericSelectProps<T>, GenericSelectState> {
  constructor(props: GenericSelectProps<T>) {
    super(props);
    this.state = {
      isCollapsed: true,
    };
    this.toggleCollapsed = this.toggleCollapsed.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  render() {
    const {options, onChange, value, title} = this.props;
    return (
      <DropdownWrapper>
        <Select onClick={this.toggleCollapsed}>
          <Title>{title}</Title>

          <RowDiv>
            <div>{value.label}</div>
            <CaretWrapper>
              <Caret color={colors.black} />
            </CaretWrapper>
          </RowDiv>
        </Select>

        <ChoicesList isCollapsed={this.state.isCollapsed}>
          {options.map((option, i) => (
            <Choice
              key={i}
              onClick={() => {
                onChange(option.value);
                this.toggleCollapsed();
              }}
            >
              <Title>{title}</Title>
              <span>{option.label}</span>
            </Choice>
          ))}
        </ChoicesList>
      </DropdownWrapper>
    );
  }

  handleClickOutside() {
    if (!this.state.isCollapsed) {
      this.setState({
        isCollapsed: true,
      });
    }
  }
  toggleCollapsed() {
    this.setState({
      isCollapsed: !this.state.isCollapsed,
    });
  }
}

export default onClickOutside(GenericSelect);
