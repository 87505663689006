/**
 * Burger Menu icon
 *
 */

// IMPORTS
import React from "react";

interface BurgerProps {
  color?: string;
  strokeWidth?: number;
}
const Burger = ({color = "#fff", strokeWidth = 1}: BurgerProps) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 26 21"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g stroke={color} strokeWidth={strokeWidth} fill="none" strokeLinecap="square">
      <path d="M0,0.5 L24,0.5" />
      <path d="M0,9.5 L24,9.5" />
      <path d="M0,18.5 L24,18.5" />
    </g>
  </svg>
);

export default Burger;
